// src/components/ios/repairs/RepairsOverview.js
import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import RepairsPerformance from './RepairsPerformance';
import TopOpenRepairs from './TopOpenRepairs';
import InventoryHealth from './InventoryHealth';
import TopVendors from './TopVendors';
import RepairsProductivity from './RepairsProductivity';
import RepairsErrors from './RepairsErrors';

const RepairsOverview = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Repairs
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, mb: 2, height: '100%', bgcolor: '#2a2a2a' }}>
             <RepairsPerformance /> 
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, mb: 2, height: '100%', bgcolor: '#2a2a2a' }}>
               <TopOpenRepairs /> 
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, mb: 2, height: '100%', bgcolor: '#2a2a2a' }}>
               <InventoryHealth /> 
          </Paper> 
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, mb: 2, height: '100%', bgcolor: '#2a2a2a' }}>
               <TopVendors /> 
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, mb: 2, height: '100%', bgcolor: '#2a2a2a' }}>
               <RepairsProductivity /> 
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, mb: 2, height: '100%', bgcolor: '#2a2a2a' }}>
               <RepairsErrors />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RepairsOverview;