import { useState, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';

const useAuthenticatedPut = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { instance, accounts } = useMsal();

    const put = useCallback(async (url, data, contentType = 'application/json') => {
        if (!accounts.length) {
            const noAccountError = new Error("No authenticated account found");
            setError(noAccountError);
            throw noAccountError;
        }

        setIsLoading(true);
        setError(null);

        try {
            await instance.initialize();
            const accessToken = await instance.acquireTokenSilent({
                scopes: ['api://82ba1170-03da-46d4-be4b-dcc3fb4473f1/access_as_user'],
                account: accounts[0],
            });

            let requestData = data;
            let headers = { 
                Authorization: `Bearer ${accessToken.accessToken}`,
                'Content-Type': contentType
            };

            // If it's JSON data and not already a string, stringify it
            if (contentType === 'application/json' && typeof data !== 'string') {
                requestData = JSON.stringify(data);
            }

            // If it's form data, let axios handle the Content-Type
            if (data instanceof FormData) {
                delete headers['Content-Type'];
            }

            const response = await axios.put(url, requestData, { headers });

            return response.data;
        } catch (error) {
            console.error('Error putting data:', error);
            setError(error);
            if (error.name === "InteractionRequiredAuthError") {
                instance.acquireTokenRedirect({
                    scopes: ['api://82ba1170-03da-46d4-be4b-dcc3fb4473f1/access_as_user'],
                });
            }
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, [instance, accounts]);

    const resetError = useCallback(() => {
        setError(null);
    }, []);

    return { put, isLoading, error, resetError };
};

export default useAuthenticatedPut;