import React, { useState, useEffect } from 'react';
import useAuthenticatedData from '../../hooks/useAuthenticatedData';
import useAuthenticatedPost from '../../hooks/useAuthenticatedPost';
import { Alert } from '@mui/material';
import {
    Box, Typography, CircularProgress, Paper, IconButton, Button,
    Dialog, DialogTitle, DialogContent, DialogActions, TextField, Snackbar
} from '@mui/material';
import { Star, StarBorder, Download } from '@mui/icons-material';
import MetricHistoricDataChart from '../MetricHistoricDataChart';
import ManageMetricAssignmentsDialog from '../ManageMetricAssignmentsDialog';

import MetricAssignmentGrid from '../MetricAssignmentGrid';

import MetricKPIsGrid from '../MetricKPIsGrid';
import MetricValuesGrid from '../MetricValuesGrid';
import useAuthenticatedDownload from '../../hooks/useAuthenticatedDownload';
import AssignmentDialog from '../AssignmentDialog';
import { useNavigate } from 'react-router-dom';


const MetricDetail = ({ metricId }) => {


    const { downloadFile } = useAuthenticatedDownload();
    const navigate = useNavigate(); 

    const {
        data: metricData,
        isLoading: isLoadingDetails,
        error: detailsError
    } = useAuthenticatedData(`https://localhost:5001/mos/metrics/${metricId}/detail`);

    const {
        data: favoriteStatus,
        isLoading: isLoadingFavorite,
        error: favoriteError

    } = useAuthenticatedData(`https://localhost:5001/mos/metrics/${metricId}/favorite/1`);

    const { post, isLoading: isPostingFavorite, error: postError } = useAuthenticatedPost();


    /*
    const {
        data: historicData,
        isLoading: isLoadingHistory,
        error: historyError
    } = useAuthenticatedData(`https://localhost:5001/mos/historic-metric-values/${metricId}/history`);


      {isLoadingHistory ? (
                    <CircularProgress />
                ) : historyError ? (
                    <Typography color="error">Error loading historical data: {historyError.message}</Typography>
                ) : (
                    <MetricHistoricDataChart data={historicData || []} />
                )}


    */

    const [isFavorite, setIsFavorite] = React.useState(false);
    const [openAssignDialog, setOpenAssignDialog] = useState(false);
    const [assignDialogType, setAssignDialogType] = useState('assign');



    const [assignToUser, setAssignToUser] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');

    useEffect(() => {
        if (favoriteStatus !== undefined && favoriteStatus !== null) {
            setIsFavorite(favoriteStatus);
        }
    }, [favoriteStatus]);


    // todo: the body of the post request should be true or false, depending on the favorite status
    const handleFavoriteToggle = async () => {
        try {
            const response = await post(
                `https://localhost:5001/mos/metrics/${metricId}/favorite/1`,
                !isFavorite
            );
            if (response !== undefined) {
                setIsFavorite(response);
                setSnackbarMessage(response ? 'Metric added to favorites' : 'Metric removed from favorites');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);

            }
        } catch (error) {
            console.error('Error toggling favorite status:', error);
            setSnackbarMessage('Error updating favorite status. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleDownload = async () => {
        try {
            await downloadFile(
                `https://localhost:5001/mos/metrics/${metricId}/export?format=csv`,
                `metric_${metricId}_data.csv`
            );
            setSnackbarMessage('Download started successfully');
            setSnackbarSeverity('success');
        } catch (error) {
            setSnackbarMessage('Failed to download CSV. Please try again.');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleOpenAssignDialog = (type) => {
        setAssignDialogType(type);
        setOpenAssignDialog(true);
    };

    const handleCloseAssignDialog = () => {
        setOpenAssignDialog(false);
    };

    const handleEdit = () => {
        navigate(`/metrics/${metricId}/edit`);
    }


    const handleAssign = async (selectedItems, category) => {
        try {
            // Implement the logic to assign the metric to the selected items
            await post(`https://localhost:5001/mos/assign-metric`, {
                metricId,
                [category]: selectedItems
            });
            setSnackbarMessage('Metric assigned successfully');
            setSnackbarSeverity('success');
            // Optionally, refresh the metric data or update the assignments list here
        } catch (error) {
            console.error('Error assigning metric:', error);
            setSnackbarMessage('Error assigning metric. Please try again.');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
            handleCloseAssignDialog();
        }
    };

    const handleUnassign = async (selectedItems, category) => {
        try {
            // Implement the logic to unassign the metric from the selected items
            await post(`https://localhost:5001/mos/unassign-metric`, {
                metricId,
                [category]: selectedItems
            });
            setSnackbarMessage('Metric unassigned successfully');
            setSnackbarSeverity('success');
            // Optionally, refresh the metric data or update the assignments list here
        } catch (error) {
            console.error('Error unassigning metric:', error);
            setSnackbarMessage('Error unassigning metric. Please try again.');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
            handleCloseAssignDialog();
        }
    };



    if (isLoadingDetails || isLoadingFavorite) return <CircularProgress />;
    if (detailsError) return <Typography color="error">Error loading metric details: {detailsError.message}</Typography>;
    if (favoriteError) return <Typography color="error">Error loading favorite status: {favoriteError.message}</Typography>;
    if (!metricData) return <Typography>Metric not found</Typography>;

    const { metricDTO, metricValueDTO, metricAssignments, kpIs } = metricData;

    return (
        <Box sx={{ p: 3 }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography
                    variant="overline"
                    sx={{
                        color: 'text.secondary',
                        opacity: 0.4,
                        letterSpacing: 2,
                        fontWeight: 'bold'
                    }}
                >
                    METRIC
                </Typography>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h4">{metricDTO.metricName}</Typography>
                <IconButton onClick={handleFavoriteToggle} disabled={isPostingFavorite}>
                    {isFavorite ? <Star color="primary" /> : <StarBorder />}
                </IconButton>
            </Box>

            <Button
                variant="contained"
               
                onClick={handleEdit}
            >
                Edit
            </Button>

            <Typography variant="h5">
                Value: {metricDTO ? `${metricDTO.value} as of ${new Date(metricDTO.lastUpdated).toLocaleDateString()}` : 'No current value'}
            </Typography>
            <Typography variant="body1">Description: {metricDTO.metricDescription}</Typography>
            <Typography variant="body1">Type: {metricDTO.displayType}</Typography>


            <Paper sx={{ mt: 4, p: 2 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Metric Values</Typography>
                    <Button
                        variant="contained"
                        startIcon={<Download />}
                        onClick={handleDownload}
                    >
                        Download CSV
                    </Button>
                </Box>
                <Box sx={{ height: '400px', mt: 2 }}>
                    <MetricValuesGrid metricId={metricId} />
                </Box>
            </Paper>


            <Paper sx={{ mt: 4, p: 2 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Assignments</Typography>

                    <Button variant="contained" onClick={() => {
                        setAssignDialogType('metric');
                        setOpenAssignDialog(true);
                    }}>
                        Assign This Metric to Users
                    </Button>

                    <Button variant="outlined" onClick={() => {
                        setAssignDialogType('edit');
                        setOpenAssignDialog(true);
                    }}>
                        Edit Metric Assignments
                    </Button>

                    <AssignmentDialog
                        open={openAssignDialog}
                        onClose={handleCloseAssignDialog}
                        onAssign={handleAssign}
                        onUnassign={handleUnassign}
                        type="metric"
                    />
                </Box>
                <MetricAssignmentGrid assignments={metricAssignments} />
            </Paper>

            <Paper sx={{ mt: 4, p: 2 }}>
                <Typography variant="h6">KPIs</Typography>
                <MetricKPIsGrid kpis={kpIs} />
            </Paper>



            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default MetricDetail;