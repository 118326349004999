import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@mui/material';

const columns = [
    { field: 'goalId', headerName: 'Goal ID', width: 100 },
    { field: 'userId', headerName: 'User ID', width: 100 },
    {
        field: 'assignedDate',
        headerName: 'Assigned Date',
        width: 200,
        valueGetter: (params) => {
            if (params && params.value) {
                return new Date(params.value).toLocaleString();
            }
            return 'N/A';
        }
    },
    {
        field: 'unassignedDate',
        headerName: 'Unassigned Date',
        width: 200,
        valueGetter: (params) => {
            if (params && params.value) {
                return new Date(params.value).toLocaleString();
            }
            return 'N/A';
        }
    },
    { field: 'assignedBy', headerName: 'Assigned By', width: 100 },
    {
        field: 'isValid',
        headerName: 'Is Valid',
        width: 100,
        valueGetter: (params) => {
            if (params && params.value !== undefined) {
                return params.value ? 'Yes' : 'No';
            }
            return 'N/A';
        }
    },
];

const GoalAssignmentGrid = ({ assignments }) => {
    if (!assignments || assignments.length === 0) {
        return <Typography>No assignments found.</Typography>;
    }

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={assignments}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                getRowId={(row) => `${row.goalId}-${row.userId}-${row.assignedDate}`}
            />
        </div>
    );
};

export default GoalAssignmentGrid;