// src/components/CalendarDailyDetails.js

import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Box, Typography, Button, TextField } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { gql, useQuery } from '@apollo/client';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core ag-Grid CSS
import 'ag-grid-community/styles/ag-theme-alpine.css'; // ag-Grid theme
import { useNavigate } from 'react-router-dom';


// Update the date formatting
const formatDateForGraphQL = (dateString) => {
    const date = new Date(dateString);
    // Format as ISO string which is compatible with DateTime scalar
    return date.toISOString();
};


// Helper functions for formatting
const formatNumber = (n) => {
    const num = typeof n === 'number' ? n : Number(n);
    if (isNaN(num)) return 'Invalid Number';
    if (num === 0 || Math.abs(num) < 0.01) return '0';
    if (Number.isInteger(num)) {
        return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(num);
    } else {
        let [intPart, decPart] = num.toFixed(2).split('.');
        intPart = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(intPart);
        decPart = decPart.replace(/0+$/, '');
        return decPart === '' ? intPart : `${intPart === '0' ? '' : intPart}.${decPart}`;
    }
};

const formatCurrency = (value) => {
    const num = typeof value === 'number' ? value : Number(value);
    if (isNaN(num)) return 'Invalid Currency';
    const absValue = Math.abs(num);
    const sign = num < 0 ? '-' : '';
    const formattedValue = formatNumber(Math.floor(absValue));
    return `${sign}$${formattedValue}`;
};

// Define GraphQL Queries
const GET_QUOTES_FOR_DAY = gql`
    query CustomerQuotesForDay($acctNo: String!, $date: DateTime!) {
        customerQuotesForDay(acctNo: $acctNo, date: $date) {
            docNo
            line
            partnumber
            description
            lineTotal
            ata
            id
        }
    }
`;

const GET_SALES_FOR_DAY = gql`
    query CustomerSalesForDay($acctNo: String!, $date: DateTime!) {
        customerSalesOrdersForDay(acctNo: $acctNo, date: $date) {
            docNo
            line
            partnumber
            description
            lineTotal
            ata
            id
        }
    }
`;

const GET_CALL_TRACKERS_FOR_DAY = gql`
    query CustomerCallTrackersForDay($acctNo: String!, $date: DateTime!) {
        customerCallTrackersForDay(acctNo: $acctNo, date: $date) {
            id
            docNo
            callMessage
        }
    }
`;

const CalendarDailyDetails = ({ selectedCustomer, selectedDate, onBack }) => {
    const navigate = useNavigate();
    //  const formattedDate = new Intl.DateTimeFormat('en-US').format(new Date(selectedDate));
    const formattedDate = new Date(selectedDate).toISOString().split('T')[0];

    // Format date for display
    const displayDate = new Intl.DateTimeFormat('en-US').format(new Date(selectedDate));

    // Format date for GraphQL
    const graphqlDate = formatDateForGraphQL(selectedDate);

    // Fetch Quotes
    const { data: quotesData, loading: quotesLoading, error: quotesError, refetch: refetchQuotes } = useQuery(GET_QUOTES_FOR_DAY, {
        variables: { acctNo: selectedCustomer?.acctNo || '', date: graphqlDate },
        skip: !selectedCustomer,
        fetchPolicy: 'network-only',
    });

    // Fetch Sales
    const { data: salesData, loading: salesLoading, error: salesError, refetch: refetchSales } = useQuery(GET_SALES_FOR_DAY, {
        variables: { acctNo: selectedCustomer?.acctNo || '', date: graphqlDate },
        skip: !selectedCustomer,
        fetchPolicy: 'network-only',
    });

    // Fetch Call Trackers
    const { data: callsData, loading: callsLoading, error: callsError, refetch: refetchCalls } = useQuery(GET_CALL_TRACKERS_FOR_DAY, {
        variables: { acctNo: selectedCustomer?.acctNo || '', date: graphqlDate },
        skip: !selectedCustomer,
        fetchPolicy: 'network-only',
    });

    // State for ag-Grid APIs
    const [quotesApi, setQuotesApi] = useState(null);
    const [salesApi, setSalesApi] = useState(null);
    const [callsApi, setCallsApi] = useState(null);

    // State for search inputs
    const [quotesSearch, setQuotesSearch] = useState('');
    const [salesSearch, setSalesSearch] = useState('');
    const [callsSearch, setCallsSearch] = useState('');

    // State for selected Call Tracker
    const [selectedCallTracker, setSelectedCallTracker] = useState(null);

    // Define column definitions for Quotes
    const quotesColumns = useMemo(() => [
        { headerName: 'Doc No', field: 'docNo', sortable: true, filter: 'agTextColumnFilter', flex: 1 },
        { headerName: 'Part Number', field: 'partnumber', sortable: true, filter: 'agTextColumnFilter', flex: 1.5 },
        { headerName: 'Description', field: 'description', sortable: true, filter: 'agTextColumnFilter', flex: 2 },
        {
            headerName: 'Line Total',
            field: 'lineTotal',
            sortable: true,
            filter: 'agNumberColumnFilter',
            flex: 1.5,
            valueFormatter: (params) => formatCurrency(params.value),
            cellStyle: { textAlign: 'right' },
        },
        { headerName: 'ATA', field: 'ata', sortable: true, filter: 'agTextColumnFilter', flex: 1 },
    ], []);

    // Define column definitions for Sales
    const salesColumns = useMemo(() => [
        { headerName: 'Doc No', field: 'docNo', sortable: true, filter: 'agTextColumnFilter', flex: 1 },
        { headerName: 'Part Number', field: 'partnumber', sortable: true, filter: 'agTextColumnFilter', flex: 1.5 },
        { headerName: 'Description', field: 'description', sortable: true, filter: 'agTextColumnFilter', flex: 2 },
        {
            headerName: 'Line Total',
            field: 'lineTotal',
            sortable: true,
            filter: 'agNumberColumnFilter',
            flex: 1.5,
            valueFormatter: (params) => formatCurrency(params.value),
            cellStyle: { textAlign: 'right' },
        },
        { headerName: 'ATA', field: 'ata', sortable: true, filter: 'agTextColumnFilter', flex: 1 },
    ], []);

    // Define column definitions for Call Trackers (Master Table)
    const callsMasterColumns = useMemo(() => [
        { headerName: 'ID', field: 'id', sortable: true, filter: 'agTextColumnFilter', flex: 1 },
        { headerName: 'Doc No', field: 'docNo', sortable: true, filter: 'agTextColumnFilter', flex: 1 },
    ], []);

    // Function to handle CSV Export using ag-Grid API
    const handleExportCSV = (api, fileName) => {
        if (api) {
            api.exportDataAsCsv({
                fileName: `${fileName}_${formattedDate}.csv`,
            });
        }
    };

    // Function to handle search input changes
    const handleSearchChange = (api, value) => {
        if (api) {
            api.setQuickFilter(value);
        }
    };

    // Refetch data when selectedCustomer or selectedDate changes
    useEffect(() => {
        if (selectedCustomer) {
            refetchQuotes({ acctNo: selectedCustomer.acctNo, date: formattedDate });
            refetchSales({ acctNo: selectedCustomer.acctNo, date: formattedDate });
            refetchCalls({ acctNo: selectedCustomer.acctNo, date: formattedDate });
        }
    }, [selectedCustomer, formattedDate, refetchQuotes, refetchSales, refetchCalls]);

    // If selectedCustomer is not provided, display a message with Back button
    if (!selectedCustomer) {
        return (
            <Box sx={{ padding: 2, textAlign: 'center', color: '#FFFFFF' }}>
                {/* Back Button */}
                <Box sx={{ marginBottom: 2 }}>
                    <Button variant="contained" startIcon={<ArrowBack />} onClick={onBack}>
                        Back to Calendar
                    </Button>
                </Box>
                <Typography variant="h6">Loading...</Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                padding: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#121212',
                color: '#FFFFFF',
                overflowY: 'auto',
            }}
        >
            {/* Back Button */}
            <Box sx={{ marginBottom: 2 }}>
                <Button variant="contained" startIcon={<ArrowBack />} onClick={onBack}>
                    Back to Calendar
                </Button>
            </Box>

            {/* Header with Customer Name and Date */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <Typography variant="h5">Customer: {selectedCustomer.name}</Typography>
                <Typography variant="h5">Date: {formattedDate}</Typography>
            </Box>

            {/* DataGrids Stacked Vertically */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                {/* Quotes Grid */}
                <Box>
                    <Typography variant="h6" gutterBottom>
                        Quotes
                    </Typography>
                    {quotesLoading ? (
                        <Typography sx={{ p: 3 }}>Loading...</Typography>
                    ) : quotesError ? (
                        <Typography color="error">Error: {quotesError.message}</Typography>
                    ) : (
                        <Box
                            sx={{
                                height: 400,
                                width: '100%',
                                backgroundColor: '#1E1E1E',
                                padding: 1,
                                borderRadius: 1,
                            }}
                        >
                            {/* Search and Export */}
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    placeholder="Search Quotes"
                                    value={quotesSearch}
                                    onChange={(e) => {
                                        setQuotesSearch(e.target.value);
                                        handleSearchChange(quotesApi, e.target.value);
                                    }}
                                    sx={{
                                        backgroundColor: '#2C2C2C',
                                        borderRadius: 1,
                                        input: { color: '#FFFFFF' },
                                        width: '70%',
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleExportCSV(quotesApi, 'Quotes')}
                                >
                                    Export CSV
                                </Button>
                            </Box>
                            {/* ag-Grid Table */}
                            <div className="ag-theme-alpine" style={{ height: 'calc(100% - 40px)', width: '100%' }}>
                                <AgGridReact
                                    rowData={quotesData?.customerQuotesForDay ?? []}
                                    columnDefs={quotesColumns}
                                    defaultColDef={{
                                        resizable: true,
                                        sortable: true,
                                        filter: true,
                                    }}
                                    enableCellTextSelection={true}
                                    pagination={true}
                                    paginationPageSize={10}
                                    rowSelection="single"
                                    onGridReady={(params) => {
                                        setQuotesApi(params.api);
                                    }}
                                />
                            </div>
                        </Box>
                    )}
                </Box>

                {/* Sales Grid */}
                <Box>
                    <Typography variant="h6" gutterBottom>
                        Sales
                    </Typography>
                    {salesLoading ? (
                        <Typography>Loading...</Typography>
                    ) : salesError ? (
                        <Typography color="error">Error: {salesError.message}</Typography>
                    ) : (
                        <Box
                            sx={{
                                height: 400,
                                width: '100%',
                                backgroundColor: '#1E1E1E',
                                padding: 1,
                                borderRadius: 1,
                            }}
                        >
                            {/* Search and Export */}
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    placeholder="Search Sales"
                                    value={salesSearch}
                                    onChange={(e) => {
                                        setSalesSearch(e.target.value);
                                        handleSearchChange(salesApi, e.target.value);
                                    }}
                                    sx={{
                                        backgroundColor: '#2C2C2C',
                                        borderRadius: 1,
                                        input: { color: '#FFFFFF' },
                                        width: '70%',
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleExportCSV(salesApi, 'Sales')}
                                >
                                    Export CSV
                                </Button>
                            </Box>
                            {/* ag-Grid Table */}
                            <div className="ag-theme-alpine" style={{ height: 'calc(100% - 40px)', width: '100%' }}>
                                <AgGridReact
                                    rowData={salesData?.customerSalesOrdersForDay ?? []}
                                    columnDefs={salesColumns}
                                    defaultColDef={{
                                        resizable: true,
                                        sortable: true,
                                        filter: true,
                                    }}
                                    enableCellTextSelection={true}
                                    pagination={true}
                                    paginationPageSize={10}
                                    rowSelection="single"
                                    onGridReady={(params) => {
                                        setSalesApi(params.api);
                                    }}
                                />
                            </div>
                        </Box>
                    )}
                </Box>

                {/* Call Trackers Grid */}
                <Box>
                    <Typography variant="h6" gutterBottom>
                        Call Trackers
                    </Typography>
                    {callsLoading ? (
                        <Typography>Loading...</Typography>
                    ) : callsError ? (
                        <Typography color="error">Error: {callsError.message}</Typography>
                    ) : (
                        <Box
                            sx={{
                                height: 400,
                                width: '100%',
                                backgroundColor: '#1E1E1E',
                                padding: 1,
                                borderRadius: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                            }}
                        >
                            {/* Master Table */}
                            <Box sx={{ flex: 1 }}>
                                {/* Search and Export */}
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Search Call Trackers"
                                        value={callsSearch}
                                        onChange={(e) => {
                                            setCallsSearch(e.target.value);
                                            handleSearchChange(callsApi, e.target.value);
                                        }}
                                        sx={{
                                            backgroundColor: '#2C2C2C',
                                            borderRadius: 1,
                                            input: { color: '#FFFFFF' },
                                            width: '70%',
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleExportCSV(callsApi, 'CallTrackers')}
                                    >
                                        Export CSV
                                    </Button>
                                </Box>
                                {/* ag-Grid Master Table */}
                                <div className="ag-theme-alpine" style={{ height: 'calc(100% - 40px)', width: '100%' }}>
                                    <AgGridReact
                                        rowData={callsData?.customerCallTrackersForDay ?? []}
                                        columnDefs={callsMasterColumns}
                                        defaultColDef={{
                                            resizable: true,
                                            sortable: true,
                                            filter: true,
                                        }}
                                        enableCellTextSelection={true}
                                        pagination={true}
                                        paginationPageSize={10}
                                        rowSelection="single"
                                        onGridReady={(params) => {
                                            setCallsApi(params.api);
                                        }}
                                        onSelectionChanged={(params) => {
                                            const selectedNodes = params.api.getSelectedNodes();
                                            if (selectedNodes.length > 0) {
                                                const selectedData = selectedNodes[0].data;
                                                setSelectedCallTracker(selectedData);
                                            } else {
                                                setSelectedCallTracker(null);
                                            }
                                        }}
                                    />
                                </div>
                            </Box>

                            {/* Details View */}
                            <Box sx={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Call Message
                                </Typography>
                                <TextField
                                    multiline
                                    fullWidth
                                    minRows={10}
                                    maxRows={20}
                                    value={selectedCallTracker?.callMessage || ''}
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    sx={{
                                        backgroundColor: '#2C2C2C',
                                        borderRadius: 1,
                                        color: '#FFFFFF',
                                        '& .MuiOutlinedInput-root': {
                                            color: '#FFFFFF',
                                            '& fieldset': {
                                                borderColor: '#424242',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#FFFFFF',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#FFFFFF',
                                            },
                                        },
                                        overflowY: 'auto',
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default CalendarDailyDetails;
