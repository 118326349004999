// src/components/ios/repairs/TopOpenRepairs.js
import React from 'react';
import { Box, Typography, Link, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import useAuthenticatedData from '../../../hooks/useAuthenticatedData';
import { useNavigate } from 'react-router-dom';

const columns = [
    { field: 'addedOnDate', headerName: 'Added On', width: 200 },
    { field: 'vendorName', headerName: 'Vendor Name', width: 200 },
    { field: 'partnumber', headerName: 'Part Number', width: 150 },
    { field: 'description', headerName: 'Description', width: 150 },
    { field: 'lineTotal', headerName: 'Line Total', width: 150 },
    { field: 'daysOpen', headerName: 'Days Open', width: 150 },
    { field: 'docStatusDesc', headerName: 'Doc Status Desc', width: 150 },
    ]; 

const TopOpenRepairs = () => {
  const { data, isLoading, error } = useAuthenticatedData('https://localhost:5001/ios/repairs/top-open-repairs');
  const navigate = useNavigate();

  const handleClick = (row) => {
    navigate(`/ios/repair/${row.id}`);
  }

  if (!data || isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Top Open Repairs</Typography>
        <Link href="#" underline="hover">open repairs</Link>
      </Box>
      <Typography variant="subtitle1" mb={1}>
        ${data.totalOpenRepairs} (add percent of total open)
      </Typography>
      <DataGrid
        rows={data}
        onRowClick={(row) => handleClick(row)}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default TopOpenRepairs;