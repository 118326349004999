import React, { useState } from 'react';
import { Paper, Box, Typography, Grid, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import EmailList from './EmailList';
import EmailDetails from './EmailDetails';
import { FrontDeskContext } from './FrontDeskContext';
import './FrontDesk.css';

const GET_EMAILS = `
  query GetEmails {
    emails {
      items {
        aiResponse
        aiParts
        subject
        webLink
        fromAddress
        fromName
        assignedTo
        assignedDateTime
        aiSummary
        aiAnalyzedYN
        aiLegitimate
        aiAnalyzedDateTime
        pentagonAccount
        pentagonLink
        pentagonLinkMethod
        pentagonLinkDateTime
        receivedDateTime
        sentDateTime
        status
        parts {
          condition
          partnumber
          quantity
        }
      }
    }
  }
`;

const FrontDesk = () => {
    const [selectedEmail, setSelectedEmail] = useState(null);
    const navigate = useNavigate();

    const [result] = useQuery(
        GET_EMAILS
    );

    const { data, fetching, error } = result;

    if (fetching) return <CircularProgress />;
    if (error) return <Typography color="error">Error: {error.message}</Typography>;

    const emails = data?.emails?.items || [];

    return (
        <Paper elevation={3} className="frontdesk-container">
            <Typography variant="h4" component="h1" gutterBottom className="frontdesk-title">
                FRONT DESK (Sales Funnel)
            </Typography>

            <FrontDeskContext.Provider value={{ selectedEmail, setSelectedEmail, emails }}>
                <Box className="frontdesk-content">
                    <Grid container spacing={2} className="frontdesk-grid">
                        <Grid item xs={4}>
                            <Paper className="email-list-container" style={{ height: 'calc(100vh - 200px)' }}>
                                <EmailList />
                            </Paper>
                        </Grid>
                        <Grid item xs={8}>
                            <Paper className="email-details-container">
                                <EmailDetails />
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </FrontDeskContext.Provider>
        </Paper>
    );
};

export default FrontDesk;