import React, { useState } from 'react';
import { 
    Box, 
    Typography, 
    TextField, 
    Button, 
    Paper, 
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAuthenticatedPost from '../hooks/useAuthenticatedPost'; // Adjust the import path as needed

const AddGoalPage = () => {
    const navigate = useNavigate();
    const [goalName, setGoalName] = useState('');
    const [targetValue, setTargetValue] = useState('');
    const [period, setPeriod] = useState('');
    const { post, isLoading, error } = useAuthenticatedPost();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const newGoal = {
                name: goalName,
                currentTargetValue: parseFloat(targetValue),
                currentPeriodName: period
            };
            await post('https://localhost:5001/mos/goals', newGoal);
            navigate('/mos/goals');
        } catch (error) {
            console.error('Error creating goal:', error);
        }
    };

    return (
        <Box maxWidth="md" margin="auto" mt={4}>
            <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h4" gutterBottom>Add New Goal</Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Goal Name"
                                value={goalName}
                                onChange={(e) => setGoalName(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Target Value"
                                type="number"
                                value={targetValue}
                                onChange={(e) => setTargetValue(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Period</InputLabel>
                                <Select
                                    value={period}
                                    onChange={(e) => setPeriod(e.target.value)}
                                    required
                                >
                                    <MenuItem value="Monthly">Monthly</MenuItem>
                                    <MenuItem value="Quarterly">Quarterly</MenuItem>
                                    <MenuItem value="Yearly">Yearly</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="flex-end">
                                <Button 
                                    type="button" 
                                    onClick={() => navigate('/mos/goals')} 
                                    sx={{ mr: 2 }}
                                >
                                    Cancel
                                </Button>
                                <Button 
                                    type="submit" 
                                    variant="contained" 
                                    color="primary"
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Adding...' : 'Add Goal'}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            {error && (
                <Typography color="error" mt={2}>
                    Error: {error.message}
                </Typography>
            )}
        </Box>
    );
};

export default AddGoalPage;