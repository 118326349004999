// src/components/ios/purchasing/TopOpenPurchases.js
import React from 'react';
import { Box, Typography, Link, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import useAuthenticatedData from '../../../hooks/useAuthenticatedData';

const columns = [
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'age', headerName: 'Age', width: 100 },
  { field: 'nickname', headerName: 'Nickname', width: 150 },
  { field: 'employee', headerName: 'Employee', width: 120 },
];

const TopOpenPurchases = () => {
  const { data, isLoading, error } = useAuthenticatedData('https://localhost:5001/ios/purchasing/top-open-purchases');

  if (!data || isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Top Open Purchases</Typography>
        <Link href="#" underline="hover">open purchases</Link>
      </Box>
      <Typography variant="subtitle1" mb={1}>
        $XXX (add percent of total open)
      </Typography>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default TopOpenPurchases;