// PerformanceChart.jsx
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Line,
    Cell,
} from 'recharts';
import {
    Box,
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Paper,
} from '@mui/material';

import styles from './PerformanceChart.module.css';

const PerformanceChart = ({
    data,
    timeFrame,
    setTimeFrame,
    mode,
    setMode,
    accountNumber,
    DetailComponent,
    businessKey,
}) => {
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const currentDate = new Date();
    const currentMonthName = currentDate.toLocaleString('default', { month: 'short' });

    console.log('data', data);
    // Extract years data from the generic data structure
    const yearsData = data.years;

    console.log('years', yearsData);

    // Transform all years' months data to match the chart's expected format
    const chartData = useMemo(() => {
        return data.years.flatMap(year =>
            year.months.map(month => {
                const date = new Date(month.date);
                const monthName = new Intl.DateTimeFormat('default', { month: 'short', timeZone: 'UTC' }).format(date);
                return {
                    name: `${monthName} ${year.year}`,
                    fullDate: new Date(month.date),
                    accumulatedRevenue: month.totalRevenue,
                    thisMonthRevenue: month.monthlyContribution,
                    openSales: month.openSales,
                    annualPlan: month.goal,
                    pastYearPerformance: null, // Placeholder for future data
                    year: year.year, // Adding year for potential future use
                };
            })
        ).sort((a, b) => a.fullDate - b.fullDate);
    }, [data.years]);

    const sortedChartData = useMemo(() => {
        return [...chartData].sort((a, b) => a.fullDate.getTime() - b.fullDate.getTime());
    }, [chartData]);

    const getOpacity = (entry) => {
        return entry.fullDate <= currentDate ? 1 : 0.5;
    };

    const renderColorfulLegendText = (value, entry) => {
        const { color } = entry;
        return <span style={{ color }}>{value}</span>;
    };

    let descriptionText = `You are viewing the revenue and goal (if there is one) for the selected data for 1, 2, or 3 years. You've selected the ${mode === 'annual' ? 'yearly' : 'monthly'} view, `;

    if (mode === 'annual') {
        descriptionText += "so you're seeing the revenue and goal accumulate over the year, then reset in January. Additionally, Open Sales are displayed in yellow on top of the revenue bars.";
    } else {
        descriptionText += "so you're seeing the revenue remain flat for each month, with the goal displayed as a line. Additionally, Open Sales are displayed in yellow on top of the revenue bars.";
    }

    const handleChartClick = (data) => {
        if (data && data.activeLabel) {
            const [monthName, year] = data.activeLabel.split(' ');
            setSelectedMonth(monthName);
            setSelectedYear(parseInt(year, 10));
        }
    };

    const handleBack = () => {
        setSelectedMonth(null);
        setSelectedYear(new Date().getFullYear());
    };

    const formatYAxis = (value) => {
        return `$${value.toLocaleString()}`;
    };

    const formatTooltip = (value, name) => {
        return [`$${Math.round(value).toLocaleString()}`, name];
    };

    return (
        <Box className={styles["chart-container"]} sx={{
            display: 'flex',
            flexDirection: 'column', // Changed to column by default
            color: 'white',
            padding: 4,
            minHeight: '100vh',
        }}>
            {!selectedMonth ? (
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, flexGrow: 1 }}>
                    {/* Chart Section - 80% */}
                    <Box className={styles["chart-section"]} sx={{
                        flexBasis: { md: '80%' },
                        flexGrow: 1,
                        marginRight: { md: 4 },
                        marginBottom: { xs: 4, md: 0 },
                    }}>
                        <Typography variant="h5" gutterBottom>
                            Performance to Plan
                        </Typography>
                        <ResponsiveContainer width="100%" height={500}>
                            <ComposedChart
                                data={sortedChartData}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                                style={{
                                    backgroundColor: '#111111',
                                    borderRadius: '8px',
                                }}
                                onClick={handleChartClick}
                            >
                                <XAxis
                                    dataKey="name" // Uses 'name' for XAxis
                                    stroke="white"
                                    interval={0}
                                    style={{ fontSize: '0.8rem' }}
                                    tick={({ x, y, payload }) => {
                                        console.log('payload', payload);

                                        // split the month name from the year
                                        const [month, year] = payload.value.split(' ');

                                        const currentMonth = currentDate.toLocaleString('default', { month: 'short' });
                                        console.log('currentMonth', currentMonth);

                                        const isCurrentMonth = month === currentMonth;

                                        const isCurrentYear = year === currentDate.getFullYear().toString();
                                        const isCurrentYearAndMonth = isCurrentMonth && isCurrentYear;


                                        return (
                                            <g transform={`translate(${x},${y})`}>
                                                <text
                                                    x={0}
                                                    y={0}
                                                    dy={16}
                                                    textAnchor="middle"
                                                    style={{ fontSize: '0.8rem' }}
                                                    fill={isCurrentYearAndMonth ? 'yellow' : 'white'}
                                                >
                                                    {month}
                                                </text>
                                            </g>
                                        );
                                    }}
                                />
                                <YAxis
                                    stroke="white"
                                    style={{ fontSize: '0.8rem' }}
                                    tickFormatter={formatYAxis}
                                />
                                <Tooltip
                                    contentStyle={{ backgroundColor: '#333333', color: 'white', border: 'none' }}
                                    itemStyle={{ color: 'white' }}
                                    labelStyle={{ color: 'white' }}
                                    formatter={formatTooltip}
                                />
                                <Legend
                                    wrapperStyle={{ color: 'white' }}
                                    formatter={renderColorfulLegendText}
                                />
                                <Bar
                                    dataKey="accumulatedRevenue"
                                    stackId="a"
                                    name="Accumulated Revenue"
                                    fill="rgba(54, 162, 235, 0.6)"
                                    isAnimationActive={false}
                                >
                                    {sortedChartData.map((entry, index) => {
                                        const isCurrentMonth =
                                            entry.fullDate.getMonth() === currentDate.getMonth() &&
                                            entry.fullDate.getFullYear() === currentDate.getFullYear();

                                        return (
                                            <Cell
                                                key={`cell-accumulated-${index}`}
                                                fill="rgba(54, 162, 235, 0.3)"
                                                fillOpacity={getOpacity(entry)}
                                                cursor="pointer"
                                            />
                                        );
                                    })}
                                </Bar>

                                <Bar
                                    dataKey="thisMonthRevenue"
                                    stackId="a"
                                    name="Monthly Contribution"
                                    fill="rgb(54, 162, 235)"
                                    isAnimationActive={false}
                                >
                                    {sortedChartData.map((entry, index) => (
                                        <Cell
                                            key={`cell-monthly-${index}`}
                                            fill="rgba(54, 162, 235, 0.6)"
                                            fillOpacity={getOpacity(entry)}
                                            cursor="pointer"
                                        />
                                    ))}
                                </Bar>
                                <Bar
                                    dataKey="openSales"
                                    stackId="a"
                                    name="Open Sales"
                                    isAnimationActive={false}
                                    fill="rgb(255, 206, 86)"
                                >
                                    {sortedChartData.map((entry, index) => (
                                        <Cell
                                            key={`cell-opensales-${index}`}
                                            fill="rgba(255, 206, 86, 0.6)"
                                            fillOpacity={getOpacity(entry)}
                                            cursor="pointer"
                                        />
                                    ))}
                                </Bar>
                                <Line
                                    type="linear"
                                    dataKey="annualPlan"
                                    stroke="#00a900"
                                    name="Annual Plan"
                                    strokeWidth={4}
                                    dot={false}
                                    connectNulls={false}
                                />
                                <Line
                                    type="linear"
                                    dataKey="pastYearPerformance"
                                    stroke="#808080"
                                    name="Past Year Performance"
                                    strokeWidth={4}
                                    dot={false}
                                />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </Box>

                    {/* Controls Section - 20% */}
                    <Box
                        className={styles["controls-section"]}
                        sx={{
                            flexBasis: { md: '20%' },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {/* Time Selection Control */}
                        <FormControl component="fieldset" sx={{ marginBottom: 4 }}>
                            <FormLabel component="legend">
                                <Typography variant="h6" gutterBottom>
                                    Time
                                </Typography>
                            </FormLabel>
                            <RadioGroup
                                aria-label="timeRange"
                                name="timeRange"
                                value={timeFrame}
                                onChange={(e) => setTimeFrame(e.target.value)}
                            >
                                <FormControlLabel
                                    value="1year"
                                    control={<Radio />}
                                    label="This year"
                                />
                                <FormControlLabel
                                    value="2years"
                                    control={<Radio />}
                                    label="Past 2 years"
                                />
                                <FormControlLabel
                                    value="3years"
                                    control={<Radio />}
                                    label="Past 3 years"
                                />
                            </RadioGroup>
                        </FormControl>

                        {/* View Type Selection Control */}
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                <Typography variant="h6" gutterBottom>
                                    View Type
                                </Typography>
                            </FormLabel>
                            <RadioGroup
                                aria-label="viewType"
                                name="viewType"
                                value={mode === 'annual' ? 'Yearly' : 'Monthly'}
                                onChange={(e) => setMode(e.target.value === 'Yearly' ? 'annual' : 'month')}
                                row
                            >
                                <FormControlLabel
                                    value="Yearly"
                                    control={<Radio />}
                                    label="Yearly"
                                />
                                <FormControlLabel
                                    value="Monthly"
                                    control={<Radio />}
                                    label="Monthly"
                                />
                            </RadioGroup>
                        </FormControl>

                        {/* Description Section */}
                        <Paper
                            elevation={3}
                            sx={{
                                marginTop: 4,
                                padding: 2,
                                backgroundColor: '#1e1e1e',
                                color: 'lightgray',
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                What you're seeing
                            </Typography>
                            <Typography variant="body2">{descriptionText}</Typography>
                        </Paper>
                    </Box>
                </Box>
            ) : (
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', padding: 0 }}>
                    <DetailComponent
                        businessKey={businessKey}
                        month={selectedMonth}
                        year={selectedYear}
                        onBack={handleBack}
                    />
                </Box>
            )}
        </Box>
     );
    };

    PerformanceChart.propTypes = {
        data: PropTypes.shape({
            years: PropTypes.arrayOf(
                PropTypes.shape({
                    year: PropTypes.number.isRequired,
                    annualGoal: PropTypes.number.isRequired,
                    totalRevenue: PropTypes.number.isRequired,
                    months: PropTypes.arrayOf(
                        PropTypes.shape({
                            date: PropTypes.string.isRequired,
                            totalRevenue: PropTypes.number.isRequired,
                            openSales: PropTypes.number.isRequired,
                            monthlyContribution: PropTypes.number.isRequired,
                            goal: PropTypes.number.isRequired,
                        })
                    ).isRequired,
                })
            ).isRequired,
        }).isRequired,
        timeFrame: PropTypes.string.isRequired,
        setTimeFrame: PropTypes.func.isRequired,
        mode: PropTypes.string.isRequired,
        setMode: PropTypes.func.isRequired,
        accountNumber: PropTypes.string.isRequired,
        businessKey: PropTypes.string.isRequired,
        DetailComponent: PropTypes.elementType.isRequired,
    };

    export default PerformanceChart;
