import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarQuickFilter,
    GridToolbarExport,
} from '@mui/x-data-grid';

const MetricGrid = ({ data, isLoading, error, handleRowClick }) => {
    const navigate = useNavigate();

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ backgroundColor: 'black', p: 1 }}>
              <GridToolbarColumnsButton sx={{ color: 'white' }} />
                <GridToolbarFilterButton sx={{ color: 'white' }} />
                <GridToolbarDensitySelector sx={{ color: 'white' }} />
                <GridToolbarQuickFilter 
                    sx={{ 
                        color: 'white', 
                        '& .MuiInputBase-root': { 
                            color: 'white',
                            '& .MuiInputBase-input::placeholder': {
                                color: 'rgba(255, 255, 255, 0.7)',
                            },
                        },
                        '& .MuiIconButton-root': {
                            color: 'white',
                        },
                    }} 
                />
                <GridToolbarExport sx={{ color: 'white' }} />
            </GridToolbarContainer>
        );
    }

 
 

    const columns = [
 
        { field: 'metricName', headerName: 'Name', width: 400 },
        { field: 'metricDescription', headerName: 'Level', width: 100 },
    ];

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography color="error">Error loading metrics: {error.message}</Typography>
            </Box>
        );
    }

    if (!data || data.length === 0) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography>No metrics data available.</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={data}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                onRowClick={handleRowClick}
                disableSelectionOnClick
                autoHeight
                slots={{
                    toolbar: CustomToolbar,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: 'black',
                        color: 'white',
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        letterSpacing: '0.1em',
                        fontFamily: "'Roboto Condensed', sans-serif",
                    },
                    '& .MuiDataGrid-row:nth-of-type(even)': {
                        backgroundColor: '#262626',
                    },
                    '& .MuiDataGrid-row:nth-of-type(odd)': {
                        backgroundColor: '#333333',
                    },
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                        '& button': {
                            color: 'white',
                        },
                        '& .MuiInputBase-root': {
                            color: 'white',
                        },
                    },
                }}
            />
        </Box>
    );
};

export default MetricGrid;