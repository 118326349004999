import React, { createContext, useContext, useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, CircularProgress, Container, Grid, Paper, Button } from '@mui/material';
import useAuthenticatedData from '../../hooks/useAuthenticatedData'; // Adjust the import path as needed
import ReportsGrid from '../ReportsGrid';
import CompanyStack from '../dashboard/CompanyStack';
import LevelDetail from './LevelDetail';
import { useNavigate } from 'react-router-dom';

const Overview = () => {


    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState(null);



    const handleItemSelect = (item) => {
        setSelectedItem(item);
    };



    return (
        <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
            <Typography variant="h1" component="h1" gutterBottom align="left">
                OVERVIEW
            </Typography>
            <Box sx={{ mt: 2 }}>
                <Typography variant="body1" paragraph align="left">
                    Here we show an overview of the company health, across departments, with respect to metrics, goals, and KPIs.
                </Typography>
     
            
            </Box>
        </Paper>

    )


    /*
      <Box>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Paper elevation={3} style={{ height: '80vh', overflow: 'auto' }}>
                        <CompanyStack onItemSelect={handleItemSelect} />
                    </Paper>
                </Grid>
                <Grid item xs={8}>
                    <Paper elevation={3} style={{ height: '80vh', padding: '20px', overflow: 'auto' }}>
                        <LevelDetail selectedItem={selectedItem?.id} /> 
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    */
};

export default Overview;