import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
    List,
    ListItem,
    ListItemText,
    Tab,
    Tabs,
    Box,
    Chip
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import useAuthenticatedData from '../hooks/useAuthenticatedData';
import useAuthenticatedPost from '../hooks/useAuthenticatedPost';

const ManageKpiAssignmentsDialog = ({ open, onClose, kpiId, mode = 'assign' }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [tabValue, setTabValue] = useState(0);

    const { data: allUsers, isLoading: isLoadingAllUsers, refetch: refetchAllUsers } = useAuthenticatedData('https://localhost:5001/admin/users');
    const { data: assignedUsers, isLoading: isLoadingAssigned, refetch: refetchAssigned } = useAuthenticatedData(`https://localhost:5001/kpis/${kpiId}/assigned-users`);
    const { post } = useAuthenticatedPost();

    const hasInitialFetchBeenMade = useRef(false);

    useEffect(() => {
        if (open && !hasInitialFetchBeenMade.current) {
            refetchAllUsers();
            refetchAssigned();
            hasInitialFetchBeenMade.current = true;
        }
    }, [open]);

    useEffect(() => {
        if (!open) {
            hasInitialFetchBeenMade.current = false;
        }
    }, [open]);

    useEffect(() => {
        if (mode === 'edit' && assignedUsers) {
            setSelectedUsers(assignedUsers);
        }
    }, [mode, assignedUsers]);

    const filteredUsers = useMemo(() => {
        if (!allUsers) return [];
        return allUsers.filter(user =>
            user && user.name && user.email &&
            (user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }, [allUsers, searchTerm]);

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Name', width: 130 },
        { field: 'email', headerName: 'Email', width: 200 },
        {
            field: 'status',
            headerName: 'Status',
            width: 120,
            renderCell: (params) => (
                <Chip
                    label={selectedUsers.some(u => u.id === params.row.id) ? 'Assigned' : 'Unassigned'}
                    color={selectedUsers.some(u => u.id === params.row.id) ? 'primary' : 'default'}
                />
            ),
        },
    ];

    const handleUserSelection = (selectionModel) => {
        const selectedUserData = allUsers.filter(user => selectionModel.includes(user.id));
        setSelectedUsers(selectedUserData);
    };

    const handleSave = async () => {
        try {
            if (mode === 'assign') {
                await post(`https://localhost:5001/kpis/${kpiId}/assign`, {
                    userIds: selectedUsers.map(user => user.id)
                });
            } else {
                const currentAssignedIds = assignedUsers.map(user => user.id);
                const newAssignedIds = selectedUsers.map(user => user.id);

                const toAssign = newAssignedIds.filter(id => !currentAssignedIds.includes(id));
                const toUnassign = currentAssignedIds.filter(id => !newAssignedIds.includes(id));

                await post(`https://localhost:5001/kpis/${kpiId}/update-assignments`, {
                    assignUserIds: toAssign,
                    unassignUserIds: toUnassign
                });
            }
            refetchAssigned();
            onClose();
        } catch (error) {
            console.error('Error updating KPI assignments:', error);
        }
    };

    if (!open) return null;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{mode === 'assign' ? 'Assign KPI' : 'Edit KPI Assignments'}</DialogTitle>
            <DialogContent>
                <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)}>
                    <Tab label="Users" />
                    <Tab label="Groups" />
                    <Tab label="Roles" />
                </Tabs>
                <Box sx={{ mt: 2 }}>
                    {tabValue === 0 && (
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    label="Search Users"
                                    variant="outlined"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    sx={{ mb: 2 }}
                                />
                                <div style={{ height: 400, width: '100%' }}>
                                    <DataGrid
                                        rows={filteredUsers}
                                        columns={columns}
                                        pageSize={5}
                                        checkboxSelection
                                        onSelectionModelChange={handleUserSelection}
                                        loading={isLoadingAllUsers || isLoadingAssigned}
                                        selectionModel={selectedUsers.map(u => u.id)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <List>
                                    {selectedUsers.map(user => (
                                        <ListItem key={user.id}>
                                            <ListItemText primary={user.name} secondary={user.email} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    )}
                    {tabValue === 1 && <div>Groups content (to be implemented)</div>}
                    {tabValue === 2 && <div>Roles content (to be implemented)</div>}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} variant="contained" color="primary">
                    {mode === 'assign' ? 'Assign' : 'Save Changes'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ManageKpiAssignmentsDialog;