// Customer.jsx
import React from 'react';
import { Paper } from '@mui/material';
import SalespersonTabSet from './SalespersonTabSet';
import './Salesperson.css'; // Create this CSS file

const Salesperson = () => {
    return (
        <Paper className="customer-paper">
            <SalespersonTabSet />
        </Paper>
    );
};

export default Salesperson;
