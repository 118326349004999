import React from 'react';
import { Paper, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
 
 

const Inventory = () => {
    const navigate = useNavigate();
 

    return (


        <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4, position: 'relative' }}>
        <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
 
        </Box>
        <Typography variant="h1" component="h1" gutterBottom align="left">
          INVENTORY
        </Typography>

        <Typography variant="body1" align="left">
            Inventory details & age
       </Typography>
        
      </Paper>


    );

};

export default Inventory;