import React, { useState } from 'react';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import useAuthenticatedData from '../hooks/useAuthenticatedData';
import AddGroupDialog from './AddGroupDialog';


const Groups = () => {
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);

    const { data: groups, isLoading, error } = useAuthenticatedData('https://localhost:5001/admin/groups');

    const columns = [
        { field: 'name', headerName: 'Name', width: 500 },
    ];

    const handleRowClick = (params) => {
        navigate(`/groups/${params.id}`);
    }

    const handleOpenDialog = () => {
        navigate('/groups/new');
    }

 
    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography color="error">Error: {error.message}</Typography>
            </Box>
        );
    }

    if (!groups || groups.length === 0) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography>No group data available.</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4" component="h1">
                    Groups
                </Typography>
                <Button variant="contained" color="primary" onClick={handleOpenDialog}>
                    Add Group
                </Button>
            </Box>
            <DataGrid
                rows={groups}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                disableSelectionOnClick
                onRowClick={handleRowClick}
            />
         </Box>
    );
};

export default Groups;