import React, { useMemo, useState, useEffect } from 'react';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { styled, alpha } from '@mui/material/styles';
import { Box, Typography, Collapse, Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { animated, useSpring } from '@react-spring/web';
import { treeItemClasses } from '@mui/x-tree-view/TreeItem';
import clsx from 'clsx';
import { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view/useTreeItem2';
import {
    TreeItem2Content,
    TreeItem2IconContainer,
    TreeItem2Label,
    TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import useAuthenticatedData from '../hooks/useAuthenticatedData';

function DotIcon() {
    return (
        <Box
            sx={{
                width: 6,
                height: 6,
                borderRadius: '70%',
                bgcolor: 'warning.main',
                display: 'inline-block',
                verticalAlign: 'middle',
                zIndex: 1,
                mx: 1,
            }}
        />
    );
}

const StyledTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
    color:
        theme.palette.mode === 'light'
            ? theme.palette.grey[800]
            : theme.palette.grey[400],
    position: 'relative',
    [`& .${treeItemClasses.groupTransition}`]: {
        marginLeft: theme.spacing(3.5),
    },
}));

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
    flexDirection: 'row',
    borderRadius: theme.spacing(0.7),
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    fontWeight: 500,
    [`&.Mui-expanded `]: {
        '&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.primary.main
                    : theme.palette.primary.dark,
        },
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: '16px',
            top: '44px',
            height: 'calc(100% - 48px)',
            width: '1.5px',
            backgroundColor:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[300]
                    : theme.palette.grey[700],
        },
    },
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        color: theme.palette.mode === 'light' ? theme.palette.primary.main : 'white',
    },
    [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
        backgroundColor:
            theme.palette.mode === 'light'
                ? theme.palette.primary.main
                : theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
    },
}));

const AnimatedCollapse = animated(Collapse);

function TransitionComponent(props) {
    const style = useSpring({
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
        },
    });

    return <AnimatedCollapse style={style} {...props} />;
}

const StyledTreeItemLabelText = styled(Typography)({
    color: 'inherit',
    fontFamily: 'General Sans',
    fontWeight: 500,
});

function CustomLabel({ children, ...other }) {
    return (
        <TreeItem2Label
            {...other}
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
            }}
        >
            {children}
        </TreeItem2Label>
    );
}

const isExpandable = (reactChildren) => {
    if (Array.isArray(reactChildren)) {
        return reactChildren.length > 0 && reactChildren.some(isExpandable);
    }
    return Boolean(reactChildren);
};

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const { id, itemId, label, disabled, children, onItemSelect, ...other } = props;

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
        publicAPI,
    } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

    const item = publicAPI.getItem(itemId);
    const expandable = isExpandable(children);

    const handleItemClick = (event) => {
        event.stopPropagation();
        onItemSelect(item);
    };

    return (
        <TreeItem2Provider itemId={itemId}>
            <StyledTreeItemRoot {...getRootProps(other)} onClick={handleItemClick}>
                <CustomTreeItemContent
                    {...getContentProps({
                        className: clsx('content', {
                            'Mui-expanded': status.expanded,
                            'Mui-selected': status.selected,
                            'Mui-focused': status.focused,
                            'Mui-disabled': status.disabled,
                        }),
                    })}
                >
                    <TreeItem2IconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </TreeItem2IconContainer>
                    <CustomLabel {...getLabelProps()}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <Typography variant="body2">{item.label}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                 
                            </Box>
                        </Box>
                    </CustomLabel>
                </CustomTreeItemContent>
                {children && <TransitionComponent {...getGroupTransitionProps()} />}
            </StyledTreeItemRoot>
        </TreeItem2Provider>
    );
});

export default function SelectableCompanyStack({ onItemSelect, initialSelectedItemId }) {
    const { data, isLoading, error } = useAuthenticatedData('https://localhost:5001/mos/company-levels/');
    const [selectedItem, setSelectedItem] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [currentLevel, setCurrentLevel] = useState(null);
    const [expandedItems, setExpandedItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const treeItems = useMemo(() => {
        if (!data || !Array.isArray(data) || data.length === 0) return [];

        const convertToTreeItems = (levels) => {
            return levels.map(level => ({
                id: level.id.toString(),
                label: level.name,
                description: level.description,
                levelType: level.levelType,
                depth: level.depth,
                healthyKPIs: 0,
                totalKPIs: 0,
                children: level.childLevels ? convertToTreeItems(level.childLevels) : null
            }));
        };

        return convertToTreeItems(data);
    }, [data]);

    useEffect(() => {
        if (initialSelectedItemId != null && treeItems.length > 0) {
            const initialId = Number(initialSelectedItemId);
            if (!isNaN(initialId)) {
                const initialItem = findItemById(treeItems, initialId);
                if (initialItem) {
                    setCurrentLevel(initialItem);
                    setSelectedItem(initialItem);
                    setSelectedItems([initialItem.id]);
                    const path = findPathToItem(treeItems, initialId);
                    if (path) {
                        setExpandedItems(path.slice(0, -1));
                    }
                }
            }
        }
    }, [initialSelectedItemId, treeItems]);

    // Update findItemById to use number comparison
    const findItemById = (items, id) => {
        for (let item of items) {
            if (Number(item.id) === id) return item;
            if (item.children) {
                const found = findItemById(item.children, id);
                if (found) return found;
            }
        }
        return null;
    };

    const findPathToItem = (items, targetId, path = []) => {
        for (let item of items) {
            if (Number(item.id) === Number(targetId)) {
                return [...path, item.id];
            }
            if (item.children) {
                const foundPath = findPathToItem(item.children, targetId, [...path, item.id]);
                if (foundPath) return foundPath;
            }
        }
        return null;
    };

    const handleSelect = (event, itemIds) => {
        if (!itemIds || itemIds.length === 0) return;

        const newSelectedItem = findItemById(treeItems, itemIds[0]);
        if (newSelectedItem && newSelectedItem !== selectedItem) {
            setSelectedItem(newSelectedItem);
            setSelectedItems([newSelectedItem.id]);
            setHasChanges(true);
        }
    };

    const handleExpand = (event, itemIds) => {
        setExpandedItems(itemIds);
    };

    const handleSave = () => {
        if (selectedItem) {
            setCurrentLevel(selectedItem);
            onItemSelect(selectedItem.id);
            setHasChanges(false);
        }
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Current Level: {currentLevel ? currentLevel.label : 'None'}
            </Typography>
            <RichTreeView
                items={treeItems}
                expandedItems={expandedItems}
                selectedItems={selectedItems}
                sx={{ height: 'fit-content', flexGrow: 1, maxWidth: 400, overflowY: 'auto', marginBottom: 2 }}
                slots={{ item: CustomTreeItem }}
                onSelectedItemsChange={handleSelect}
                onExpandedItemsChange={handleExpand}
                slotProps={{
                    item: {
                        onItemSelect: (item) => {
                            setSelectedItem(item);
                            setSelectedItems([item.id]);
                            setHasChanges(true);
                        },
                    },
                }}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={!hasChanges}
            >
                Update Level
            </Button>
        </Box>
    );
}