import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, Box, Typography, CircularProgress } from '@mui/material';
import useAuthenticatedData from '../../hooks/useAuthenticatedData'; // Adjust the import path as needed
import KpisGrid from '../dashboard/KpisGrid';

const KPIs = () => {
    return (


        <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
            <Typography variant="h1" component="h1" gutterBottom align="left">
                KPIs
            </Typography>
            <Box sx={{ mt: 2 }}>
                <KpisGrid />
            </Box>
        </Paper>



    )
};

export default KPIs;