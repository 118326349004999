// src/components/CustomerLandingPage.js

import React, { useMemo, useState } from 'react';
import { Box, Grid, Typography, Paper, Button } from '@mui/material';
import './CustomerLandingPage.css';
import { ArrowForward } from '@mui/icons-material';
import { gql, useQuery } from '@apollo/client';

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
};

const formatCurrency = (amount) => {
  if (amount === null || amount === undefined) return 'N/A';
  if (amount === 0) return '$0';
  const absAmount = Math.abs(amount).toLocaleString();
  return amount < 0 ? `-$${absAmount}` : `$${absAmount}`;
};

// Define GraphQL Query
const GET_CUSTOMER_LANDING_PAGE = gql`
  query GetCustomerLandingPage($acctNo: String!) {
    customerLandingDetailsByAccountNumber(acctNo: $acctNo) {
      acctNo
      name
      lastQuoteDate
      lastSalesOrderDate
      lastCallTrackerDate
      healthStatus
      creditRemaining
      creditLimit
      ytdRevenue
      ytdGoal
      netNewOrders
      openOrders
    }
  }
`;

const InfoItem = ({ label, value }) => (
  <Box className="info-item">
    <Typography className="info-label">{label}:</Typography>
    <Typography className="info-value">{value}</Typography>
  </Box>
);

const Section = ({ title, children, buttonText, onViewMore }) => (
  <Paper className="section">
    <Typography className="section-title">{title}</Typography>
    <Box className="section-content">
      {children}
    </Box>
    <Button variant="contained" endIcon={<ArrowForward />} className="view-more-button" onClick={onViewMore}>
      {buttonText}
    </Button>
  </Paper>
);

const CustomerLandingPage = ({ setActiveTab, selectedCustomer }) => {
  const { data, loading, error } = useQuery(GET_CUSTOMER_LANDING_PAGE, {
    variables: { acctNo: selectedCustomer?.acctNo || '' },
    skip: !selectedCustomer,
    fetchPolicy: 'network-only',
  });

  if (loading) return <Typography sx={{ p: 3 }}>Loading...</Typography>;
  if (error) return <Typography sx={{ p: 3 }}>Error: {error.message}</Typography>;
  if (!data) return <Typography sx={{ p: 3 }}>No data available</Typography>;

  const customerData = data.customerLandingDetailsByAccountNumber;

  return (
    <Box className="customer-landing-page">
      <Grid container spacing={2} className="grid-container">
        {/* Production Section */}
        <Grid item xs={12} sm={6} className="grid-item">
          <Section title="Production" onViewMore={() => setActiveTab(2)} buttonText="Production">
            <Grid container spacing={2} className="nested-grid-container">
              <Grid item xs={12} sm={6} className="nested-grid-item">
                <InfoItem label="Last Quote Date" value={formatDate(customerData.lastQuoteDate)} />
                <InfoItem label="Last Sales Date" value={formatDate(customerData.lastSalesOrderDate)} />
                <InfoItem label="Last Call Tracker" value={formatDate(customerData.lastCallTrackerDate)} />
              </Grid>
            </Grid>
          </Section>
        </Grid>

        {/* Status Section */}
        <Grid item xs={12} sm={6} className="grid-item">
          <Section title="Status" onViewMore={() => setActiveTab(1)} buttonText="Profile">
            <Grid container spacing={2} className="nested-grid-container">
              <Grid item xs={12} sm={6} className="nested-grid-item">
                <InfoItem label="Health" value={customerData.healthStatus || 'N/A'} />
                <InfoItem label="Credit Remaining" value={formatCurrency(customerData.creditRemaining)} />
                <InfoItem label="Credit Limit" value={formatCurrency(customerData.creditLimit)} />
              </Grid>
            </Grid>
          </Section>
        </Grid>

        {/* Performance Section */}
        <Grid item xs={12} sm={6} className="grid-item">
          <Section title="Performance" onViewMore={() => setActiveTab(3)} buttonText="Performance">
            <Grid container spacing={2} className="nested-grid-container">
              <Grid item xs={12} sm={6} className="nested-grid-item">
                <InfoItem label="Year to Date Revenue" value={formatCurrency(customerData.ytdRevenue)} />
                <InfoItem label="Annual Goal" value={formatCurrency(customerData.ytdGoal)} />
              </Grid>
              <Grid item xs={12} sm={6} className="nested-grid-item">
                <InfoItem label="Net New Orders" value={formatCurrency(customerData.netNewOrders)} />
                <InfoItem label="Open Orders" value={formatCurrency(customerData.openOrders)} />
              </Grid>
            </Grid>
          </Section>
        </Grid>

        {/* Proficiency Section */}
        <Grid item xs={12} sm={6} className="grid-item">
          <Section title="Proficiency" onViewMore={() => setActiveTab(4)} buttonText="Proficiency">
            <Grid container spacing={2} className="nested-grid-container">
              <Grid item xs={12} sm={6} className="nested-grid-item">
                <InfoItem label="Account Number" value={customerData.acctNo} />
                <InfoItem label="Customer Name" value={customerData.name} />
              </Grid>
            </Grid>
          </Section>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerLandingPage;
