import React from 'react';
import Modal from 'react-modal';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        padding: '40px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        width: '30%',
        backgroundColor: '#fff'
    },
};

const buttonStyles = {
    padding: '12px 30px',
    fontSize: '18px',
    color: '#fff',
    backgroundColor: '#0056b3', // A business-like blue
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
    transition: 'background-color 0.3s',
    ':hover': {
        backgroundColor: '#004494'
    }
};

const LoginModal = ({ isOpen, onRequestClose }) => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginPopup(loginRequest).catch((e) => {
            console.error(e);
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
            contentLabel="Login Modal"
        >
            <img src="/its_logo.png" alt="ITS Company Logo" style={{ width: '60%', marginBottom: '20px' }} />
            <h2 style={{ color: '#333', marginBottom: '20px' }}>Please login using your ITS account</h2>
            <button onClick={handleLogin} style={buttonStyles}>Login</button>
        </Modal>
    );
};

export default LoginModal;
