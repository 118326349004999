import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Box, Typography, CircularProgress, Alert } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { GET_SALESPERSON_INVOICES_FOR_MONTH, GET_SALESPERSON_OPEN_SALES_ORDERS_FOR_MONTH } from '../../../queries';
import { enterpriseGridOverrideTheme } from '../../../themes/EnterpriseGridTheme';

const SalespersonMonthPerformanceDetails = ({ month, year, onBack, businessKey }) => {
  const monthNumber = useMemo(() => {
    const date = new Date(`${month} 1, ${year}`);
    return date.getMonth() + 1;
  }, [month, year]);

  const {
    data: invoicesData,
    loading: loadingInvoices,
    error: errorInvoices,
  } = useQuery(GET_SALESPERSON_INVOICES_FOR_MONTH, {
    variables: {
      ccode: businessKey,
      year: year,
      month: monthNumber,
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: openSalesData,
    loading: loadingOpenSales,
    error: errorOpenSales,
  } = useQuery(GET_SALESPERSON_OPEN_SALES_ORDERS_FOR_MONTH, {
    variables: {
      ccode: businessKey,
      year: year,
      month: monthNumber,
    },
    fetchPolicy: 'network-only',
  });

  const invoiceColumns = useMemo(() => [
    { field: 'docNo', headerName: 'Pentagon Doc', width: 130 },
    { 
      field: 'lineTotal', 
      headerName: 'Line Total', 
      width: 130,
      type: 'number',
      valueFormatter: (params) => params != null ? `$${params.toLocaleString()}` : '',
    },
    { 
      field: 'qtyShipped', 
      headerName: 'Shipped', 
      width: 100,
      type: 'number',
    },
     
    { field: 'partnumber', headerName: 'Part Number', width: 150 },
    { field: 'condition', headerName: 'Condition', width: 120 },
    { field: 'serialNumber', headerName: 'Serial Number', width: 150 },
    { field: 'description', headerName: 'Description', width: 200 },
  ], []);

  const openSalesColumns = useMemo(() => [
    { 
      field: 'estShipDate', 
      headerName: 'Ship Date', 
      width: 120,
      type: 'date',
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(params.value);
          return date.toLocaleDateString('en-US', { 
            month: '2-digit', 
            day: '2-digit', 
            year: 'numeric' 
          });
        }
        return '';
      }
    },
    { field: 'docNo', headerName: 'Pentagon Doc', width: 130 },
    { 
      field: 'lineTotal', 
      headerName: 'Line Total', 
      width: 130,
      type: 'number',
   //   valueFormatter: (params) => params.value != null ? `$${params.value.toLocaleString()}` : '',
    },
    { 
      field: 'qtyOrdered', 
      headerName: 'Ordered', 
      width: 100,
      type: 'number',
    },
    { 
      field: 'qtyShipped', 
      headerName: 'Shipped', 
      width: 100,
      type: 'number',
    },
    
    { field: 'partnumber', headerName: 'Part Number', width: 150 },
    { field: 'condition', headerName: 'Condition', width: 120 },
    { field: 'serialNumber', headerName: 'Serial Number', width: 150 },
    { field: 'description', headerName: 'Description', width: 200 },
  ], []);

  const invoiceRows = useMemo(() => {
    if (!invoicesData?.salespersonInvoicesForMonth) return [];
    return invoicesData.salespersonInvoicesForMonth.map((item, index) => ({
      id: index,
      ...item,
    }));
  }, [invoicesData]);

  const openSalesRows = useMemo(() => {
    if (!openSalesData?.salespersonOpenSalesOrdersForMonth) return [];
    return openSalesData.salespersonOpenSalesOrdersForMonth.map((item, index) => ({
      id: index,
      ...item,
    }));
  }, [openSalesData]);

  const generateExportFileName = (prefix) => {
    return `${businessKey}-${year}-${monthNumber}-${prefix}`;
  };

  return (
    <Box sx={{ padding: 0 }}>
      <Button variant="contained" onClick={onBack} sx={{ marginBottom: 2 }}>
        Back to Chart
      </Button>
      <Typography variant="h6" gutterBottom>
        Details for {new Date(`${month} 1, ${year}`).toLocaleString('default', { month: 'long' })}, {year}
      </Typography>

      {/* Invoices Table */}
      <Typography variant="subtitle1" gutterBottom>
        Invoices
      </Typography>
      {loadingInvoices ? (
        <CircularProgress />
      ) : errorInvoices ? (
        <Alert severity="error">Error loading invoices: {errorInvoices.message}</Alert>
      ) : (
        <Box sx={{ height: 280, width: '100%', marginBottom: '2rem' }}>
          <DataGrid
            rows={invoiceRows}
            columns={invoiceColumns}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 25]}
            disableSelectionOnClick
            sx={enterpriseGridOverrideTheme}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                csvOptions: { fileName: generateExportFileName('invoices') },
                printOptions: { fileName: generateExportFileName('invoices') }
              }
            }}
          />
        </Box>
      )}

      {/* Open Sales Table */}
      <Typography variant="subtitle1" gutterBottom>
        Open Sales
      </Typography>
      {loadingOpenSales ? (
        <CircularProgress />
      ) : errorOpenSales ? (
        <Alert severity="error">Error loading open sales: {errorOpenSales.message}</Alert>
      ) : (
        <Box sx={{ height: 280, width: '100%' }}>
          <DataGrid
            rows={openSalesRows}
            columns={openSalesColumns}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 25]}
            disableSelectionOnClick
            sx={enterpriseGridOverrideTheme}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                csvOptions: { fileName: generateExportFileName('open-sales') },
                printOptions: { fileName: generateExportFileName('open-sales') }
              }
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default SalespersonMonthPerformanceDetails;