// src/components/CustVendDimDataGrid.jsx

import React, { useState, useMemo, useEffect, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery, gql } from "@apollo/client";
import { Paper, Box, Typography } from "@mui/material";
import FilterControls from "./FilterControls";
import "./CustVendDimDataGrid.css";
import { useNavigate, useSearchParams } from "react-router-dom";

// Helper functions for formatting
const formatCurrency = (value) => {
  if (value === null || value === undefined) return "";
  if (value >= 1000) {
    return `$${(value / 1000).toFixed(1)}K`;
  }
  return `$${value.toFixed(2)}`;
};

const formatDate = (value) => {
  if (!value) return "";
  const date = new Date(value);
  if (isNaN(date)) return "";
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

const GET_CUST_VEND_DIM = gql`
  query GetCustVendDim(
    $skip: Int!
    $take: Int!
    $where: CustVendDimFilterInput
    $order: [CustVendDimSortInput!]
  ) {
    custVendDim(skip: $skip, take: $take, where: $where, order: $order) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        acctNo
        priority
        name
        lastQuoted
        lastCallTracker
        lastSODate
        creditLimit
        creditTerms
        customerKhealth
        customerHealth
        creditInsurance
        isValid
         isAirframeKeyCustomer
        isEngineKeyCustomer
        bdcrms
        avl
        pbh
        pbhcompany
        annualFinancialPlanConverted
        annualFinancialPlanNumeric
        annualFinancialPlan
      }
    }
  }
`;

const CustVendDimDataGrid = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize state from URL search params
  const initialPage = parseInt(searchParams.get("page")) || 0;
  const initialPageSize = parseInt(searchParams.get("pageSize")) || 50;
  const initialSortField = searchParams.get("sortField") || "";
  const initialSortOrder = searchParams.get("sortOrder") || "";
  const initialFilterName = searchParams.get("filterName") || "";
  const initialFilterCreditLimit =
    parseInt(searchParams.get("filterCreditLimit")) || 1000000;
  const initialAdvanced = searchParams.get("advanced") === "true";

  const [paginationModel, setPaginationModel] = useState({
    page: initialPage,
    pageSize: initialPageSize,
  });
  const [sortModel, setSortModel] = useState(
    initialSortField
      ? [{ field: initialSortField, sort: initialSortOrder.toLowerCase() }]
      : []
  );
  const [filterModel, setFilterModel] = useState({
    name: initialFilterName,
    creditLimit: initialFilterCreditLimit,
    lastQuoted: "",
    lastCallTracker: "",
    lastSODate: "",
  });
  const [advanced, setAdvanced] = useState(initialAdvanced);

  const [queryVariables, setQueryVariables] = useState({
    skip: initialPage * initialPageSize,
    take: initialPageSize,
    where: {},
    order: [],
  });

  // Update query variables based on state
  useEffect(() => {
    const skip = paginationModel.page * paginationModel.pageSize;
    const take = paginationModel.pageSize;

    let where = {};
    if (filterModel.name) {
      where.name = { contains: filterModel.name };
    }
    if (filterModel.creditLimit < 1000000) {
      where.creditLimit = { lte: filterModel.creditLimit };
    }
    if (advanced) {
      if (filterModel.lastQuoted) {
        where.lastQuoted = { eq: filterModel.lastQuoted };
      }
      if (filterModel.lastCallTracker) {
        where.lastCallTracker = { eq: filterModel.lastCallTracker };
      }
      if (filterModel.lastSODate) {
        where.lastSODate = { eq: filterModel.lastSODate };
      }
    }

    let order = [];
    if (sortModel.length > 0) {
      order = sortModel.map((sort) => ({
        [sort.field]: sort.sort.toUpperCase(),
      }));
    }

    setQueryVariables({ skip, take, where, order });
  }, [paginationModel, sortModel, filterModel, advanced]);

  // Update URL search params without causing infinite loop
  useEffect(() => {
    const params = {};
    if (paginationModel.page !== 0) params.page = paginationModel.page;
    if (paginationModel.pageSize !== 50)
      params.pageSize = paginationModel.pageSize;
    if (sortModel.length > 0) {
      params.sortField = sortModel[0].field;
      params.sortOrder = sortModel[0].sort.toUpperCase();
    }
    if (filterModel.name) params.filterName = filterModel.name;
    if (filterModel.creditLimit < 1000000)
      params.filterCreditLimit = filterModel.creditLimit;
    if (advanced) params.advanced = true;

    // Create a URLSearchParams object from current searchParams
    const currentParams = new URLSearchParams(searchParams);
    const newParams = new URLSearchParams(params);

    // Compare existing searchParams with newParams
    let needUpdate = false;

    // Check if any new param differs from the current one
    newParams.forEach((value, key) => {
      if (currentParams.get(key) !== value) {
        needUpdate = true;
      }
    });

    // Check for any params that exist in currentParams but not in newParams
    currentParams.forEach((value, key) => {
      if (!newParams.has(key)) {
        needUpdate = true;
      }
    });

    if (needUpdate) {
      setSearchParams(params);
    }
  }, [
    paginationModel,
    sortModel,
    filterModel,
    advanced,
    searchParams,
    setSearchParams,
  ]);

  const { data, loading, error } = useQuery(GET_CUST_VEND_DIM, {
    variables: queryVariables,
  });

  const rows = useMemo(() => {
    if (!data?.custVendDim?.items) return [];
    return data.custVendDim.items.map((item) => ({
      id: item.acctNo,
      ...item,
    }));
  }, [data]);

  const rowCount = data?.custVendDim?.totalCount || 0;

  const handleSortModelChange = useCallback((newSortModel) => {
    setSortModel(newSortModel);
  }, []);

  const handlePaginationModelChange = useCallback((newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  }, []);

  const handleFilterChange = useCallback((newFilters) => {
    setFilterModel(newFilters);
  }, []);

  const handleResetFilters = useCallback(() => {
    setFilterModel({
      name: "",
      creditLimit: 1000000,
      lastQuoted: "",
      lastCallTracker: "",
      lastSODate: "",
    });
    setSortModel([]);
    setPaginationModel({
      page: 0,
      pageSize: 50,
    });
    setAdvanced(false);
  }, []);

  const columns = [
    { field: "acctNo", headerName: "Account Number", width: 150 },
    { field: "priority", headerName: "Priority", width: 120 },
    { field: "name", headerName: "Name", width: 200 },
    {
      field: "lastQuoted",
      headerName: "Last Quoted",
      width: 150,
      valueFormatter: (params) => formatDate(params?.value),
    },
    {
      field: "lastCallTracker",
      headerName: "Last Call Tracker",
      width: 150,
      valueFormatter: (params) => formatDate(params?.value),
    },
    {
      field: "lastSODate",
      headerName: "Last SO Date",
      width: 150,
      valueFormatter: (params) => formatDate(params?.value),
    },
    {
      field: "creditLimit",
      headerName: "Credit Limit",
      width: 150,
      valueFormatter: (params) => formatCurrency(params?.value),
    },
    { field: "creditTerms", headerName: "Credit Terms", width: 150 },
    { field: "customerKhealth", headerName: "Customer K-health", width: 150 },
    { field: "customerHealth", headerName: "Customer Health", width: 150 },
    { field: "creditInsurance", headerName: "Credit Insurance", width: 150 },
    { field: "isValid", headerName: "Is Valid", width: 120 },
    { field: "isActiveCustomer", headerName: "Is Active Customer", width: 150 },
    {
      field: "isAirframeKeyCustomer",
      headerName: "Is Airframe Key Customer",
      width: 200,
    },
    {
      field: "isEngineKeyCustomer",
      headerName: "Is Engine Key Customer",
      width: 200,
    },
    { field: "bdcrms", headerName: "BDCRMS", width: 120 },
    { field: "avl", headerName: "AVL", width: 120 },
    { field: "pbh", headerName: "PBH", width: 120 },
    { field: "pbhcompany", headerName: "PBH Company", width: 150 },
    {
      field: "annualFinancialPlanConverted",
      headerName: "Annual Financial Plan Converted",
      width: 250,
      valueFormatter: (params) => formatCurrency(params?.value),
    },
    {
      field: "annualFinancialPlanNumeric",
      headerName: "Annual Financial Plan Numeric",
      width: 250,
      valueFormatter: (params) => formatCurrency(params?.value),
    },
    { field: "annualFinancialPlan", headerName: "Annual Financial Plan", width: 200 },
  ];

  const handleRowClick = useCallback(
    (params) => {
      navigate(`/customer?acctno=${params.id}`);
    },
    [navigate]
  );

  return (
    <Paper elevation={3} className="paper">
      <Box mb={2}>
        <FilterControls
          onFilterChange={handleFilterChange}
          onReset={handleResetFilters}
          initialFilters={filterModel}
          advanced={advanced}
          setAdvanced={setAdvanced}
        />
      </Box>
      <div className="dataGridContainer">
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Typography>Loading...</Typography>
          </Box>
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            pagination
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            rowCount={rowCount}
            page={paginationModel.page}
            pageSize={paginationModel.pageSize}
            onPageChange={(newPage) =>
              setPaginationModel((prev) => ({ ...prev, page: newPage }))
            }
            onPageSizeChange={(newPageSize) =>
              setPaginationModel((prev) => ({ ...prev, pageSize: newPageSize }))
            }
            onSortModelChange={handleSortModelChange}
            sortModel={sortModel}
            loading={loading}
            className="dataGrid"
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            onRowClick={handleRowClick}
          />
        )}
      </div>
      {error && (
        <div className="error">
          Error: {error.message}
        </div>
      )}
    </Paper>
  );
};

export default CustVendDimDataGrid;
