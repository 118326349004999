import React, { useEffect } from 'react';
import { Box, Grid, Typography, Paper } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_CUSTOMER_PROFILE } from '../../queries';
import './CustomerProfile.css';

const InfoItem = ({ label, value }) => (
  <Box className="info-item">
    <Typography className="info-label">{label}:</Typography>
    <Typography className="info-value">{value}</Typography>
  </Box>
);

const Section = ({ title, children }) => (
  <Paper className="section">
    <Typography className="section-title">{title}</Typography>
    <Box className="section-content">
      {children}
    </Box>
  </Paper>
);

const formatCurrency = (amount) => {
  if (amount === null || amount === undefined) return 'N/A';
  if (amount === 0) return '$0';
  const absAmount = Math.abs(amount).toLocaleString();
  return amount < 0 ? `-$${absAmount}` : `$${absAmount}`;
};


const CustomerProfile = ({ selectedCustomer }) => {
  const { data, loading, error } = useQuery(GET_CUSTOMER_PROFILE, {
    variables: { acctNo: selectedCustomer?.acctNo || '' },
    skip: !selectedCustomer, // Use 'skip' instead of 'pause'
  });

  // Handle errors (optional: you can customize error handling further)
  useEffect(() => {
    if (error) {
      console.error('Error fetching customer profile:', error);
    }
  }, [error]);

  if (loading) return <Typography sx={{ p: 3 }}>Loading...</Typography>;
  if (error) return <Typography sx={{ p: 3 }}>Error: {error.message}</Typography>;
  if (!data) return <Typography sx={{ p: 3 }}>No data available</Typography>;

  const profile = data.customerProfileByAccountNumber;

  return (
    <Box className="customer-profile">
      <Grid container spacing={2} className="grid-container">
        {/* Top row */}
        <Grid item xs={12} lg={6} className="grid-item">
          {/* Status Section */}
          <Section title="Status">
            <Grid container spacing={2} className="nested-grid-container">
              <Grid item xs={12} sm={6} className="nested-grid-item">
                <InfoItem label="Key Customer" value={profile.keyCustomerYN} />
                <InfoItem label="Customer Priority" value={profile.customerPriority} />
                <InfoItem label="Customer Health" value={profile.customerHealth} />
                <InfoItem label="Engine Customer" value={profile.engineCustomerYN} />
                <InfoItem label="BD CRMS" value={profile.bdCrms} />
                <InfoItem label="Account Approved" value={profile.accountApprovedYN} />
                <InfoItem label="Account Type" value={profile.accountType} />
              </Grid>
              <Grid item xs={12} sm={6} className="nested-grid-item">
                <InfoItem label="Region" value={profile.region} />
                <InfoItem label="Country" value={profile.country} />
                <InfoItem label="State" value={profile.state} />
                <InfoItem label="Priority" value={profile.priority} />
                <InfoItem label="Account Rating" value={profile.accountRating} />
                <InfoItem label="On Hold" value={profile.onHold} />
              </Grid>
            </Grid>
          </Section>
        </Grid>

        <Grid item xs={12} lg={6} className="grid-item">
          <Grid container spacing={2}>
            {/* Financials Section */}
            <Grid item xs={12} sm={6} className="grid-item">
              <Section title="Financials">
                <Grid container spacing={2} className="nested-grid-container">
                  <Grid item xs={12} className="nested-grid-item">
                    <InfoItem label="Credit Terms" value={profile.creditTerms} />
                    <InfoItem label="Credit Limit" value={formatCurrency(profile.creditLimit)} />
                    <InfoItem label="Open Balance" value={formatCurrency(profile.openBalance)} />
                    <InfoItem label="Credit Remaining" value={formatCurrency(profile.creditRemaining)} />
                    <InfoItem label="Annual Financial Plan" value={formatCurrency(profile.annualFinancialPlan)} />
                    <InfoItem label="YTD Invoiced" value={formatCurrency(profile.ytdInvoiced)} />
                    <InfoItem label="YTD Sales Orders" value={formatCurrency(profile.ytdSalesOrders)} />
                    <InfoItem label="Current Backlog" value={formatCurrency(profile.currentBacklog)} />
                  </Grid>
                </Grid>
              </Section>
            </Grid>

            {/* Fleet Section */}
            <Grid item xs={12} sm={6} className="grid-item">
              <Section title="Fleet">
                <Grid container spacing={2} className="nested-grid-container">
                  <Grid item xs={12} className="nested-grid-item">
                    <InfoItem label="AVL" value={profile.avl_YN} />
                    <InfoItem label="PBH" value={profile.pbh_YN} />
                    <InfoItem label="PBH Company" value={profile.pbhCompany} />
                    <InfoItem label="Fleet Size" value={profile.fleetSize || 'N/A'} />
                    <Typography className="section-subtitle">Airframes</Typography>
                    <Typography className="section-text">{profile.airframeList || 'N/A'}</Typography>
                  </Grid>
                </Grid>
              </Section>
            </Grid>
          </Grid>
        </Grid>

        {/* Bottom row - Memo/Alert Notes Section */}
        <Grid item xs={12} className="grid-item">
          <Section title="Memo/Alert Notes">
            <Typography className="alert-status">Alert: {profile.alertYN}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box className="alert-box">
                  <Typography className="box-title">Alert</Typography>
                  <Typography className="box-text">
                    {profile.alertText || 'No alerts'}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className="memo-box">
                  <Typography className="box-title">Memo</Typography>
                  <Typography className="box-text">
                    {profile.memoText || 'No memo'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Section>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerProfile;
