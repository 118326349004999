import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, CircularProgress } from '@mui/material';
import useAuthenticatedData from '../../hooks/useAuthenticatedData';
import { useNavigate } from 'react-router-dom';

const GoalPeriodsGrid = ({goalId}) => {
    const { data: periods, isLoading, error } = useAuthenticatedData(`https://localhost:5001/mos/goals/${goalId}/periods`);
    const navigate = useNavigate();

    console.log("Periods data:", periods); // Debug log

    const handleRowClick = (params) => {
        navigate(`/goal/${goalId}/period/${params.id}`);
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };

    const columns = [
        
        { field: 'periodName', headerName: 'Period Name', width: 200 },
        { 
            field: 'startDate', 
            headerName: 'Start Date', 
            width: 150
        },
        { 
            field: 'endDate', 
            headerName: 'End Date', 
            width: 150
        },
        { 
            field: 'value', 
            headerName: 'Value', 
            width: 150
            
        },
    ];

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 2, height: 400 }}>
                <Typography color="error">Error loading periods: {error.message}</Typography>
            </Box>
        );
    }

    if (!periods || periods.length === 0) {
        return (
            <Box sx={{ p: 2, height: 400 }}>
                <Typography>No period data available.</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ height: 400, width: '100%', overflow: 'hidden' }}>
            <DataGrid
                rows={periods}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                onRowClick={handleRowClick}
                disableSelectionOnClick
            />
        </Box>
    );
};

export default GoalPeriodsGrid;