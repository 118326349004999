// src/app/services/Apollo/index.js

import { ApolloClient, InMemoryCache, createHttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import  msalInstance  from "../../msalInstance";
import { loginRequest, loginSilentRequest } from "../../authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

import config from '../../config';
 
const httpLink = createHttpLink({
  uri: `${config.apiBaseUrl}/graphql`,
});

// 2. Create an authentication link to inject the token into headers
const authLink = setContext(async (_, { headers }) => {
  const accounts = msalInstance.getAllAccounts();

  if (accounts.length === 0) {
    // No accounts found, initiate login
    await msalInstance.loginRedirect(loginRequest);
    return { headers };
  }

  const account = accounts[0];

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginSilentRequest,
      account,
    });
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${response.accessToken}`,
      },
    };
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // Silent acquisition failed, initiate interactive login
      await msalInstance.acquireTokenRedirect(loginRequest);
    }
    console.error("Token acquisition failed:", error);
    return { headers };
  }
});

// 3. Optional: Error handling link to catch GraphQL and network errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      // Handle specific GraphQL errors if needed
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    // Handle network errors if needed
  }
});

// 4. Initialize Apollo Client with the links and cache
const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
