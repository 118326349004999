import React, { useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery, gql } from '@apollo/client';
import { Paper, Box, Typography } from '@mui/material';
import './AssignedAccountsGrid.css';

const GET_ASSIGNED_ACCOUNTS = gql`
  query AssignedAccounts($ccode: String!) {
    assignedAccounts(ccode: $ccode) {
      acctNo
      name
      customerHealth
      customerKhealth
      specialAccount
      specialAccountLabel
      airframeCustomerClassification
      isAirframeKeyCustomer
      engineCustomerClassification
      isEngineKeyCustomer
      isOperator
      annualFinancialPlanNumeric
      accountType
      accountRatingDesc
      marketSegment
    }
  }
`;

const formatCurrency = (value) => {
  if (value === null || value === undefined) return '';
  return `$${(value / 1000000).toFixed(2)}M`;
};

const AssignedAccountsGrid = ({ ccode }) => {
  const { data, loading, error } = useQuery(GET_ASSIGNED_ACCOUNTS, {
    variables: { ccode },
  });

  const rows = useMemo(() => {
    if (!data?.assignedAccounts) return [];
    return data.assignedAccounts.map((item, index) => ({
      id: index,
      ...item,
    }));
  }, [data]);

  // name, health, annual plan, YTD revenue, open orders - $ if there's room
  // * southwest error
  const columns = [
   
    { field: 'name', headerName: 'Name', width: 250 },
    { field: 'customerHealth', headerName: 'Health', width: 100 },
    { field: 'customerKhealth', headerName: 'K-Health', width: 100 },
    /*
    { field: 'specialAccount', headerName: 'Special', width: 100 },
    { field: 'specialAccountLabel', headerName: 'Special Label', width: 120 },
    { field: 'airframeCustomerClassification', headerName: 'Airframe Class', width: 120 },
    { field: 'isAirframeKeyCustomer', headerName: 'Airframe Key', width: 120 },
    { field: 'engineCustomerClassification', headerName: 'Engine Class', width: 120 },
    { field: 'isEngineKeyCustomer', headerName: 'Engine Key', width: 120 },
    { field: 'isOperator', headerName: 'Operator', width: 100 },
    { 
      field: 'annualFinancialPlanNumeric', 
      headerName: 'Annual Plan', 
      width: 120,
      valueFormatter: (params) => formatCurrency(params?.value),
    },
    */
  //  { field: 'accountType', headerName: 'Account Type', width: 120 },
    //{ field: 'accountRatingDesc', headerName: 'Rating', width: 100 },

    { field: 'marketSegment', headerName: 'Market Segment', width: 300 },
  ];

  return (
    <Paper elevation={3} className="assigned-accounts-paper">
      <div className="assigned-accounts-grid-container">
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography>Loading...</Typography>
          </Box>
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            className="assigned-accounts-grid"
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
          />
        )}
      </div>
      {error && (
        <div className="assigned-accounts-error">
          Error: {error.message}
        </div>
      )}
    </Paper>
  );
};

export default AssignedAccountsGrid;