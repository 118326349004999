import { useState, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';

const useAuthenticatedDownload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { instance, accounts } = useMsal();

  const downloadFile = useCallback(async (url, filename) => {
    if (!accounts.length) {
      setError(new Error("No account available"));
      return;
    }
   
    setIsLoading(true);
    setError(null);

    try {
      await instance.initialize();

      const accessToken = await instance.acquireTokenSilent({
        scopes: ['api://82ba1170-03da-46d4-be4b-dcc3fb4473f1/access_as_user'],
        account: accounts[0],
      });

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken.accessToken}` },
        responseType: 'blob', // This is important for file downloads
      });

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      
      // Create a link element, set the download attribute with the filename
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      
      // Append to the document, click and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Free up memory
      window.URL.revokeObjectURL(link.href);

    } catch (error) {
      console.error('Error downloading file:', error);
      setError(error);
      if (error.name === "InteractionRequiredAuthError") {
        instance.acquireTokenRedirect({
          scopes: ['api://82ba1170-03da-46d4-be4b-dcc3fb4473f1/access_as_user'],
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [instance, accounts]);

  return { downloadFile, isLoading, error };
};

export default useAuthenticatedDownload;