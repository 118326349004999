import React, { useState, useEffect } from 'react';
import useAuthenticatedData from '../../hooks/useAuthenticatedData';
import useAuthenticatedPost from '../../hooks/useAuthenticatedPost';
import { Alert } from '@mui/material';
import {
    Box, Typography, CircularProgress, Paper, IconButton, Button,
    Dialog, DialogTitle, DialogContent, DialogActions, TextField, Snackbar
} from '@mui/material';
import { Star, StarBorder } from '@mui/icons-material';

import ManageKpiAssignmentsDialog from '../ManageKpiAssignmentsDialog';
import KpiAssignmentGrid from './KpiAssignmentGrid';
import MetricsGrid from '../dashboard/MetricsGridxx';
import GoalsGrid from '../overview/GoalsGrid';

const KpiDetail = ({ kpiId }) => {
    const {
        data: kpiData,
        isLoading: isLoadingDetails,
        error: detailsError
    } = useAuthenticatedData(`https://localhost:5001/mos/kpi-detail/${kpiId}`);

    const {
        data: favoriteStatus,
        isLoading: isLoadingFavorite,
        error: favoriteError
    } = useAuthenticatedData(`https://localhost:5001/mos/kpis/${kpiId}/favorite/1`);

    const { post, isLoading: isPostingFavorite, error: postError } = useAuthenticatedPost();

    const [isFavorite, setIsFavorite] = useState(false);
    const [openAssignDialog, setOpenAssignDialog] = useState(false);
    const [assignDialogMode, setAssignDialogMode] = useState('assign');
    const [assignToUser, setAssignToUser] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');

    useEffect(() => {
        if (favoriteStatus !== undefined && favoriteStatus !== null) {
            setIsFavorite(favoriteStatus);
        }
    }, [favoriteStatus]);

    const handleFavoriteToggle = async () => {
        try {
            const response = await post(
                `https://localhost:5001/mos/kpis/${kpiId}/favorite/1`,
                !isFavorite
            );
            if (response !== undefined) {
                setIsFavorite(response);
                setSnackbarMessage(response ? 'KPI added to favorites' : 'KPI removed from favorites');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error toggling favorite status:', error);
            setSnackbarMessage('Error updating favorite status. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleAssignKpi = async () => {
        try {
            await post(`https://localhost:5001/kpis/assign-kpi`, { kpiId, userId: assignToUser });
            setOpenAssignDialog(false);
            setAssignToUser('');
        } catch (error) {
            console.error('Error assigning KPI:', error);
            setSnackbarMessage('Error assigning KPI. Please try again.');
            setSnackbarOpen(true);
        }
    };

    if (isLoadingDetails || isLoadingFavorite) return <CircularProgress />;
    if (detailsError) return <Typography color="error">Error loading KPI details: {detailsError.message}</Typography>;
    if (favoriteError) return <Typography color="error">Error loading favorite status: {favoriteError.message}</Typography>;
    if (!kpiData) return <Typography>KPI not found</Typography>;

    const { kpidto: kpiDTO, metricDTO, goalDTO, kpiValueDTO } = kpiData;

    return (
        <Box sx={{ p: 3 }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h4">{kpiDTO.name}</Typography>
                <IconButton onClick={handleFavoriteToggle} disabled={isPostingFavorite}>
                    {isFavorite ? <Star color="primary" /> : <StarBorder />}
                </IconButton>
            </Box>
            
            <Typography variant="body1">Type: {kpiDTO.type}</Typography>
            <Typography variant="body1">Description: {kpiDTO.description}</Typography>

          
            <Paper sx={{ mt: 4, p: 2 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Assignments</Typography>

                    <Button variant="contained" onClick={() => {
                        setAssignDialogMode('assign');
                        setOpenAssignDialog(true);
                    }}>
                        Assign This KPI to Users
                    </Button>

                    <Button variant="outlined" onClick={() => {
                        setAssignDialogMode('edit');
                        setOpenAssignDialog(true);
                    }}>
                        Edit KPI Assignments
                    </Button>

                    <ManageKpiAssignmentsDialog
                        open={openAssignDialog}
                        onClose={() => setOpenAssignDialog(false)}
                        kpiId={kpiId}
                        mode={assignDialogMode}
                    />
                </Box>
        {/*         <KpiAssignmentGrid assignments={kpiAssignments} /> */}
            </Paper>

            <Paper sx={{ mt: 4, p: 2 }}>
                <Typography variant="h6">Metrics</Typography>
            {/*    <MetricsGrid metrics={metrics} /> */}
            </Paper>           
        
        

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default KpiDetail;