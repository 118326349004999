import React from 'react';
import { Box, Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PropTypes from 'prop-types';
 import ListAltIcon from '@mui/icons-material/ListAlt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';


const CompactCalendarLegend = ({ activities }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#1E1E1E',
        border: '1px solid #424242',
        borderRadius: '4px',
        padding: '2px 8px',
        display: 'inline-flex',
        alignItems: 'center',
        gap: '8px',
        height: '24px',
      }}
    >
      <Typography
        variant="caption"
        sx={{
          color: '#e3e3e3',
          fontSize: '0.8rem',
          fontWeight: 'bold',
          borderRight: '1px solid #424242',
          paddingRight: '8px',
          lineHeight: 1,
        }}
      >
        LEGEND
      </Typography>
      
      <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        {activities.map((activity, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <Box
              sx={{
                width: '12px',
                height: '12px',
                backgroundColor: activity.color,
                borderRadius: '1px',
              }}
            />
            <Typography
              sx={{
                color: '#b3b3b3',
                fontSize: '0.8rem',
                lineHeight: 1,
              }}
            >
              {activity.type}
            </Typography>
          </Box>
        ))}

        {/* Spacer after CALLS */}
        <Box sx={{ width: '8px' }} />

        {/* Icons section */}
        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <DescriptionIcon sx={{ color: 'white', fontSize: '14px' }} />
            <Typography sx={{ color: '#b3b3b3', fontSize: '0.8rem' }}>
              DOCS
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <ListAltIcon sx={{ color: 'white', fontSize: '14px' }} />
            <Typography sx={{ color: '#b3b3b3', fontSize: '0.8rem' }}>
              LINES
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <AttachMoneyIcon sx={{ color: 'white', fontSize: '14px' }} />
            <Typography sx={{ color: '#b3b3b3', fontSize: '0.8rem' }}>
              DOLLARS
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

CompactCalendarLegend.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CompactCalendarLegend;