// Performance.jsx
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { Box, CircularProgress, Alert, Typography } from '@mui/material';
import MonthlyRevenueChart from './MonthlyRevenueChart';
import { GET_CUSTOMER_PERFORMANCE } from '../../queries';

const Performance = ({ selectedCustomer }) => {
  const [timeFrame, setTimeFrame] = useState('thisYear');
  const [mode, setMode] = useState('annual');

  const getDateRange = useCallback((timeFrame) => {
    const currentDate = new Date();
    let startDate, endDate;

    switch (timeFrame) {
      case 'thisYear':
        startDate = new Date(currentDate.getFullYear(), 0, 1);
        break;
      case 'past2Years':
        startDate = new Date(currentDate.getFullYear() - 1, 0, 1);
        break;
      case 'past3Years':
        startDate = new Date(currentDate.getFullYear() - 2, 0, 1);
        break;
      default:
        startDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1);
    }
    endDate = currentDate;

    return {
      start: startDate.toISOString(),
      end: endDate.toISOString(),
    };
  }, []);

  const { start, end } = useMemo(() => getDateRange(timeFrame), [timeFrame, getDateRange]);

  const {
    data: performanceData,
    loading: performanceLoading,
    error: performanceError,
    refetch: refetchPerformance,
  } = useQuery(GET_CUSTOMER_PERFORMANCE, {
    variables: {
      acctNo: selectedCustomer?.acctNo || '',
      start,
      end,
      mode,
    },
    skip: !selectedCustomer, // Apollo uses 'skip' instead of 'pause'
    fetchPolicy: 'network-only', // Ensures fresh data on refetch
  });



  // Optional: Refetch when timeFrame or mode changes
  useEffect(() => {
    if (selectedCustomer) {
      refetchPerformance({
        acctNo: selectedCustomer.acctNo,
        start,
        end,
        mode,
      });
    }
  }, [selectedCustomer, timeFrame, mode, start, end, refetchPerformance]);

  // Handle errors (optional: you can customize error handling further)
  useEffect(() => {
    if (performanceError) {
      console.error('Error fetching customer performance:', performanceError);
    }
  }, [performanceError]);

  const chartData = useMemo(() => {
    if (!performanceData?.customerPerformance?.[0]?.months) return [];

    return performanceData.customerPerformance[0].months
      .sort((a, b) => new Date(a.date) - new Date(b.date)) // Sort by date
      .map((monthData) => {
        // Extract breakdown categories safely
        const monthlyContributionCategories =
          monthData.breakdown?.find((b) => b.dimension === 'Monthly Contribution')?.categories || [];

        // Extract accumulated revenue and this month's revenue
        const accumulatedRevenue =
          monthlyContributionCategories.find((c) => c.name === 'Accumulated Revenue')?.value || 0;
        const thisMonthRevenue =
          monthlyContributionCategories.find((c) => c.name === 'This Month')?.value || 0;

        // Extract past year's performance from additionalMetrics
        const pastYearPerformance =
          monthData.additionalMetrics?.find((m) => m.name === "Last Year's Revenue")?.value || 0;

        const [year, month, day] = monthData.date.split('-').map(Number);
        const date = new Date(year, month - 1, day); // month is 0-indexed in JS Date

        return {
          name: date.toLocaleString('default', { month: 'short' }),
          date: date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' }),
          fullDate: date,
          accumulatedRevenue,
          thisMonthRevenue,
          openSales: monthData.openSales || 0,
          annualPlan: monthData.goal || 0,
          pastYearPerformance,
        };
      });
  }, [performanceData]);

  // Optional: Handle loading and error states for better UX
  if (!selectedCustomer) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#121212"
        padding={2}
      >
        <Typography variant="h6" color="white">
          Please select a customer to view performance data.
        </Typography>
      </Box>
    );
  }

  if (performanceLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#121212"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (performanceError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#121212"
        padding={2}
      >
        <Alert severity="error">{performanceError.message}</Alert>
      </Box>
    );
  }

  if (!performanceData?.customerPerformance?.[0]?.months?.length) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#121212"
        padding={2}
      >
        <Typography variant="h6" color="white">
          No performance data available for the selected customer.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        overflow: 'hidden',
        bgcolor: '#121212',
      }}
    >
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <MonthlyRevenueChart
          chartData={chartData}
          timeFrame={timeFrame}
          setTimeFrame={setTimeFrame}
          mode={mode}
          setMode={setMode}
          annualGoal={performanceData.customerPerformance[0].annualGoal || 0}
          accountNumber={selectedCustomer.acctNo || 'N/A'}
        />
      </Box>
    </Box>
  );
};

export default Performance;
