import React from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import useAuthenticatedData from '../../hooks/useAuthenticatedData'; // Adjust the import path as needed
import config from '../../config';
import './VersionHistory.css';

const columns = [
  { field: 'version', headerName: 'Version', width: 100 },
  { field: 'description', headerName: 'Description', flex: 1, minWidth: 200 },
  {
    field: 'releaseDate',
    headerName: 'Release Date',
    width: 150,
    renderCell: (params) => {
      if (!params.value) return '';
      const date = new Date(params.value);
      if (isNaN(date.getTime())) return 'Invalid date';
      return date.toLocaleDateString('en-US', { 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric' 
      });
    }  
  },
];

const VersionHistory = () => {
  const { data: versionData, isLoading, error } = useAuthenticatedData(`${config.apiBaseUrl}/backend/nexus-versions`);

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error.message}</Typography>;
  if (!versionData || versionData.length === 0) return <Typography>No version data available.</Typography>;

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={versionData}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        disableSelectionOnClick
        autoHeight
      />
    </Box>
  );
};

export default VersionHistory;