import React from 'react';
import { Paper, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
 
 

const Displays = () => {
    const navigate = useNavigate();

    const handleAddGoal = () => {
        navigate('/goals/new');
    };

    return (


        <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4, position: 'relative' }}>
        <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
 
        </Box>
        <Typography variant="h1" component="h1" gutterBottom align="left">
          DISPLAYS
        </Typography>

        <Typography variant="body1" align="left">
            A list of available displays - like the sales monitor - and links to them.
      </Typography>
        
      </Paper>


    );

};

export default Displays;