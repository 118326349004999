import React, { useMemo, useState, useEffect } from 'react';
import { Typography, Paper, Box, TextField } from '@mui/material';
import { useQuery } from '@apollo/client';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbar } from '@mui/x-data-grid';
import { GET_CALL_TRACKERS_FOR_DAY_FOR_CUSTOMER } from '../../queries';
import { enterpriseGridOverrideTheme } from '../../themes/EnterpriseGridTheme';

const CustomerCallDetail = ({ businessKey, date }) => {
    const [selectedCall, setSelectedCall] = useState(null);

    const { data, loading, error } = useQuery(GET_CALL_TRACKERS_FOR_DAY_FOR_CUSTOMER, {
        variables: { acctNo: businessKey, date: date },
    });

    const rows = useMemo(() => {
        if (!data?.customerCallTrackersForDay) return [];
        return data.customerCallTrackersForDay.map((item, index) => ({
            id: index,
            ...item,
        }));
    }, [data]);

    useEffect(() => {
        if (rows.length > 0 && !selectedCall) {
            setSelectedCall(rows[0]);
        }
    }, [rows]);

    const generateExportFileName = () => {
        const formattedDate = date.toISOString().split('T')[0];
        const dateParts = formattedDate.split('-');
        return `${businessKey}-${dateParts[1]}-${dateParts[2]}-${dateParts[0]}-call-trackers-for-day`;
    };

    const columns = useMemo(
        () => [
            { field: 'docNo', headerName: 'Pentagon Doc#', width: 120 },
            { field: 'callTypeDesc', headerName: 'Type', width: 80 },
            { field: 'callCategoryDesc', headerName: 'Category', width: 120 },
            { field: 'acctNo', headerName: 'Account', width: 120 },
            {
                field: 'enteredDate',
                headerName: 'Entered On',
                width: 180,
                valueFormatter: (params) => {
                    if (!params) return '';
                    try {
                        const date = new Date(params);
                        return date.toLocaleString(); // This will show both date and time
                        // Alternative formatting options:
                        // return date.toLocaleDateString(); // For date only
                        // return new Intl.DateTimeFormat('en-US', { 
                        //   dateStyle: 'short', 
                        //   timeStyle: 'short' 
                        // }).format(date); // For customized format
                    } catch (e) {
                        return params.value;
                    }
                }
            },
        ],
        []
    );

    return (
        <div>
            <Typography sx={{ p: '8px' }} variant="h6">
                Call Trackers for Day
            </Typography>

            <Box sx={{ display: 'flex', gap: 2 }}>
                {/* Master Grid Section */}
                <Paper elevation={3} sx={{ flex: 1, height: 500, p: 2 }}>
                    <Box sx={{ height: '100%' }}>
                        {loading ? (
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                <Typography>Loading...</Typography>
                            </Box>
                        ) : error ? (
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                <Typography color="error">Error: {error.message}</Typography>
                            </Box>
                        ) : (
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5, 10, 25]}
                                disableSelectionOnClick={false}
                                onRowClick={(params) => setSelectedCall(params.row)}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 5, page: 0 },
                                    },
                                    selection: {
                                        selectedRowIds: rows.length > 0 ? [0] : []
                                    }
                                }}
                                autoHeight={false}
                                headerHeight={40}
                                sx={{
                                    ...enterpriseGridOverrideTheme,
                                    '& .MuiDataGrid-row': {
                                        cursor: 'pointer',
                                        '&.Mui-selected': {
                                            backgroundColor: 'primary.main',
                                            '&:hover': {
                                                backgroundColor: 'primary.dark',
                                            },
                                        },
                                    },
                                }}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                        csvOptions: { fileName: generateExportFileName() },
                                        printOptions: { fileName: generateExportFileName() }
                                    }
                                }}
                            />
                        )}
                    </Box>
                </Paper>

                {/* Detail Section */}
                <Paper elevation={3} sx={{ flex: 1, height: 500, paddingBottom: 5 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Message
                    </Typography>
                    <TextField
                        multiline
                        fullWidth
                        minRows={10}
                        maxRows={20}
                        value={selectedCall?.callMessage || 'Select a call to view details'}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                        sx={{
                            height: 'calc(100% - 40px)',
                            border: '1px solid #e0e0e0',
                          
                            
                            '& .MuiOutlinedInput-root': {
                                height: '100%',
                             
                                '& textarea': {
                                    height: '100% !important',
                               
                                },
                            },
                        }}
                    />
                </Paper>
            </Box>
        </div>
    );
};

export default CustomerCallDetail;