import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import SelectableMetricGrid from './SelectableMetricGrid';
import SelectableCompanyStack from '../SelectableCompanyStack';

const MetricShuttle = ({ 
  assignedMetrics, 
  unassignedMetrics, 
  onMetricsChange,
  onCompanyLevelSelect, // New prop for handling company level selection
  selectedCompanyLevel  // New prop for the currently selected company level
}) => {
  const [selectedUnassigned, setSelectedUnassigned] = useState([]);
  const [selectedAssigned, setSelectedAssigned] = useState([]);

  const handleSelectionChangeUnassigned = (newSelection) => {
    setSelectedUnassigned(newSelection);
  };

  const handleSelectionChangeAssigned = (newSelection) => {
    setSelectedAssigned(newSelection);
  };

  const handleAssign = () => {
    const newAssigned = assignedMetrics.concat(
      unassignedMetrics.filter((metric) => selectedUnassigned.includes(metric.id))
    );
    const newUnassigned = unassignedMetrics.filter(
      (metric) => !selectedUnassigned.includes(metric.id)
    );

    setSelectedUnassigned([]);
    onMetricsChange(newAssigned, newUnassigned);
  };

  const handleUnassign = () => {
    const newUnassigned = unassignedMetrics.concat(
      assignedMetrics.filter((metric) => selectedAssigned.includes(metric.id))
    );
    const newAssigned = assignedMetrics.filter(
      (metric) => !selectedAssigned.includes(metric.id)
    );

    setSelectedAssigned([]);
    onMetricsChange(newAssigned, newUnassigned);
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Metrics
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Box width="33%">
          <Typography variant="h6">Unassigned</Typography>
          <SelectableMetricGrid
            data={unassignedMetrics}
            onSelectionChange={handleSelectionChangeUnassigned}
            selectedMetrics={selectedUnassigned}
          />
          <Button
            variant="contained"
            onClick={handleAssign}
            disabled={selectedUnassigned.length === 0}
            sx={{ mt: 2 }}
          >
            Add Metric &gt;
          </Button>
        </Box>
        <Box width="33%">
          <Typography variant="h6">Assigned</Typography>
          <SelectableMetricGrid
            data={assignedMetrics}
            onSelectionChange={handleSelectionChangeAssigned}
            selectedMetrics={selectedAssigned}
          />
          <Button
            variant="contained"
            onClick={handleUnassign}
            disabled={selectedAssigned.length === 0}
            sx={{ mt: 2 }}
          >
            &lt; Remove Metric
          </Button>
        </Box>
        <Box width="33%">
          <Typography variant="h6">Filter by Company Level</Typography>
          <SelectableCompanyStack 
            onItemSelect={onCompanyLevelSelect}
            selectedItem={selectedCompanyLevel}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MetricShuttle;