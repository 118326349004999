// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import msalInstance from './msalInstance'; // Import the shared MSAL instance
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import { ApolloProvider } from "@apollo/client";
import client from "./services/Apollo"; // Adjust the path if necessary
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";


const root = ReactDOM.createRoot(document.getElementById('root'));

loadDevMessages();    // Loads development-specific error messages
loadErrorMessages();  // Loads all error messages

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </ApolloProvider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
