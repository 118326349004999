import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const formatNumber = (n) => {
    const num = typeof n === 'number' ? n : Number(n);
    if (isNaN(num)) return 'Invalid Number';
    if (num === 0 || Math.abs(num) < 0.01) return '0';
    if (Number.isInteger(num))
        return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(num);
    let [intPart, decPart] = num.toFixed(2).split('.');
    intPart = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(intPart);
    decPart = decPart.replace(/0+$/, '');
    return decPart === '' ? intPart : `${intPart === '0' ? '' : intPart}.${decPart}`;
};

const formatCurrency = (value) => {
    const num = typeof value === 'number' ? value : Number(value);
    if (isNaN(num)) return 'Invalid Currency';
    const absValue = Math.abs(num);
    const sign = num < 0 ? '-' : '';
    if (absValue >= 1000) {
        const inThousands = absValue / 1000;
        const formattedValue = formatNumber(inThousands);
        return `${sign}${formattedValue}K`;
    } else {
        const formattedValue = formatNumber(Math.floor(absValue));
        return `${sign}${formattedValue}`;
    }
};

const ActivityBand = ({ color, type, docCount, lineCount, total, minimal = false }) => {
    const tooltipText = minimal
        ? `${type}: ${formatNumber(lineCount)} lines`
        : `${type}: ${formatNumber(docCount)} docs,  ${formatNumber(lineCount)} lines, ${formatCurrency(total)} total`;


    return (
        <Box sx={{ height: '20px', position: 'relative' }}>
            <Tooltip title={tooltipText}>
                <Box
                    sx={{
                        backgroundColor: color,
                        color: '#FFFFFF',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between', // Distribute space between watermark and data points
                        fontSize: '1rem',
                        padding: '0 8px', // Increased padding for better spacing
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        borderRadius: '2px', // Slight border radius for aesthetics
                    }}
                >
                    {/* Watermark on the Left */}
                    <Typography
                        variant="caption"
                        sx={{
                            color: 'rgba(255, 255, 255, 0.6)', // Slightly more visible watermark
                            pointerEvents: 'none',
                            userSelect: 'none',
                            fontSize: '.6rem',
                        }}
                    >
                        {type}
                    </Typography>

                    {/* Data Points on the Right */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>


                        {!minimal && (
                            <>
                                < DescriptionIcon sx={{ fontSize: '1rem', mr: 0 }} />
                                <Typography variant="caption" sx={{ mr: 1 }}>
                                    {formatNumber(docCount)}
                                </Typography>
                            </>
                        )}


                        {/* Line Count (always displayed) */}
                        <ListAltIcon sx={{ fontSize: '1rem', mr: 0 }} />
                        <Typography variant="caption" sx={{ mr: minimal ? 0 : 1 }}>
                            {formatNumber(lineCount)}
                        </Typography>

                        {!minimal && (
                            <>
                                <AttachMoneyIcon sx={{ fontSize: '1rem', mr: 0 }} />
                                <Typography variant="caption">{formatCurrency(total)}</Typography>
                            </>
                        )}
                    </Box>
                </Box>
            </Tooltip>
        </Box>
    );
};

export default ActivityBand;
