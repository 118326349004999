// src/components/ios/repairs/RepairsErrors.js
import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const RepairsErrors = () => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>Errors</Typography>
      <Typography variant="body2">Improper dates & things</Typography>
      <Link href="#" underline="hover">link out to detail page</Link>
    </Box>
  );
};

export default RepairsErrors;