// DayCell.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import ActivityBand from './ActivityBand';

const DayCell = ({ date, data, activities, selectedDate,  isToday, businessKey, onActivityClick }) => {
  const dateString = date.toISOString().split('T')[0];
  const dayData = data;//data.find((d) => d.date === dateString);

  const isBusinessDay = date.getDay() !== 0 && date.getDay() !== 6;
  const isSelected = selectedDate && selectedDate.toDateString() === date.toDateString();

  if(!data)
    return null;

  return (
    <Box
      
      sx={{
        border: isToday ? '2px solid #ceaf07' : '1px solid #424242',
        padding: 1,
        backgroundColor: isBusinessDay ? '#1E1E1E' : '#2C2C2C',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
      //  width: '100%',
        boxSizing: 'border-box',
        transition: 'background-color 0.3s, border 0.3s',
     //   outline: isToday ? '2px solid rgba(255, 215, 0, 0.6)' : 'none', // Subtle yellow outline
        borderRadius: isToday ? '2px' : '0px', // Rounded corners for outline
        '&:hover': {
          backgroundColor: '#333333',
        },
      }}
    >
      {/* Horizontal Layout: Date Number and Activities Side by Side */}
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        {/* Date Number */}
        <Typography variant="subtitle2" sx={{ fontSize: '1em', color: '#FFFFFF', marginRight: 0.5,  minWidth: '40px',  }}>
          {date.getDate()}
        </Typography>

        {/* Activities */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.2,
            flexGrow: 1,
            overflow: 'hidden',
          }}
        >
          {activities.map((activity) => {
            if (!dayData) return null;
            const { docCount, lineCount, total } = activity.dataFields;
            const currentDocCount = dayData[docCount] || 0;
            const currentLineCount = dayData[lineCount] || 0;
            const currentTotal = dayData[total] || 0;

            // Determine if activity has any data to display
            const hasData =
              (docCount && currentDocCount > 0) ||
              (lineCount && currentLineCount > 0) ||
              (total && currentTotal > 0);

            if (!hasData) return null;

            return (
              <ActivityBand
                 
                key={activity.type}
                businessKey={businessKey}
                color={activity.color}
                type={activity.type}
                docCount={currentDocCount}
                lineCount={currentLineCount}
                total={currentTotal}
                minimal={activity.minimal}
                date={date}
                onClick={() =>
                  onActivityClick(activity.type, date, businessKey)                  
                }
            
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

DayCell.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  data: PropTypes.array.isRequired,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      dataFields: PropTypes.shape({
        docCount: PropTypes.string,
        lineCount: PropTypes.string,
        total: PropTypes.string,
      }),
      minimal: PropTypes.bool,
    })
  ).isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  onDateSelect: PropTypes.func.isRequired,
  isToday: PropTypes.bool, // Added isToday prop
};

DayCell.defaultProps = {
  selectedDate: null,
  isToday: false, // Default value for isToday
};

export default DayCell;
