import React from 'react';
import {
    Box,
    Typography,
    Paper,
} from '@mui/material';
import { 
    DataGrid, 
    GridToolbar,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarQuickFilter,
    GridToolbarExport
} from '@mui/x-data-grid';


// Custom toolbar component without export button
    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ backgroundColor: 'black', p: 1 }}>
                <GridToolbarQuickFilter 
                    sx={{ 
                        color: 'white', 
                        '& .MuiInputBase-root': { 
                            color: 'white',
                            '& .MuiInputBase-input::placeholder': {
                                color: 'rgba(255, 255, 255, 0.7)',
                            },
                        },
                        '& .MuiIconButton-root': {
                            color: 'white',
                        },
                    }} 
                />
               
            </GridToolbarContainer>
        );
    }

const SelectableUserGrid = ({ users, onSelectionChange, onRowClick }) => {
    const columns = [
      
        { field: 'name', headerName: 'Name', width: 130 },
     
    ];

    if (!users || users.length === 0) {
        return null; // or return some placeholder content
    }

    return (
        <Paper elevation={3} sx={{ p: 2 }}>
            <Box sx={{ mb: 2 }}>
                <Typography variant="h6" gutterBottom>
                    User List
                </Typography>
            </Box>
            <DataGrid
                rows={users}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 20]}
                autoHeight
                checkboxSelection
                disableRowSelectionOnClick
                onRowClick={onRowClick}
                slots={{
                    toolbar: CustomToolbar,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: 'black',
                        color: 'white',
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        letterSpacing: '0.1em',
                        fontFamily: "'Roboto Condensed', sans-serif",
                    },
                    '& .MuiDataGrid-row:nth-of-type(even)': {
                        backgroundColor: '#262626',
                    },
                    '& .MuiDataGrid-row:nth-of-type(odd)': {
                        backgroundColor: '#333333',
                    },
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                        '& button': {
                            color: 'white',
                        },
                        '& .MuiInputBase-root': {
                            color: 'white',
                        },
                    },
                }}
            />
        </Paper>
    );
};

export default SelectableUserGrid;