// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000', // Bright yellow, picked from the logo
        },
        secondary: {
            main: '#FDB813', // Strong black for secondary accents
        },
        background: {
            // default: '#333333', // Dark grey for the main background
            default: '#000000',
            //  paper: '#424242', // Slightly lighter grey for elements like cards and paper components
            paper: '#000000',
        },
        text: {
            primary: '#FFFFFF', // White text for primary content on dark backgrounds
            secondary: '#BDBDBD', // Light grey for secondary content and less emphasis
        },
    },
    typography: {
        fontFamily: 'Roboto, Arial, sans-serif',
        h1: {
            fontSize: '2.25rem', // Slightly larger for more impact
            fontWeight: 700,
            color: '#FDB813', // Use primary color for headings to maintain visual interest
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 500,
            color: '#FFFFFF', // Secondary headings in white for contrast
        },
        h4: {
            fontFamily: "'Roboto Condensed', sans-serif",
            fontWeight: 500,
            textTransform: 'uppercase',

        },
        body1: {
            fontSize: '1rem',
            fontWeight: 400,
            color: '#FFFFFF', // Main text in white for readability
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 400,
            color: '#BDBDBD', // Secondary text in light grey
        },

    },
    spacing: 8, // Maintain default spacing unit
    shape: {
        borderRadius: 4, // Reduced corner rounding for a sharper look
    },
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF', // Set icon color to white
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    backgroundColor: '#FDB813', // Solid yellow background
                    color: '#000000', // Black text
                    border: '2px solid #000000', // Black outline
                    // make strong
                    fontWeight: 700,
                    '&:hover': {
                        backgroundColor: '#FFD700', // Slightly lighter yellow on hover
                    },
                    '& .MuiSvgIcon-root': {
                        color: '#000000', // Black icon
                    },
                },
            },
        },
    },
});

export default theme;
