import React, { useState } from 'react';
import {
    TextField, Button, Box, Typography, Paper, Container, Grid, CircularProgress, Snackbar, Alert
} from '@mui/material';
import useAuthenticatedData from '../hooks/useAuthenticatedData';
import useAuthenticatedPost from '../hooks/useAuthenticatedPost';
import UsersGrid from './user/UsersGrid';
import { useNavigate } from 'react-router-dom';
import config from '../config';

const AddGroupPage = () => {
    const navigate = useNavigate();
    const [groupName, setGroupName] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
   // const { data: users, isLoading, error } = useAuthenticatedData('https://localhost:5001/admin/users');
    const { data: users, isLoading, error } = useAuthenticatedData(`${config.apiBaseUrl}/admin/users`);


    const { post, isLoading: isSubmitting, error: submitError } = useAuthenticatedPost();
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const handleGroupNameChange = (event) => {
        setGroupName(event.target.value);
    }

    const handleUserSelection = (selectionModel) => {
        console.log('Selected users in parent:', selectionModel);
        setSelectedUsers(selectionModel);
    }

    const handleSubmit = async () => {
        try {
            const payload = {
                name: groupName,
                userIds: selectedUsers
            };

            console.log('Submitting payload:', payload);

            const response = await post('https://localhost:5001/admin/groups', payload);

            console.log('Group created:', response);
            setSnackbar({ open: true, message: 'Group created successfully!', severity: 'success' });
            setTimeout(() => navigate('/groups'), 2000);
        } catch (error) {
            console.error('Error creating group:', error);
            setSnackbar({ open: true, message: 'Error creating group. Please try again.', severity: 'error' });
        }
    };

    const handleCancel = () => {
        navigate('/groups');
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Container maxWidth="lg">
            <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Add New Group
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Group Name"
                            type="text"
                            fullWidth
                            value={groupName}
                            onChange={handleGroupNameChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Select Users for Group
                        </Typography>
                        {isLoading ? (
                            <Box display="flex" justifyContent="center" my={4}>
                                <CircularProgress />
                            </Box>
                        ) : error ? (
                            <Typography color="error">Error loading users: {error.message}</Typography>
                        ) : users && users.length > 0 ? (
                            <UsersGrid users={users} onSelectionChange={handleUserSelection} />
                        ) : (
                            <Typography>No users available.</Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button onClick={handleCancel} sx={{ mr: 2 }} disabled={isSubmitting}>
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                variant="contained"
                                color="primary"
                                disabled={isLoading || !users || isSubmitting || !groupName.trim()}
                            >
                                {isSubmitting ? <CircularProgress size={24} /> : 'Create Group'}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default AddGroupPage;