import React from 'react';
import { Paper, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustVendDimDataGrid from './CustVendDimDataGrid';
 
 

const Customers = () => {
    const navigate = useNavigate();
 

    return (


        <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4, position: 'relative' }}>
 
        <Typography variant="h1" component="h1" gutterBottom align="left">
          CUSTOMERS
        </Typography>

        <CustVendDimDataGrid />
        
      </Paper>


    );

};

export default Customers;