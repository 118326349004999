// src/components/EmailDetails.js
import React, { useContext } from 'react';
import { Box, Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import { FrontDeskContext } from './FrontDeskContext';
import './EmailDetails.css';

const EmailDetails = () => {
  const { selectedEmail } = useContext(FrontDeskContext);

  if (!selectedEmail) {
    return <Typography className="no-email-selected">Select an email to view details</Typography>;
  }

  return (
    <Box className="email-details">
      <Typography variant="h6" gutterBottom className="email-details-title">Email Details</Typography>
      <Paper className="email-info">
        <Typography><strong>From:</strong> {selectedEmail.fromName} ({selectedEmail.fromAddress})</Typography>
        <Typography><strong>Subject:</strong> {selectedEmail.subject}</Typography>
        <Typography><strong>Received:</strong> {new Date(selectedEmail.receivedDateTime).toLocaleString()}</Typography>
        <Typography><strong>Status:</strong> {selectedEmail.status}</Typography>
      </Paper>
      <Paper className="ai-summary">
        <Typography variant="subtitle1">AI Summary:</Typography>
        <Typography variant="body2">{selectedEmail.aiSummary}</Typography>
      </Paper>
      <Paper className="parts-table">
        <Typography variant="subtitle1">Parts:</Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Part Number</TableCell>
              <TableCell>Condition</TableCell>
              <TableCell>Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedEmail.parts.map((part, index) => (
              <TableRow key={index}>
                <TableCell>{part.partnumber}</TableCell>
                <TableCell>{part.condition || 'N/A'}</TableCell>
                <TableCell>{part.quantity || 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Box className="action-buttons">
        <Button variant="contained" color="primary" href={selectedEmail.webLink} target="_blank">
          View in Outlook
        </Button>
      </Box>
    </Box>
  );
};

export default EmailDetails;