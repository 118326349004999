import React, { useState, useEffect } from 'react';
import useAuthenticatedData from '../hooks/useAuthenticatedData';
import useAuthenticatedPost from '../hooks/useAuthenticatedPost';
import { Alert } from '@mui/material';
import {
    Box, Typography, CircularProgress, Paper, IconButton, Button,
    Snackbar, Link
} from '@mui/material';
import { Star, StarBorder } from '@mui/icons-material';

const ReportDetail = ({ reportId }) => {
    const {
        data: reportData,
        isLoading: isLoadingDetails,
        error: detailsError
    } = useAuthenticatedData(`https://localhost:5001/bi/reports/${reportId}`);

    const {
        data: favoriteStatus,
        isLoading: isLoadingFavorite,
        error: favoriteError
    } = useAuthenticatedData(`https://localhost:5001/bi/reports/${reportId}/favorite/1`);

    const { post, isLoading: isPostingFavorite, error: postError } = useAuthenticatedPost();

    const [isFavorite, setIsFavorite] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');

    useEffect(() => {
        if (favoriteStatus !== undefined && favoriteStatus !== null) {
            setIsFavorite(favoriteStatus);
        }
    }, [favoriteStatus]);

    const handleFavoriteToggle = async () => {
        try {
            const response = await post(
                `https://localhost:5001/bi/reports/${reportId}/favorite/1`,
                !isFavorite
            );
            if (response !== undefined) {
                setIsFavorite(response);
                setSnackbarMessage(response ? 'Report added to favorites' : 'Report removed from favorites');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error toggling favorite status:', error);
            setSnackbarMessage('Error updating favorite status. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    if (isLoadingDetails || isLoadingFavorite) return <CircularProgress />;
    if (detailsError) return <Typography color="error">Error loading report details: {detailsError.message}</Typography>;
    if (favoriteError) return <Typography color="error">Error loading favorite status: {favoriteError.message}</Typography>;
    if (!reportData) return <Typography>Report not found</Typography>;

    const { 
        id, reportKey, name, description, link, version, isValid, 
        validFrom, validTo, reportType 
    } = reportData;

    return (
        <Box sx={{ p: 3 }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h4">{name}</Typography>
                <IconButton onClick={handleFavoriteToggle} disabled={isPostingFavorite}>
                    {isFavorite ? <Star color="primary" /> : <StarBorder />}
                </IconButton>
            </Box>
            
            <Paper sx={{ mt: 4, p: 2 }}>
                <Typography variant="body1"><strong>Report Key:</strong> {reportKey}</Typography>
                <Typography variant="body1"><strong>Description:</strong> {description}</Typography>
                <Typography variant="body1"><strong>Version:</strong> {version}</Typography>
                <Typography variant="body1"><strong>Report Type:</strong> {reportType}</Typography>
                <Typography variant="body1">
                    <strong>Link:</strong> {link ? (
                        <Link href={link} target="_blank" rel="noopener noreferrer">
                            Open Report
                        </Link>
                    ) : 'N/A'}
                </Typography>
                <Typography variant="body1"><strong>Is Valid:</strong> {isValid ? 'Yes' : 'No'}</Typography>
                <Typography variant="body1"><strong>Valid From:</strong> {new Date(validFrom).toLocaleDateString()}</Typography>
                <Typography variant="body1"><strong>Valid To:</strong> {validTo ? new Date(validTo).toLocaleDateString() : 'N/A'}</Typography>
            </Paper>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ReportDetail;