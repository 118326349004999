import React, { useState, useEffect } from 'react';
import useAuthenticatedData from '../../hooks/useAuthenticatedData';
import useAuthenticatedPost from '../../hooks/useAuthenticatedPost';
import { Alert } from '@mui/material';
import {
    Box, Typography, CircularProgress, Paper, IconButton, Button,
    Dialog, DialogTitle, DialogContent, DialogActions, TextField, Snackbar
} from '@mui/material';
import { Star, StarBorder } from '@mui/icons-material';

import ManageGoalAssignmentsDialog from './ManageGoalAssignmentsDialog';

import GoalAssignmentGrid from './GoalAssignmentGrid';

import GoalKPIsGrid from './GoalKPIsGrid';
import GoalPeriodsGrid from './GoalPeriodsGrid';



const GoalDetail = ({ goalId }) => {

    const {
        data: goalData,
        isLoading: isLoadingDetails,
        error: detailsError
    } = useAuthenticatedData(`https://localhost:5001/mos/goals/${goalId}`);

    const {
        data: favoriteStatus,
        isLoading: isLoadingFavorite,
        error: favoriteError

    } = useAuthenticatedData(`https://localhost:5001/mos/goals/${goalId}/favorite/1`);

    const { post, isLoading: isPostingFavorite, error: postError } = useAuthenticatedPost();

    const [isFavorite, setIsFavorite] = React.useState(false);
    const [openAssignDialog, setOpenAssignDialog] = useState(false);
    const [assignDialogMode, setAssignDialogMode] = useState('assign');

    const [assignToUser, setAssignToUser] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');

    useEffect(() => {
        if (favoriteStatus !== undefined && favoriteStatus !== null) {
            setIsFavorite(favoriteStatus);
        }
    }, [favoriteStatus]);

    // todo: the body of the post request should be true or false, depending on the favorite status
    const handleFavoriteToggle = async () => {
        try {
            const response = await post(
                `https://localhost:5001/mos/goals/${goalId}/favorite/1`,
                !isFavorite
            );
            if (response !== undefined) {
                setIsFavorite(response);
                setSnackbarMessage(response ? 'Goal added to favorites' : 'Goal removed from favorites');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);

            }
        } catch (error) {
            console.error('Error toggling favorite status:', error);
            setSnackbarMessage('Error updating favorite status. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleAssignGoal = async () => {
        try {
            await post(`https://localhost:5001/goals/assign-goal`, { goalId, userId: assignToUser });
            setOpenAssignDialog(false);
            setAssignToUser('');
        } catch (error) {
            console.error('Error assigning goal:', error);
            setSnackbarMessage('Error assigning goal. Please try again.');
            setSnackbarOpen(true);
        }
    };



    if (isLoadingDetails || isLoadingFavorite) return <CircularProgress />;
    if (detailsError) return <Typography color="error">Error loading goal details: {detailsError.message}</Typography>;
    if (favoriteError) return <Typography color="error">Error loading favorite status: {favoriteError.message}</Typography>;
    if (!goalData) return <Typography>Goal not found</Typography>;

    const { goalDTO, goalValueDTO, goalAssignments, kpIs } = goalData;

    return (
        <Box sx={{ p: 3 }}>

            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h4">{goalDTO.metricName}</Typography>
                <IconButton onClick={handleFavoriteToggle} disabled={isPostingFavorite}>
                    {isFavorite ? <Star color="primary" /> : <StarBorder />}
                </IconButton>
            </Box>

            <Typography variant="h4" gutterBottom>{goalDTO.name}</Typography>


            <Typography variant="body1">Type: {goalDTO.metricType}</Typography>
            <Typography variant="body1">Description: {goalDTO.metricDescription}</Typography>

            <Paper sx={{ mt: 4, p: 2 }}>
                <Typography variant="h6">Goal Periods</Typography>
                <GoalPeriodsGrid goalId={goalDTO.id} />
            </Paper>

            <Paper sx={{ mt: 4, p: 2 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Assignments</Typography>

                    <Button variant="contained" onClick={() => {
                        setAssignDialogMode('assign');
                        setOpenAssignDialog(true);
                    }}>
                        Assign This Goal to Users
                    </Button>

                    <Button variant="outlined" onClick={() => {
                        setAssignDialogMode('edit');
                        setOpenAssignDialog(true);
                    }}>
                        Edit Goal Assignments
                    </Button>

                    <ManageGoalAssignmentsDialog
                        open={openAssignDialog}
                        onClose={() => setOpenAssignDialog(false)}
                        goalId={goalId}
                        mode={assignDialogMode}
                    />
                </Box>
                <GoalAssignmentGrid assignments={goalAssignments} />
            </Paper>

            <Paper sx={{ mt: 4, p: 2 }}>
                <Typography variant="h6">KPIs</Typography>
                <GoalKPIsGrid kpis={kpIs} />
            </Paper>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default GoalDetail;