import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import useAuthenticatedData from '../hooks/useAuthenticatedData';

const Roles = () => {
    const navigate = useNavigate();

    const { data: roles, isLoading, error } = useAuthenticatedData('https://localhost:5001/admin/roles');

    const columns = [
        { field: 'name', headerName: 'Name', width: 500 },
 
    ];

    const handleRowClick = (params) => {
        navigate(`/roles/${params.id}`);
    }

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography color="error">Error: {error.message}</Typography>
            </Box>
        );
    }

    if (!roles || roles.length === 0) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography>No role data available.</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Roles
            </Typography>
            <DataGrid
                rows={roles}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                disableSelectionOnClick
                onRowClick={handleRowClick}
            />
        </Box>
    );
};

export default Roles;