import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, Box, Typography, CircularProgress } from '@mui/material';
import useAuthenticatedData from '../hooks/useAuthenticatedData'; // Adjust the import path as needed
import ReportsGrid from './ReportsGrid';

const Reports = () => {
    return (
        <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
            <Typography variant="h1" component="h1" gutterBottom align="left">
                REPORTS
            </Typography>
            <Box sx={{ mt: 2 }}>
                <Typography variant="body1" paragraph align="left">
                    Here the user will see a table of reports they have access to or should be focused upon. 
                </Typography>
                <Typography variant="body1" paragraph align="left">
                    Clicking a report opens it in a new tab.
                </Typography>
            </Box>
        </Paper>
    )
};
 //   <ReportsGrid />

export default Reports;