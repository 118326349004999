// src/components/ios/purchasing/Productivity.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const PurchasingProductivity = () => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>Productivity</Typography>
      <Typography variant="body2">Expected vs actual take home</Typography>
      <Typography variant="body2">Average leadtime</Typography>
      <Typography variant="body2">Average time to sell purchased material</Typography>
      {/* Add more productivity metrics here */}
    </Box>
  );
};

export default PurchasingProductivity;