import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Box, Collapse, ListItemButton, Link } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BarChartIcon from '@mui/icons-material/BarChart';
import SpeedIcon from '@mui/icons-material/Speed';
import FlagIcon from '@mui/icons-material/Flag';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TvIcon from '@mui/icons-material/Tv';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Gavel from '@mui/icons-material/Gavel';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Flight, FlightTakeoff } from '@mui/icons-material';
import Refresh from '@mui/icons-material/Refresh';
import Public from '@mui/icons-material/Public';
import Build from '@mui/icons-material/Build';
import LocalShipping from '@mui/icons-material/LocalShipping';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { useMsal } from "@azure/msal-react";
import BugReportIcon from '@mui/icons-material/BugReport';

import AttachMoney from '@mui/icons-material/AttachMoney';

const drawerWidth = 240;

export default function AdminMenu({ open, toggleDrawer }) {
  const location = useLocation();
  const { instance, accounts } = useMsal();
  const [username, setUsername] = useState("");
  const [organizationOpen, setOrganizationOpen] = useState(false);
  const [operationsOpen, setOperationsOpen] = useState(false);


  useEffect(() => {
    if (accounts.length > 0) {
      const name = accounts[0].name || accounts[0].username || "User";
      setUsername(name);
    }
  }, [accounts]);


  const handleLogout = async () => {
    const logoutRequest = {
      postLogoutRedirectUri: window.location.origin, // or your specific logout URL
    };

    try {
      await instance.logoutRedirect(logoutRequest);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  useEffect(() => {
    // Check if the current path is under the Organization submenu
    if (location.pathname.startsWith('/users') ||
      location.pathname.startsWith('/groups') ||
      location.pathname.startsWith('/roles')) {
      setOrganizationOpen(true);

    }
    else if (location.pathname.startsWith('/sales') ||
      location.pathname.startsWith('/ios/repairs-overview') ||
      location.pathname.startsWith('/ios/purchasing-overview') ||
      location.pathname.startsWith('/inventory') ||
      location.pathname.startsWith('/customer') ||
      location.pathname.startsWith('/warehouse') ||
      location.pathname.startsWith('/warranties') ||
      location.pathname.startsWith('/rma')


    ) {
      setOperationsOpen(true);
    }
  }, [location]);

  const handleOrganizationClick = () => {
    setOrganizationOpen(!organizationOpen);
  };

  const handleOperationsClick = () => {
    setOperationsOpen(!operationsOpen);
  };

  const NestedMenuItem = ({ href, icon, text }) => {
    let isActive = location.pathname === href;

    if (text == "Customers" && location.pathname.startsWith('/customer')) {
      isActive = true;
    }

    if (text == "Sales" && location.pathname.startsWith('/sales')) {
      isActive = true;
    }

    return (
      <ListItemButton
        component="a"
        href={href}
        sx={{
          pl: 4, // Add left padding for indentation
          bgcolor: 'rgba(0, 0, 0, 0.03)', // Slight background color difference
          '&:hover': {
            bgcolor: 'rgba(0, 0, 0, 0.08)',
          },

        }}
      >
        <ListItemIcon sx={{ color: isActive ? '#FDB813' : 'inherit', minWidth: 40 }}>
          {React.cloneElement(icon, { style: { color: isActive ? '#FDB813' : 'inherit' } })}
        </ListItemIcon>
        <ListItemText
          primary={text}
          primaryTypographyProps={{
            fontSize: '0.875rem', // Slightly smaller font for nested items
            fontWeight: isActive ? 'bold' : 'normal',
            color: isActive ? '#FDB813' : 'inherit',
          }}
        />
      </ListItemButton>
    );
  };

  const MenuItem = ({ href, icon, text }) => {
    const isActive = location.pathname === href;
    return (
      <ListItem
        button
        component="a"
        href={href}
      >
        <ListItemIcon sx={{ color: isActive ? '#FDB813' : 'inherit' }}>
          {React.cloneElement(icon, { style: { color: isActive ? '#FDB813' : 'inherit' } })}
        </ListItemIcon>
        <ListItemText
          primary={text}
          sx={{
            '& .MuiListItemText-primary': {
              color: isActive ? '#FDB813' : 'inherit'
            }
          }}
        />
      </ListItem>
    );
  };

  /*
         <Typography variant="h6" noWrap sx={{ ml: 2 }}>
              ITS Control Tower
            </Typography>
  */

  return (
    <>
      <AppBar sx={{
        borderBottom: '1px solid #e0e0e0', // subtle gray border
      }} position="fixed" style={{ zIndex: 1201 }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
            >
              <MenuIcon fontSize="large" />
              <Box sx={{ flexShrink: 0 }}>
                <img src="/nexus_logo_blue.png" alt="ITS Logo" style={{ paddingLeft: 30, height: 30 }} />
              </Box>
            </IconButton>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography
              variant="body2"
              color="gray"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              Logged in as:
              <Typography variant="body2"
                color="gray"
                sx={{ fontWeight: 'bold', ml: 1 }}>{username}</Typography>
              <Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleLogout();
                }}
                sx={{
                  color: 'darkgray',
                  ml: 1,
                  textDecoration: 'none',
                  cursor: 'pointer'
                }}
              >
                [log out]
              </Link>
            </Typography>
            <Box sx={{ flexShrink: 0 }}>
              <img src="/its_logo2.png" alt="ITS Logo" style={{ paddingLeft: 30, height: 40 }} />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        style={{ width: drawerWidth, flexShrink: 0 }}
        sx={{ '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box', borderRight: '1px solid #e0e0e0' } }}
      >
        <Toolbar />
        <List>
          <MenuItem href="/" icon={<HomeIcon />} text="Dashboard" />
          {/*  
          <MenuItem href="/overview" icon={<VisibilityIcon />} text="Overview" />
          <MenuItem href="/metrics" icon={<BarChartIcon />} text="Metrics" />
          <MenuItem href="/kpis" icon={<SpeedIcon />} text="KPIs" />
          <MenuItem href="/goals" icon={<FlagIcon />} text="Goals" />
          <MenuItem href="/frontdesk" icon={<FilterAltIcon />} text="Sales Funnel" />

          <MenuItem href="/reports" icon={<AssessmentIcon />} text="Reports" />
          <MenuItem href="/displays" icon={<TvIcon />} text="Displays" />
*/}
          <ListItem button onClick={handleOperationsClick}>
            <ListItemIcon><Public /></ListItemIcon>
            <ListItemText primary="Operations" />
            {operationsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={operationsOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NestedMenuItem href="/customers" icon={<GroupIcon />} text="Customers" />

              <NestedMenuItem href="/sales-overview" icon={<AttachMoney />} text="Sales" />
              {/*  
              <NestedMenuItem href="/ios/repairs-overview" icon={<Build />} text="Repairs" />
              <NestedMenuItem href="/ios/purchasing-overview" icon={<LocalShipping />} text="Purchasing" />

              <NestedMenuItem href="/inventory" icon={<FlightTakeoff />} text="Inventory" />
              <NestedMenuItem href="/warehouse" icon={<WarehouseIcon />} text="Warehouse" />
              <NestedMenuItem href="/warranties" icon={<Gavel />} text="Warranties" />
              <NestedMenuItem href="/rmas" icon={<Refresh />} text="RMA" />
              */}

            </List>
          </Collapse>








          {/*  
          <ListItem button onClick={handleOrganizationClick}>
            <ListItemIcon><GroupIcon /></ListItemIcon>
            <ListItemText primary="Organization" />
            {organizationOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={organizationOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NestedMenuItem href="/users" icon={<PersonIcon />} text="Users" />
              <NestedMenuItem href="/groups" icon={<PeopleIcon />} text="Groups" />
              <NestedMenuItem href="/roles" icon={<VpnKeyIcon />} text="Roles" />
            </List>
          </Collapse>









*/}
          <MenuItem  href="http://projects2.its.local/projects/nexus/work_packages" icon={<BugReportIcon />} text="Report Bug" />
          <MenuItem href="/about" icon={<InfoIcon />} text="About" />

          {/*  
          <MenuItem href="/settings" icon={<SettingsIcon />} text="Settings" />
          */}
        </List>
      </Drawer>
    </>
  );
}