import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button, Grid, Paper } from '@mui/material';
import useAuthenticatedData from '../../hooks/useAuthenticatedData';
// import nav
 import { useNavigate } from 'react-router-dom';

// Assume these components are implemented and imported
 
import MetricGrid from '../metric/MetricGrid';
import MetricShuttle from '../metric/MetricShuttle';


const UserDetail = () => {
  const { userId } = useParams();
  const navigate = useNavigate();

  
  function editButtonClicked() {
    // navigate to edit page
    navigate(`/users/${userId}/edit`);
  }
  

  const { data: user, isLoading: userLoading, error: userError } = useAuthenticatedData(`https://localhost:5001/admin/users/${userId}`);
  const { data: metrics, isLoading: metricsLoading, error: metricsError } = useAuthenticatedData(`https://localhost:5001/admin/users/${userId}/metrics`);
  const { data: kpis, isLoading: kpisLoading, error: kpisError } = useAuthenticatedData(`https://localhost:5001/admin/users/${userId}/kpis`);
  const { data: groups, isLoading: groupsLoading, error: groupsError } = useAuthenticatedData(`https://localhost:5001/admin/users/${userId}/groups`);
  const { data: roles, isLoading: rolesLoading, error: rolesError } = useAuthenticatedData(`https://localhost:5001/admin/users/${userId}/roles`);
 //         <MetricsGrid assignedMetrics={metrics} unassignedMetrics={availableMetrics}  />

  if (userLoading || metricsLoading || kpisLoading || groupsLoading || rolesLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (userError || metricsError || kpisError || groupsError || rolesError) {
    return <Typography color="error">Error loading data</Typography>;
  }

  if(!user) {
    return <Typography>No data available</Typography>;
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h1">
              Details for {user.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">{user.username}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">{user.email}</Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            <Button variant="contained" onClick={editButtonClicked} >Edit User</Button>
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} md={12}>
          <Typography variant="h6">Assigned Metrics</Typography>
          <Paper elevation={2} sx={{ height: 300, overflow: 'auto' }}>
            <MetricGrid data={metrics} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h6">Assigned KPIs</Typography>
          <Paper elevation={2} sx={{ height: 300, overflow: 'auto' }}>
           
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h6">Assigned Groups</Typography>
          <Paper elevation={2} sx={{ height: 300, overflow: 'auto' }}>
            
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h6">Assigned Roles</Typography>
          <Paper elevation={2} sx={{ height: 300, overflow: 'auto' }}>
            
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDetail;