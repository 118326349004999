import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {

    Grid,
    Paper, Box, Typography, CircularProgress
} from '@mui/material';
import useAuthenticatedData from '../../hooks/useAuthenticatedData'; // Adjust the import path as needed
import MetricGrid from './MetricGrid';
import { useNavigate } from 'react-router-dom';


const Metrics = () => {

    const navigate = useNavigate();

    const rowClick = (params) => {
        navigate(`/metrics/${params.id}`);
    }


    const { data: metrics, isLoading, error } = useAuthenticatedData('https://localhost:5001/mos/metrics');

    return (


        <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
            <Typography variant="h1" component="h1" gutterBottom align="left">
                METRICS
            </Typography>
            <Box sx={{ mt: 2 }}>
                <MetricGrid data={metrics} handleRowClick={rowClick} />
            </Box>
        </Paper>

    )

    /*
    
        <Box>
            <Typography variant="h4" gutterBottom>Metrics</Typography>
            <MetricGrid data={metrics} handleRowClick={rowClick} />
        </Box>
    */
};

export default Metrics;