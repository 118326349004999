import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Typography, Box, Button, Grid, Paper } from '@mui/material';
import useAuthenticatedData from '../hooks/useAuthenticatedData';
import { useNavigate } from 'react-router-dom';
import CompanyStack from './dashboard/CompanyStack';
import { createContext, useContext, useState, useEffect } from 'react';
import LevelDetail from './overview/LevelDetail';

const Home = () => {
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState(null);


    const { data, isLoading, error } = useAuthenticatedData('https://localhost:5001/PartList/partlists');

    const handleItemSelect = (item) => {
        setSelectedItem(item);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }





    else {
        return (
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Paper elevation={3} style={{ height: '80vh', overflow: 'auto' }}>
                            <CompanyStack onItemSelect={handleItemSelect} />
                        </Paper>
                    </Grid>
                    <Grid item xs={8}>
                        <Paper elevation={3} style={{ height: '80vh', padding: '20px', overflow: 'auto' }}>
                            <LevelDetail selectedItem={selectedItem} />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

        );
    }
}

export default Home;