import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import ActivityBand from './ActivityBand';
import styles from './SummationCell.module.css';

const SummationCell = ({ summary, activities }) => {
  const isAllZero = activities.every(activity => {
    const activitySummary = summary[activity.type];
    return (
      activitySummary.docCount === 0 &&
      activitySummary.lineCount === 0 &&
      activitySummary.total === 0
    );
  });

  if (isAllZero) {
    return (
      <Box className={styles["summation-cell empty"]}>
        <Typography variant="caption">-</Typography>
      </Box>
    );
  }

  return (
    <Box className={styles["summation-cell"]}>
      <Box className={styles["summation-headers"]}>
        <Typography variant="caption" className={styles["header"]}>SUM</Typography>
        <Typography variant="caption" className={styles["header"]}>AVG</Typography>
      </Box>
      {activities
        .filter(activity => activity.monthlyAverage)
        .map(activity => {
          const activitySummary = summary[activity.type];
          if (!activitySummary) return null;

          return (
            <Box key={activity.type} className={styles["summation-row"]}>
              {(activitySummary.docCount !== 0 ||
                activitySummary.lineCount !== 0 ||
                activitySummary.total !== 0) && (
                <ActivityBand
                  type={activity.type}
                  color={activity.color}
                  docCount={activitySummary.docCount}
                  lineCount={activitySummary.lineCount}
                  total={activitySummary.total}
                  minimal={false}
                  onClick={() => {}} // Add proper onClick handler if needed
                />
              )}
              {(activitySummary.averageDocCount !== 0 ||
                activitySummary.averageLineCount !== 0 ||
                activitySummary.averageTotal !== 0) && (
                <ActivityBand
                  type={activity.type}
                  color={activity.color}
                  docCount={activitySummary.averageDocCount}
                  lineCount={activitySummary.averageLineCount}
                  total={activitySummary.averageTotal}
                  minimal={false}
                  onClick={() => {}} // Add proper onClick handler if needed
                />
              )}
            </Box>
          );
        })}
    </Box>
  );
};

SummationCell.propTypes = {
  summary: PropTypes.objectOf(
    PropTypes.shape({
      docCount: PropTypes.number,
      lineCount: PropTypes.number,
      total: PropTypes.number,
      averageDocCount: PropTypes.number,
      averageLineCount: PropTypes.number,
      averageTotal: PropTypes.number,
    })
  ).isRequired,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      dataFields: PropTypes.shape({
        docCount: PropTypes.string,
        lineCount: PropTypes.string,
        total: PropTypes.string,
      }),
      minimal: PropTypes.bool,
    })
  ).isRequired,
};

export default SummationCell;