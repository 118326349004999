// CustomerLandingPage.jsx

import React from 'react';
import { Box, Grid, Typography, Paper, Button } from '@mui/material';
import './Proficiency.css'; // Import the external CSS file
import { ArrowBack } from '@mui/icons-material';
import { ArrowForward } from '@mui/icons-material';
import { green } from '@mui/material/colors';

const InfoItem = ({ label, value, color }) => (
    <Box className="info-item">
      {color ? (
        <>
          <Typography sx={{ color }} className="info-label">{label}:</Typography>
          <Typography sx={{ color }} className="info-value">{value}</Typography>
        </>
      ) : (
        <>
          <Typography className="info-label">{label}:</Typography>
          <Typography className="info-value">{value}</Typography>
        </>
      )}
    </Box>
  );

const Section = ({ title, children, helpText}) => (
  <Paper className="section">
    <Typography className="section-title">{title}</Typography>
    <Box className="section-content">
      {children}
    </Box>
 
    <Typography className="helptext">{helpText}</Typography>
    <Typography sx={{fontSize:13, color:"#a2a245"}}  >Note: This is simulated, made up data for the purposes of our meeting this morning.</Typography>
  </Paper>
);

const Proficiency = ({ setActiveTab }) => {
  return (
    <Box className="proficiency">
      <Grid container spacing={2} className="grid-container">
        {/* Production Section */}
        <Grid item xs={12} sm={6} className="grid-item">
          <Section title="Sales vs. Sell Price" helpText="12 month average" >
            <Grid container spacing={2} className="nested-grid-container">
              <Grid item xs={12} sm={6} className="nested-grid-item">
                <InfoItem label="Percentage" value="+ 26.6% " color="#00a900" />
                <InfoItem label="Total Dollar Difference" value="+ $304,000" color="#00a900"  />                
                <InfoItem label="total # of sales (lines)" value="400"  />                
                <InfoItem label="# of graded sales (lines)" value="304"  />                
        
                <InfoItem label="Avg $ per sale" value="+ $1,000" color="#00a900"  />                

              </Grid>
              <Grid item xs={12} sm={6} className="nested-grid-item">
                {/* Additional InfoItems can be added here */}
              </Grid>
            </Grid>
          </Section>
        </Grid>

        {/* Status Section */}
        <Grid item xs={12} sm={6} className="grid-item">
          <Section title="Quote to Close" helpText="12 month average"  >
            <Grid container spacing={2} className="nested-grid-container">
              <Grid item xs={12} sm={6} className="nested-grid-item">
                <InfoItem label="Qty Percentage" value="98% (196/200)" />
                <InfoItem label="Dollars Percentage" value="97% ($5.08 M/$5.2 M)" />
              </Grid>
              <Grid item xs={12} sm={6} className="nested-grid-item">

              </Grid>
            </Grid>
          </Section>
        </Grid>

       
      </Grid>
    </Box>
  );
};

export default Proficiency;
