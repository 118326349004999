import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@mui/material';

const columns = [
  
  { field: 'name', headerName: 'Name', width: 400 },
  { field: 'goal', headerName: 'Goal', width: 130 },
  { field: 'currentValue', headerName: 'Current Value', width: 130 },
  { field: 'status', headerName: 'Status', width: 130 },
];

const MetricKPIsGrid = ({ kpis }) => {
  if (!kpis || kpis.length === 0) {
    return <Typography>No KPIs found.</Typography>;
  }

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={kpis}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        
    
      />
    </div>
  );
};

export default MetricKPIsGrid;