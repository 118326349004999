// components/CalendarMasterDetail/HorizontalCalendarScroller.jsx

import React, { useRef, useEffect } from 'react';
import MiniCalendar from './MiniCalendar';
import PropTypes from 'prop-types';
import './HorizontalCalendarScroller.module.css';

/**
 * HorizontalCalendarScroller Component
 *
 * Renders a horizontally scrollable list of MiniCalendar components, each representing a month.
 * Automatically scrolls to the selected month whenever currentYear or currentMonth changes.
 *
 * Props:
 * - startDate: Start date of the range to display (Date object).
 * - endDate: End date of the range to display (Date object).
 * - data: Array of activity data objects, each containing a 'date' field and other relevant data.
 * - onDateSelect: Function to call when a date is selected.
 * - calendarWidth: Width of each MiniCalendar (default: 200).
 * - cellSize: Size of each day cell within the MiniCalendar (default: 29).
 * - fontSize: Font size for day numbers and activity labels (default: 'body2').
 * - selectedDate: Currently selected date (Date object).
 * - currentYear: Current focused year (number).
 * - currentMonth: Current focused month (number).
 * - activities: Array of activity configuration objects for dynamic rendering.
 */

const HorizontalCalendarScroller = ({
  startDate,
  endDate,
  data = [],
  onDateSelect,
  calendarWidth = 100,
  cellSize = 15,
  fontSize = 'body2',
  selectedDate,
  currentYear,
  currentMonth,
  activities,
  
}) => {
  /**
   * Create a Map for quick lookup of activity data by date.
   * The Map key is the date string in 'YYYY-MM-DD' format.
   */
  const dataMap = useRef(new Map(data.map(item => [item.date, item]))).current;

  /**
   * Reference to the selected month element for scrolling.
   */
  const selectedMonthRef = useRef(null);

  /**
   * Flag to track the initial render to control scroll behavior.
   */
  const isFirstRender = useRef(true);

  /**
   * Generates an array of Date objects representing the first day of each month in the range.
   *
   * @param {Date} start - Start date.
   * @param {Date} end - End date.
   * @returns {Array<Date>} - Array of Date objects.
   */
  const getMonthsInRange = (start, end) => {
    let months = [];
    let date = new Date(start.getFullYear(), start.getMonth(), 1);
    while (date <= end) {
      months.push(new Date(date));
      date.setMonth(date.getMonth() + 1);
    }
    return months;
  };

  const months = getMonthsInRange(startDate, endDate);

  /**
   * useEffect to scroll the selected month into view whenever currentYear or currentMonth changes.
   * Uses smooth scrolling after the initial render.
   */
  useEffect(() => {
    if (selectedMonthRef.current) {
      selectedMonthRef.current.scrollIntoView({ 
        behavior: isFirstRender.current ? 'auto' : 'smooth', 
        inline: 'center',
        block: 'nearest' // Prevent vertical scrolling
      });
      if (isFirstRender.current) {
        isFirstRender.current = false;
      }
    }
  }, [currentYear, currentMonth]);

  return (
    <div 
      className="horizontal-calendar-container" 
      style={{ 
        overflowX: 'auto', 
        padding: '0px 0', 
        scrollbarWidth: 'thin', // For Firefox
        msOverflowStyle: 'none' // For Internet Explorer and Edge
      }}
    >
      {/* Hide scrollbar for WebKit-based browsers */}
      <style>
        {`
          .horizontal-calendar-container::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
      <div 
        className="horizontal-calendar-scroller" 
        style={{ 
          display: 'flex', 
          flexDirection: 'row',
         
        }}
      >
        {months.map((monthDate) => {
          const isSelectedMonth =
            monthDate.getFullYear() === currentYear &&
            monthDate.getMonth() === currentMonth;
          return (
            <div
              key={monthDate.toISOString()}
              ref={isSelectedMonth ? selectedMonthRef : null}
              style={{ marginRight: '10px' }}
            >
              <MiniCalendar
                monthDate={monthDate}
                dataMap={dataMap}
                selectedDate={selectedDate}
                isSelectedMonth={isSelectedMonth}
                onDaySelect={onDateSelect}
                calendarWidth={calendarWidth}
                cellSize={cellSize}
                fontSize={fontSize}
                activities={activities} // Pass activities config to MiniCalendar
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

/**
 * Define PropTypes for HorizontalCalendarScroller
 */
HorizontalCalendarScroller.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string.isRequired,
    // Include other fields as necessary based on activitiesConfig
  })).isRequired,
  onDateSelect: PropTypes.func.isRequired,
  calendarWidth: PropTypes.number,
  cellSize: PropTypes.number,
  fontSize: PropTypes.string,
  selectedDate: PropTypes.instanceOf(Date),
  currentYear: PropTypes.number.isRequired,
  currentMonth: PropTypes.number.isRequired,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      dataFields: PropTypes.shape({
        docCount: PropTypes.string,
        lineCount: PropTypes.string,
        total: PropTypes.string,
      }),
      minimal: PropTypes.bool,
    })
  ).isRequired, // Pass activities config
};

/**
 * Define Default Props for HorizontalCalendarScroller
 */
HorizontalCalendarScroller.defaultProps = {
  calendarWidth: 150,
  cellSize: 22,
  fontSize: 'body2',
  selectedDate: null,
};

export default HorizontalCalendarScroller;
