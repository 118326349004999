import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Tabs,
  Tab,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Checkbox,
  Snackbar,
  Alert
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const AssignmentDialog = ({ open, onClose, onAssign, onUnassign, type }) => {
  const [tab, setTab] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [assignedIndividuals, setAssignedIndividuals] = useState([]);
  const [assignedGroups, setAssignedGroups] = useState([]);
  const [assignedRoles, setAssignedRoles] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    setSelectedItems([]);
  }, [tab]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleAdd = async () => {
    try {
      await onAssign(selectedItems, ['individuals', 'groups', 'roles'][tab]);
      setSnackbar({ open: true, message: 'Successfully assigned', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to assign', severity: 'error' });
    }
  };

  const handleRemove = async (category) => {
    const selectedToRemove = {
      individuals: assignedIndividuals.filter(item => item.isSelected),
      groups: assignedGroups.filter(item => item.isSelected),
      roles: assignedRoles.filter(item => item.isSelected)
    }[category];

    try {
      await onUnassign(selectedToRemove, category);
      setSnackbar({ open: true, message: 'Successfully unassigned', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to unassign', severity: 'error' });
    }
  };


  const leftColumns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'Name', width: 150 },
  ];

  // Sample data (replace with your actual data)
  const leftData = {
    individuals: [{ id: 1, name: 'John Doe' }, { id: 2, name: 'Jane Smith' }],
    groups: [{ id: 1, name: 'Sales Team' }, { id: 2, name: 'Marketing Team' }],
    roles: [{ id: 1, name: 'Manager' }, { id: 2, name: 'Developer' }],
  };

  const handleCheckboxChange = (category, id) => {
    const updater = {
      individuals: setAssignedIndividuals,
      groups: setAssignedGroups,
      roles: setAssignedRoles
    }[category];

    updater(prev => prev.map(item => 
      item.id === id ? { ...item, isSelected: !item.isSelected } : item
    ));
  };

  const renderTable = (category, data, setter) => (
    <TableContainer component={Paper} style={{ marginBottom: '1rem' }}>
      <Typography variant="h6" style={{ padding: '0.5rem' }}>
        {category.charAt(0).toUpperCase() + category.slice(1)}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                onChange={(e) => {
                  setter(prev => prev.map(item => ({ ...item, isSelected: e.target.checked })));
                }}
              />
            </TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={row.isSelected || false}
                  onChange={() => handleCheckboxChange(category, row.id)}
                />
              </TableCell>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button
        variant="contained"
        onClick={() => handleRemove(category)}
        style={{ margin: '0.5rem' }}
      >
        Remove Selected
      </Button>
    </TableContainer>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle style={{ backgroundColor: '#111', padding: '1rem' }}>
        Assign {type.charAt(0).toUpperCase() + type.slice(1)}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Tabs value={tab} onChange={handleTabChange}>
              <Tab label="Individuals" />
              <Tab label="Groups" />
              <Tab label="Roles" />
            </Tabs>
            <div style={{ height: 400, width: '100%', marginTop: '1rem' }}>
              <DataGrid
                rows={leftData[['individuals', 'groups', 'roles'][tab]]}
                columns={leftColumns}
                pageSize={5}
                checkboxSelection
                onSelectionModelChange={(newSelection) => {
                  setSelectedItems(newSelection);
                }}
              />
            </div>
            <Button 
              variant="contained" 
              onClick={handleAdd} 
              style={{ marginTop: '1rem' }}
            >
              Add
            </Button>
          </Grid>
          <Grid item xs={6}>
            {renderTable('individuals', assignedIndividuals, setAssignedIndividuals)}
            {renderTable('groups', assignedGroups, setAssignedGroups)}
            {renderTable('roles', assignedRoles, setAssignedRoles)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

    </Dialog>
  );
};

export default AssignmentDialog;