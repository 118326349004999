import { useState, useEffect, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';

const useAuthenticatedData = (url) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { instance, accounts } = useMsal();

  const fetchData = useCallback(async () => {
    if (!accounts.length) {
      setIsLoading(false);
      setError(new Error("No account available"));
      return;
    }
   
    setIsLoading(true);
    setError(null);

    try {
      await instance.initialize();

      const accessToken = await instance.acquireTokenSilent({
        scopes: ['api://82ba1170-03da-46d4-be4b-dcc3fb4473f1/access_as_user'],
        account: accounts[0],
      });

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken.accessToken}` },
      });

      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
      if (error.name === "InteractionRequiredAuthError") {
        instance.acquireTokenRedirect({
          scopes: ['api://82ba1170-03da-46d4-be4b-dcc3fb4473f1/access_as_user'],
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [url, instance, accounts]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const refetch = useCallback(() => {
    fetchData();
  }, [fetchData]);

  return { data, isLoading, error, refetch };
};

export default useAuthenticatedData;