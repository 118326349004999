// src/App.js

import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useParams, Navigate } from 'react-router-dom';
import AdminMenu from './components/AdminMenu';
import Metrics from './components/metric/Metrics';
import MetricDetail from './components/metric/MetricDetail';
import Goals from './components/goal/Goals';
import GoalDetail from './components/goal/GoalDetail';
import Reports from './components/Reports';
import ReportDetail from './components/ReportDetail';
import { useMsalAuthentication, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionType, InteractionStatus } from "@azure/msal-browser";
import Overview from './components/overview/Overview';
import { loginRequest } from './authConfig';
import KPIs from './components/kpi/KPIs';
import KpiDetail from './components/kpi/KpiDetail';
import IOSDashboard from './components/dashboard/IOSDashboard';
import Users from './components/user/Users';
import UserDetail from './components/user/UserDetail';
import Groups from './components/Groups';
import Roles from './components/Roles';
import GroupDetail from './components/GroupDetail';
import RoleDetail from './components/RoleDetail';
import AddGroupPage from './components/AddGroupPage';
import AddGoalPage from './components/NewGoalPage';
import UserEdit from './components/user/UserEdit';
import MetricEdit from './components/metric/MetricEdit';
import SalesOverview from './components/sales/SalesOverview';
import RepairsOverview from './components/ios/repairs/RepairsOverview';
import PurchasingOverview from './components/ios/purchasing/PurchasingOverview';
import Repairs from './components/ios/repairs/Repairs';
import Displays from './components/displays/Displays';
import Customers from './components/customers/Customers';
import Customer from './components/customers/Customer';
import Home from './components/Home';
import Inventory from './components/inventory/Inventory';
import Warehouse from './components/warehouse/Warehouse';
import Warranties from './components/warranties/Warranties';
import RMA from './components/rma/RMA';
import About from './components/about/About';
import FrontDesk from './components/frontdesk/FrontDesk';
import Salesperson from './components/sales/salesperson/Salesperson';
import LoginModal from './components/auth/LoginModal';
import AuthenticationInProgress from './components/auth/AuthenticationInProgress';

const MetricDetailWrapper = () => {
  const { id } = useParams();
  return <MetricDetail metricId={id} />;
};

const GoalDetailWrapper = () => {
  const { id } = useParams();
  return <GoalDetail goalId={id} />;
};

const KpiDetailWrapper = () => {
  const { id } = useParams();
  return <KpiDetail kpiId={id} />;
};

const ReportDetailWrapper = () => {
  const { id } = useParams();
  return <ReportDetail reportId={id} />;
};

function App() {
  const [open, setOpen] = useState(
    localStorage.getItem('drawerOpen') === 'false' ? false : true
  );

  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { login } = useMsalAuthentication(InteractionType.Redirect, loginRequest);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Persist drawer state in localStorage
  useEffect(() => {
    localStorage.setItem('drawerOpen', open.toString());
  }, [open]);

  // Control modal visibility based on authentication and interaction status
  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  }, [isAuthenticated, inProgress]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogin = async () => {
    try {
      await login();
      setModalIsOpen(false);
    } catch (error) {
      console.error("Login failed:", error);
      // Optionally, handle login failure (e.g., show an error message)
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Show a loading indicator while authentication is in progress
  if (inProgress === InteractionStatus.Startup || inProgress === InteractionStatus.HandleRedirect) {
    return;
  }

  return (
    <Router>
      <div style={{ display: 'flex', height: '100vh', overflow: 'hidden', position: 'relative' }}>
        <AdminMenu open={open} toggleDrawer={toggleDrawer} />
        <main style={{
          flexGrow: 1,
          paddingTop: '40px',
          transition: 'margin-left 0.3s ease',
          width: '100%',
          overflowX: 'hidden',
          marginLeft: open ? 0 : -240
        }}>
          <Routes>
            <Route path="/" element={<IOSDashboard />} />

            <Route path="/metrics" element={<Metrics />} />
            <Route path="/metrics/:id" element={<MetricDetailWrapper />} />
            <Route path="/metrics/:metricId/edit" element={<MetricEdit />} />

            <Route path="/goals" element={<Goals />} />
            <Route path="/goals/new" element={<AddGoalPage />} />

            <Route path="/goal/:id" element={<GoalDetailWrapper />} />

            <Route path="/kpis" element={<KPIs />} />
            <Route path="/kpi/:id" element={<KpiDetailWrapper />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/reports/:id" element={<ReportDetailWrapper />} />
            <Route path="/overview" element={<Overview />} />
            <Route path="/home" element={<Home />} />
            <Route path="/users" element={<Users />} />

            <Route path="/users/:userId" element={<UserDetail />} />
            <Route path="/users/:userId/edit" element={<UserEdit />} />

            <Route path="/groups" element={<Groups />} />
            <Route path="/groups/new" element={<AddGroupPage />} />
            <Route path="/group/:groupId" element={<GroupDetail />} />

            <Route path="/roles" element={<Roles />} />
            <Route path="/roles/:roleId" element={<RoleDetail />} />

            <Route path="/sales-overview" element={<SalesOverview />} />
            <Route path="/sales/salesperson" element={<Salesperson />} />

            <Route path="/ios/repairs-overview" element={<RepairsOverview />} />
            <Route path="/ios/repairs" element={<Repairs />} />
            <Route path="/ios/purchasing-overview" element={<PurchasingOverview />} />

            <Route path="/displays" element={<Displays />} />

            <Route path="/inventory" element={<Inventory />} />

            <Route path="/customers" element={<Customers />} />
            <Route path="/customer" element={<Customer />} />

            <Route path="/warehouse" element={<Warehouse />} />
            <Route path="/warranties" element={<Warranties />} />
            <Route path="/rmas" element={<RMA />} />

            <Route path="/frontdesk" element={<FrontDesk />} />

            <Route path="/about" element={<About />} />

            {/* Redirect any unknown routes to home or a 404 component */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </main>
        {!isAuthenticated && (
          <LoginModal 
            isOpen={modalIsOpen} 
            onRequestClose={closeModal} 
            onLogin={handleLogin} 
          />
        )}
      </div>
    </Router>
  );
}

export default App;
