// src/components/ios/purchasing/InventoryHealth.js
import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const InventoryHealth = () => {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Inventory Health</Typography>
        <Link href="#" underline="hover">inventory</Link>
      </Box>
      {/* Add inventory health metrics and visualizations here */}
    </Box>
  );
};

export default InventoryHealth;