// src/components/ios/repairs/RepairsProductivity.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const RepairsProductivity = () => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>Productivity</Typography>
      <Typography variant="body2">Quote to close</Typography>
      <Typography variant="body2">How well things are being done</Typography>
      <Typography variant="body2">Sales vs. sell price</Typography>
      <Typography variant="body2">Policies, average joe</Typography>
    </Box>
  );
};

export default RepairsProductivity;