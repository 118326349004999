import React from 'react';
import { Paper, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import GoalsGrid from '../overview/GoalsGrid';
import AddIcon from '@mui/icons-material/Add';

const Goals = () => {
    const navigate = useNavigate();

    const handleAddGoal = () => {
        navigate('/goals/new');
    };

    return (


        <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4, position: 'relative' }}>
        <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddGoal}
          >
            Add New Goal
          </Button>
        </Box>
        <Typography variant="h1" component="h1" gutterBottom align="left">
          GOALS
        </Typography>
        <Box sx={{ mt: 2 }}>
          <GoalsGrid />
        </Box>
      </Paper>


    );

    /*
  <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h4">Goals</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddGoal}
                >
                    Add New Goal
                </Button>
            </Box>
            <GoalsGrid />
        </Box>
    */
};

export default Goals;