// src/components/ios/purchasing/PurchasingOverview.js
import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import PurchasingPerformance from './PurchasingPerformance';
import TopOpenPurchases from './TopOpenPurchases';
import InventoryHealth from './InventoryHealth';
import PurchasingProductivity from './PurchasingProductivity';
import PurchasingErrors from './PurchasingErrors';

const PurchasingOverview = () => {
    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Purchasing
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ p: 2, mb: 2, height: '100%', bgcolor: '#2a2a2a' }}>
                        <PurchasingPerformance />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ p: 2, mb: 2, height: '100%', bgcolor: '#2a2a2a' }}>
                        <TopOpenPurchases />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ p: 2, mb: 2, height: '100%', bgcolor: '#2a2a2a' }}>
                        <InventoryHealth />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ p: 2, mb: 2, height: '100%', bgcolor: '#2a2a2a' }}>
                        <PurchasingProductivity />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ p: 2, mb: 2, height: '100%', bgcolor: '#2a2a2a' }}>
                        <PurchasingErrors />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PurchasingOverview;