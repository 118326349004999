// components/CalendarMasterDetail/CalendarDetail.jsx

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Button,
  Paper,
} from '@mui/material';

//import CalendarDailyDetails from './CalendarDailyDetails';
import SummationCell from './SummationCell';
import DayCell from './DayCell';
import CalendarLegend from './CalendarLegend';
import styles from './CalendarDetail.module.css';
import { Business } from '@mui/icons-material';
import { ArrowBack } from '@mui/icons-material';
/**
 * CalendarDetail Component
 *
 * Displays a detailed calendar view for the selected month, including daily activities
 * and weekly/monthly summaries based on dynamic activity configurations.
 *
 * Props:
 * - data: Array of activity data objects for the month.
 * - currentYear: Current focused year (number).
 * - currentMonth: Current focused month (number).
 * - onMonthChange: Function to handle month navigation.
 * - renderDayDetail: Function or component to render the day detail view.
 * - selectedDate: Currently selected date (Date object).
 * - activities: Array of activity configuration objects.
 */
const CalendarDetail = ({
  data = [],
  currentYear,
  currentMonth,
  onMonthChange,
  renderDayDetail,
  selectedDate,
  activities,
  businessKey,
  activityClicked,
  detailMode,
  DetailComponent,
  setDetailMode,

}) => {
  //  const [internalSelectedDate, setInternalSelectedDate] = useState(null);

  const today = new Date();

  // Helper function to check if two dates are the same day
  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const getMonthStart = (year, month) => new Date(year, month, 1);
  const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();



  const generateWeeks = (year, month) => {
    const weeks = [];
    const monthStart = getMonthStart(year, month);
    const daysInMonth = getDaysInMonth(year, month);

    let week = [];
    for (let i = 0; i < monthStart.getDay(); i++) {
      week.push(null);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      week.push(new Date(year, month, day));
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
    }

    if (week.length > 0) {
      while (week.length < 7) {
        week.push(null);
      }
      weeks.push(week);
    }

    return weeks;
  };

  const weeks = generateWeeks(currentYear, currentMonth);

  /*
  const handleDateSelectInternal = (date) => {
    setInternalSelectedDate(date);
    if (renderDayDetail) {
      renderDayDetail({ selectedDate: date });
    }
  };
*/

  const handlePrevMonth = () => {
    let newYear = currentYear;
    let newMonth = currentMonth - 1;
    if (newMonth < 0) {
      newMonth = 11;
      newYear -= 1;
    }
    onMonthChange(newYear, newMonth);
  };

  const handleNextMonth = () => {
    let newYear = currentYear;
    let newMonth = currentMonth + 1;
    if (newMonth > 11) {
      newMonth = 0;
      newYear += 1;
    }
    onMonthChange(newYear, newMonth);
  };

  /**
   * Aggregates weekly activities based on the activities configuration.
   *
   * @param {Array} week - Array of Date objects representing a week.
   * @returns {Object} - Aggregated summary for the week.
   */
  const aggregateWeeklyActivities = (week) => {
    const summary = {};

    activities.forEach((activity) => {
      const { type, dataFields } = activity;
      const { docCount, lineCount, total } = dataFields;

      summary[type] = {
        docCount: 0,
        lineCount: 0,
        total: 0,
        averageDocCount: 0,
        averageLineCount: 0,
        averageTotal: 0,
      };

      let businessDayCount = 0;

      week.forEach((date) => {
        if (!date) return;

        const dayOfWeek = date.getDay();
        const isBusinessDay = dayOfWeek !== 0 && dayOfWeek !== 6;

        if (isBusinessDay) {
          businessDayCount += 1;
        }

        const dateString = date.toISOString().split('T')[0];
        const dayData = data.find((d) => d.date === dateString);

        if (dayData) {
          summary[type].docCount += dayData[docCount] || 0;
          summary[type].lineCount += dayData[lineCount] || 0;
          summary[type].total += dayData[total] || 0;
        }
      });

      if (businessDayCount > 0) {
        summary[type].averageDocCount = summary[type].docCount / businessDayCount;
        summary[type].averageLineCount = summary[type].lineCount / businessDayCount;
        summary[type].averageTotal = summary[type].total / businessDayCount;
      }
    });

    return summary;
  };

  /**
   * Calculates monthly summaries based on the activities configuration.
   *
   * @returns {Object} - Aggregated summary for the month.
   */
  const calculateMonthlySummaries = () => {
    const summary = {};

    activities.forEach((activity) => {
      const { type, dataFields } = activity;
      const { docCount, lineCount, total } = dataFields;

      summary[type] = {
        docCount: 0,
        lineCount: 0,
        total: 0,
        averageDocCount: 0,
        averageLineCount: 0,
        averageTotal: 0,
      };

      let businessDayCount = 0;

      data.forEach((day) => {
        const date = new Date(day.date);
        if (
          date.getMonth() === currentMonth &&
          date.getFullYear() === currentYear
        ) {
          const dayOfWeek = date.getDay();
          const isBusinessDay = dayOfWeek !== 0 && dayOfWeek !== 6;

          if (isBusinessDay) {
            businessDayCount += 1;

            summary[type].docCount += day[docCount] || 0;
            summary[type].lineCount += day[lineCount] || 0;
            summary[type].total += day[total] || 0;
          }
        }
      });

      if (businessDayCount > 0) {
        summary[type].averageDocCount = summary[type].docCount / businessDayCount;
        summary[type].averageLineCount = summary[type].lineCount / businessDayCount;
        summary[type].averageTotal = summary[type].total / businessDayCount;
      }
    });

    return summary;
  };

  const monthlySummaries = calculateMonthlySummaries();

  // Formatting functions
  const formatNumber = (n) => {
    const num = typeof n === 'number' ? n : Number(n);
    if (isNaN(num)) return 'Invalid Number';
    if (num === 0 || Math.abs(num) < 0.01) return '0';
    if (Number.isInteger(num))
      return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(num);
    let [intPart, decPart] = num.toFixed(2).split('.');
    intPart = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(intPart);
    decPart = decPart.replace(/0+$/, '');
    return decPart === '' ? intPart : `${intPart === '0' ? '' : intPart}.${decPart}`;
  };

  const formatCurrency = (value) => {
    const num = typeof value === 'number' ? value : Number(value);
    if (isNaN(num)) return 'Invalid Currency';
    const absValue = Math.abs(num);
    const sign = num < 0 ? '-' : '';
    if (absValue >= 1000) {
      const inThousands = absValue / 1000;
      const formattedValue = formatNumber(inThousands);
      return `${sign}${formattedValue}K`;
    } else {
      const formattedValue = formatNumber(Math.floor(absValue));
      return `${sign}${formattedValue}`;
    }
  };

  console.log('DetailComponent:', DetailComponent, selectedDate, businessKey);

  return (
    
      <>
      {!detailMode ? (
        <Box className={styles['calendar-view']}>


          {/* Header */}
          <Box className={styles["calendar-header"]}>
            {/* Navigation Buttons and Month Title on the Left */}
            <Box className={styles["calendar-header-left"]}>
              <Box className={styles["navigation-container"]}>
                <Button

                  onClick={handlePrevMonth}
                  className={styles["month-nav-button prev-button"]}
                  sx={{
                    backgroundColor: '#626262 !important',
                    '&:hover': {
                      backgroundColor: '#828282 !important'
                    }
                  }}
                >
                  Previous
                </Button>
                <Typography variant="h5" className={styles["month-year-title"]}>
                  {new Date(currentYear, currentMonth).toLocaleString('default', {
                    month: 'long',
                    year: 'numeric',
                  })}
                </Typography>
                <Button

                  onClick={handleNextMonth}
                  className={styles["month-nav-button next-button"]}
                  sx={{
                    backgroundColor: '#626262 !important',
                    '&:hover': {
                      backgroundColor: '#828282 !important'
                    }
                  }}
                >
                  Next
                </Button>
              </Box>
            </Box>

            <CalendarLegend sx={{ mr: 3 }} activities={activities} />

            {/* Monthly Summary on the Right */}
            <Box className={styles["monthly-summary-container"]}>
              <SummationCell summary={monthlySummaries} activities={activities} />
            </Box>
          </Box>

          {/* Calendar Grid */}
          <Box className={styles["calendar-grid"]}>
            {/* Weekday Headers */}
            <Box className={styles["weekday-header"]}>
              {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Weekly Summary'].map((day) => (
                <Box key={day} className={styles["weekday-header-cell"]}>
                  <Typography variant="subtitle2">{day}</Typography>
                </Box>
              ))}
            </Box>

            {/* Weeks */}
            <Box className={styles["weeks-container"]}>
              {weeks.map((week, weekIndex) => {
                const summary = aggregateWeeklyActivities(week);
                return (
                  <Box key={weekIndex} className={styles["week-row"]}>
                    {week.map((date, dayIndex) => (
                      <Box key={dayIndex} className={styles["day-cell-container"]}>
                        {date ? (
                          <DayCell
                            date={date}
                            data={data.find((d) => d.date === date.toISOString().split('T')[0]) || {}}

                            isToday={isSameDay(date, today)}
                            activities={activities}
                            businessKey={businessKey}
                            onActivityClick={activityClicked}
                          />
                        ) : (
                          <Box className={styles["empty-day-cell"]} />
                        )}
                      </Box>
                    ))}
                    {/* Weekly Summary */}
                    <Box className={styles["summation-cell-container"]}>
                      <SummationCell summary={summary} activities={activities} />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            mb: 0,
            padding: '8px'
          }}>
       
              <Button
                variant="contained"
                startIcon={<ArrowBack />}
                onClick={() => setDetailMode(false)}
              >
                Back to Calendar
              </Button>
             

            
            <Typography sx={{ fontSize: '2rem', color: '#FFFFFF' }}>
              {selectedDate ? selectedDate.toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
              }) : ''}
            </Typography>
          </Box>
          {DetailComponent && businessKey && selectedDate ? (
            <Paper sx={{p:1}}>
            <DetailComponent
              key={`${businessKey}-${selectedDate.getTime()}`}
              businessKey={businessKey}
              date={selectedDate}
            />
            </Paper>
          ) : null}
        </>
      )
      }
    </>
  );
};

/*
    {DetailComponent  && businessKey && selectedDate  ? (
            <DetailComponent 
            key={`${businessKey}-${selectedDate.getTime()}`}
            businessKey={businessKey} date={selectedDate}  />
          ) : null}

         {DetailComponent  && businessKey && selectedDate  ? (
            <DetailComponent 
            key={`${businessKey}-${selectedDate.getTime()}`}
            businessKey={businessKey} date={selectedDate}  />
          ) : null}


 {DetailComponent  && businessKey  ? (
            <DetailComponent businessKey={businessKey} selectedDate={selectedDate}  />
          ) : null}

 <CalendarDailyDetails
          selectedDate={internalSelectedDate}
          onBack={() => setInternalSelectedDate(null)}
          // Pass additional props as needed
        />*/

CalendarDetail.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      // Dynamic fields based on activities
      // Example fields:
      // quoteDocCount: PropTypes.number,
      // quoteLineCount: PropTypes.number,
      // quoteTotal: PropTypes.number,
      // salesOrderDocCount: PropTypes.number,
      // salesOrderLineCount: PropTypes.number,
      // salesOrderTotal: PropTypes.number,
      // callCount: PropTypes.number,
      // acctNo: PropTypes.string,
    })
  ).isRequired,
  currentYear: PropTypes.number.isRequired,
  currentMonth: PropTypes.number.isRequired,
  onMonthChange: PropTypes.func.isRequired,
  renderDayDetail: PropTypes.func,
  DetailComponent: PropTypes.elementType, // Added

  selectedDate: PropTypes.instanceOf(Date),
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      dataFields: PropTypes.shape({
        docCount: PropTypes.string,
        lineCount: PropTypes.string,
        total: PropTypes.string,
      }),
      minimal: PropTypes.bool,
    })
  ).isRequired,
};

CalendarDetail.defaultProps = {
  selectedDate: null,
  DetailComponent: null,
  renderDayDetail: () => { }, // Provide a default empty function
};

export default CalendarDetail;
