// Customer.jsx
import React from 'react';
import { Paper } from '@mui/material';
import CustomerTabSet from './CustomerTabSet';
import styles from './Customer.css'; // Create this CSS file

const Customer = () => {
    return (
        <Paper className={styles["customer-paper"]}>
            <CustomerTabSet />
        </Paper>
    );
};

export default Customer;
