import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Line,
    Cell,
} from 'recharts';
import {
    Box,
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Paper,
} from '@mui/material';
import MonthPerformanceDetails from './MonthPerformanceDetails';

const MonthlyRevenueChart = ({
    chartData,
    timeFrame,
    setTimeFrame,
    mode,
    setMode,
    annualGoal,
    accountNumber,
}) => {
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const currentDate = new Date();
    const currentMonthName = currentDate.toLocaleString('default', { month: 'short' });

    const sortedChartData = useMemo(() => {
        return [...chartData].sort((a, b) => a.fullDate.getTime() - b.fullDate.getTime());
    }, [chartData]);

    const getOpacity = (entry) => {
        return entry.fullDate <= currentDate ? 1 : 0.5;
    };

    const renderColorfulLegendText = (value, entry) => {
        const { color } = entry;
        return <span style={{ color }}>{value}</span>;
    };

    const descriptionText = `You are viewing the revenue and goal (if there is one) for the selected customer from the selected start to end date. You've selected the ${mode === 'annual' ? 'yearly' : 'monthly'} view, so you're seeing revenue and the goal accumulate through December, then reset in January. You're seeing revenue only without any additional details. Additionally, Open Sales are displayed in yellow on top of the revenue bars.`;

    const handleChartClick = (data) => {
        if (data && data.activeLabel) {
            const monthName = data.activeLabel;
            setSelectedMonth(monthName);
            setSelectedYear(new Date(data.activePayload[0].payload.fullDate).getFullYear());
        }
    };

    const chartType = {
        dataKey1: 'line',
        dataKey2: 'bar',
    };

    const formatYAxis = (value) => {
        return `$${value.toLocaleString()}`;
    };

    const formatTooltip = (value, name) => {
        return [`$${Math.round(value).toLocaleString()}`, name];
    };


    return (
        <Box className="chart-container" sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            color: 'white',
            padding: 4,
            minHeight: '100vh',
        }}>
            <Box className="chart-section" sx={{
                flexBasis: { md: '80%' },
                flexGrow: 1,
                marginRight: { md: 0 },
                marginBottom: { xs: 4, md: 0 },
            }}>
                {!selectedMonth ? (
                    <>
                        <Typography variant="h5" gutterBottom>
                            Performance to Plan
                        </Typography>
                        <ResponsiveContainer width="100%" height={500}>
                            <ComposedChart
                                data={sortedChartData}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                                style={{
                                    backgroundColor: '#111111',
                                    borderRadius: '8px',
                                }}
                                onClick={handleChartClick}
                            >
                                <XAxis
                                    dataKey="date"
                                    stroke="white"
                                    interval={0}
                                    style={{ fontSize: '0.8rem' }}
                                    tick={({ x, y, payload }) => {
                                        const [month, year] = payload.value.split(' ');
                                        const currentDate = new Date();
                                        const currentMonth = currentDate.toLocaleString('default', { month: 'short' });
                                        const currentYear = currentDate.getFullYear().toString();
                                        const isCurrentMonthAndYear = month === currentMonth && year === currentYear;

                                        return (
                                            <g transform={`translate(${x},${y})`}>
                                                <text
                                                    x={0}
                                                    y={0}
                                                    dy={16}
                                                    textAnchor="middle"
                                                    style={{ fontSize: '0.8rem' }}
                                                    fill={isCurrentMonthAndYear ? 'yellow' : 'white'}
                                                >
                                                    {month}
                                                </text>
                                            </g>
                                        );
                                    }}
                                />
                                <YAxis
                                    stroke="white"
                                    style={{ fontSize: '0.8rem' }}
                                    tickFormatter={formatYAxis}
                                />
                                <Tooltip
                                    contentStyle={{ backgroundColor: '#333333', color: 'white', border: 'none' }}
                                    itemStyle={{ color: 'white' }}
                                    labelStyle={{ color: 'white' }}
                                    formatter={formatTooltip}
                                />
                                <Legend
                                    wrapperStyle={{ color: 'white' }}
                                    formatter={renderColorfulLegendText}
                                />
                                <Bar
                                    dataKey="accumulatedRevenue"
                                    stackId="a"
                                    name="Accumulated Revenue"
                                    fill="rgba(54, 162, 235, 0.6)"
                                    isAnimationActive={false}
                                >
                                    {sortedChartData.map((entry, index) => {
                                        const currentDate = new Date();
                                        const isCurrentMonthAndYear =
                                            entry.fullDate.getMonth() === currentDate.getMonth() &&
                                            entry.fullDate.getFullYear() === currentDate.getFullYear();

                                        return (
                                            <Cell
                                                key={`cell-accumulated-${index}`}
                                                fill="rgba(54, 162, 235, 0.3)"
                                                fillOpacity={getOpacity(entry)}
                                                //       stroke={isCurrentMonthAndYear ? '#ff7cee' : 'none'}
                                                strokeWidth={isCurrentMonthAndYear ? 2 : 0}
                                                cursor="pointer"
                                            />
                                        );
                                    })}
                                </Bar>

                                <Bar
                                    dataKey="thisMonthRevenue"
                                    stackId="a"
                                    name="Monthly Contribution"
                                    fill="rgb(54, 162, 235)"
                                    isAnimationActive={false}
                                >
                                    {sortedChartData.map((entry, index) => (
                                        <Cell
                                            key={`cell-monthly-${index}`}
                                            fill="rgba(54, 162, 235, 0.6)"
                                            fillOpacity={getOpacity(entry)}
                                            // Removed stroke to prevent double outlining
                                            cursor="pointer"
                                        />
                                    ))}
                                </Bar>
                                <Bar
                                    dataKey="openSales"
                                    stackId="a"
                                    name="Open Sales"
                                    isAnimationActive={false}
                                    fill="rgb(255, 206, 86)"
                                >
                                    {sortedChartData.map((entry, index) => (
                                        <Cell
                                            key={`cell-opensales-${index}`}
                                            fill="rgba(255, 206, 86, 0.6)"
                                            fillOpacity={getOpacity(entry)}
                                            // Removed stroke and strokeWidth
                                            cursor="pointer"
                                        />
                                    ))}
                                </Bar>
                                <Line
                                    //type="monotone"
                                    type="linear"
                                    dataKey="annualPlan"
                                    stroke="#00a900"
                                    name="Annual Plan"
                                    strokeWidth={4}
                                    dot={false}
                                    connectNulls={false}
                                />
                                <Line
                                    //type="monotone"
                                    type="linear"
                                    dataKey="pastYearPerformance"
                                    stroke="#808080"
                                    name="Past Year Performance"
                                    strokeWidth={4}
                                    dot={false}
                                />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </>
                ) : (
                    <MonthPerformanceDetails
                        month={selectedMonth}
                        year={selectedYear}
                        onBack={() => setSelectedMonth(null)}
                        accountNumber={accountNumber}
                    />
                )}
            </Box>

            {/* Controls Section - 20% */}
            <Box
                className="controls-section"
                sx={{
                    flexBasis: { md: '20%' },
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {/* Time Selection Control */}
                <FormControl component="fieldset" sx={{ marginBottom: 4 }}>
                    <FormLabel component="legend">
                        <Typography variant="h6" gutterBottom>
                            Time
                        </Typography>
                    </FormLabel>
                    <RadioGroup
                        aria-label="timeRange"
                        name="timeRange"
                        value={timeFrame}
                        onChange={(e) => setTimeFrame(e.target.value)}
                    >
                        <FormControlLabel
                            value="thisYear"
                            control={<Radio />}
                            label="This year"
                        />
                        <FormControlLabel
                            value="past2Years"
                            control={<Radio />}
                            label="Past 2 years"
                        />
                        <FormControlLabel
                            value="past3Years"
                            control={<Radio />}
                            label="Past 3 years"
                        />
                    </RadioGroup>
                </FormControl>

                {/* View Type Selection Control */}
                <FormControl component="fieldset">
                    <FormLabel component="legend">
                        <Typography variant="h6" gutterBottom>
                            View Type
                        </Typography>
                    </FormLabel>
                    <RadioGroup
                        aria-label="viewType"
                        name="viewType"
                        value={mode === 'annual' ? 'Yearly' : 'Monthly'}
                        onChange={(e) => setMode(e.target.value === 'Yearly' ? 'annual' : 'month')}
                        row
                    >
                        <FormControlLabel
                            value="Yearly"
                            control={<Radio />}
                            label="Yearly"
                        />
                        <FormControlLabel
                            value="Monthly"
                            control={<Radio />}
                            label="Monthly"
                        />
                    </RadioGroup>
                </FormControl>

                {/* Description Section */}
                <Paper
                    elevation={3}
                    sx={{
                        marginTop: 4,
                        padding: 2,
                        backgroundColor: '#1e1e1e',
                        color: 'lightgray',
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        What you're seeing
                    </Typography>
                    <Typography variant="body2">{descriptionText}</Typography>
                </Paper>
            </Box>
        </Box>
    );
};

MonthlyRevenueChart.propTypes = {
    chartData: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            fullDate: PropTypes.instanceOf(Date).isRequired,
            accumulatedRevenue: PropTypes.number.isRequired,
            thisMonthRevenue: PropTypes.number.isRequired,
            openSales: PropTypes.number.isRequired,
            annualPlan: PropTypes.number.isRequired,
            pastYearPerformance: PropTypes.number, // Optional
        })
    ).isRequired,
    timeFrame: PropTypes.string.isRequired,
    setTimeFrame: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
    setMode: PropTypes.func.isRequired,
    annualGoal: PropTypes.number.isRequired,
    accountNumber: PropTypes.string.isRequired,
};

export default MonthlyRevenueChart;
