
import { gql, useQuery } from '@apollo/client';


export const GET_CUSTOMERS_QUERY = gql`
  query GetCustomers($search: String!) {
       customer(where: { name: { contains: $search } }, first: null) {
        pageInfo {
            hasNextPage
            endCursor
        }
        nodes {
            acctNo
            name
            assignedSalesperson
        }
    }
  }
`;

export const GET_CUSTOMERS_BY_ACCTNO_QUERY = gql`
  query GetCustomerByAcctNo($acctNo: String!) {
    customer(where: { acctNo: { eq: $acctNo } }) {
      nodes {
        acctNo
        name
        assignedSalesperson
      }
    }
  }
`;



export const GET_CUSTOMERS_QUERY_OLD = `
  query GetCustomers($search: String!) {
    custVendDim(
      where: { name: { contains: $search },
       isValid: { eq: true }
      }
      first: 10
    ) {
      nodes {
        acctNo
        name
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;



export const GET_CUSTOMER_PERFORMANCE = gql`
  query customerPerformance($acctNo: String!, $start: DateTime!, $end: DateTime!, $mode: String!) {
    customerPerformance(acctNo: $acctNo, start: $start, end: $end, mode: $mode) {
      months {
        date
        totalRevenue
        openSales
        goal
        breakdown {
          dimension
          categories {
            name
            value
          }
        }
        additionalMetrics {  
          name
          value
        }
      }
      annualGoal
    }
  }
`;

export const GET_SALESPERSON_PERFORMANCE = gql`
  query salespersonPerformance($ccode: String!, $timeFrame: String!, $mode: String!) {
    salespersonPerformance(ccode: $ccode, timeFrame: $timeFrame, mode: $mode) {
        type
        businessKey
        startDate
        endDate
        years {
            year
            annualGoal
            totalRevenue
            months {
                date
                totalRevenue
                openSales
                monthlyContribution
                goal
            }
        }
    }
}

`;




export const GET_PRODUCTION_DAILY = gql`
query DailyProductionForCustomer($acctNo: String!) {
  dailyProductionForCustomer(acctNo: $acctNo) {
    date
    quoteDocCount
    quoteLineCount
    quoteTotal
    salesOrderDocCount
    salesOrderLineCount
    salesOrderTotal
    callCount
    acctNo
  }
}

`;

export const GET_PERFORMANCE_DAILY = gql`
query DailyPerformanceForCustomer($acctNo: String!) {
    dailyPerformanceForCustomer(acctNo: $acctNo) {
        date
        invoiceDocCount
        invoiceLineCount
        invoiceTotal
        salesOrderDocCount
        salesOrderLineCount
        salesOrderTotal      
        acctNo
    }
}
`;



export const GET_CUSTOMER_DETAILS_QUERY = gql`
  query GetCustomerDetails($acctNo: String!) {
    customerLandingPageDetails(acctNo: $acctNo) {
      lastQuoteDate
      lastSalesOrderDate
      lastCallTrackerDate
      healthStatus
      creditRemaining
      creditLimit
      ytdRevenue
      ytdGoal
      netNewOrders
      openOrders
    }
  }
`;

export const GET_CUSTOMER_LANDING_PAGE = gql`
  query GetCustomerLandingDetailsByAccountNumber($acctNo: String!) {
    customerLandingDetailsByAccountNumber(acctNo: $acctNo) {
      acctNo
      creditLimit
      creditRemaining
      healthStatus
      lastCallTrackerDate
      lastQuoteDate
      lastSalesOrderDate
      name
      netNewOrders
      openOrders
      ytdGoal
      ytdRevenue
    }
  }
`;





const GET_EMAILS = gql`
  query GetEmails {
    emails {
      items {
        aiResponse
        aiParts
        subject
        webLink
        fromAddress
        fromName
        assignedTo
        assignedDateTime
        aiSummary
        aiAnalyzedYN
        aiLegitimate
        aiAnalyzedDateTime
        pentagonAccount
        pentagonLink
        pentagonLinkMethod
        pentagonLinkDateTime
        receivedDateTime
        sentDateTime
        status
        parts {
          condition
          partnumber
          quantity
        }
      }
    }
  }
`;



export const GET_CUSTOMER_PROFILE = gql`
  query GetCustomerProfileByAccountNumber($acctNo: String!) {
    customerProfileByAccountNumber(acctNo: $acctNo) {
      accountApprovedYN
    accountRating
    accountType
    acctNo
    airframeList
    alertText
    alertYN
    annualFinancialPlan
    avl_YN
    bdCrms
    country
    creditLimit
    creditRemaining
    creditTerms
    currentBacklog
    customerHealth
    customerPriority
    engineCustomerYN
    fleetSize
    keyCustomerYN
    memoText
    onHold
    openBalance
    pbh_YN
    pbhCompany
    priority
    region
    state
    ytdInvoiced
    ytdSalesOrders
    }
  }
`;


export const GET_SALESPEOPLE_QUERY = gql`

query SalesPeople {
  salespeople {
      
      items {
          fullName
          cCode
      }
  }
}
`;


export const GET_SALESPERSON_BY_CCODE_QUERY = gql`

query GetSalespersonByCcode($ccode: String!) {
  salespeople (where: { ccode: { eq: $ccode } }) {
       
      
          fullName
          cCode
      
  }
}
`;



export const GET_SALESPERSON_PROFILE = gql`
  query GetSalespersonProfileByCcode($ccode: String!) {
    salespersonProfileByCcode(ccode: $ccode) {
        fullName
        cCode
        avgQuotesPerDayDollars12m
        avgQuotesPerDayDollars6m
        avgQuotesPerDayDollarsQr
        avgQuotesPerDayLines12m
        avgQuotesPerDayLines6m
        avgQuotesPerDayLinesQr
        avgRfqsPerDayDollars12m
        avgRfqsPerDayDollars6m
        avgRfqsPerDayDollarsQr
        avgRfqsPerDayLines12m
        avgRfqsPerDayLines6m
        avgRfqsPerDayLinesQr
        avgSalesPerDayDollars12m
        avgSalesPerDayDollars6m
        avgSalesPerDayDollarsQr
        avgSalesPerDayLines12m
        avgSalesPerDayLines6m
        avgSalesPerDayLinesQr
        ytdRevenue
        goal
        netNewOrders
        openOrders
    }
  }
`;

export const GET_ASSIGNED_ACCOUNTS = gql`

  query AssignedAccounts($ccode: String!) {
    assignedAccounts(ccode: $ccode) {
        acctNo
        name
        customerHealth
        customerKhealth
        specialAccount
        specialAccountLabel
        airframeCustomerClassification
        isAirframeKeyCustomer
        engineCustomerClassification
        isEngineKeyCustomer
        isOperator
        annualFinancialPlanNumeric
        accountType
        accountRatingDesc
        marketSegment
    }
}

`;


// SALES
export const GET_DAILY_SALESPERSON_PRICING_PRODUCTION = gql`
query DailySalespersonSalesPricingProduction($ccode: String!) {
  dailySalespersonPricingProduction(ccode: $ccode) {
    ccode
    date
    purchaseRFQDocCount
    purchaseRFQLineCount
    repairRFQDocCount
    repairRFQLineCount
    pricingEvents
  }
}

`;

export const GET_DAILY_SALESPERSON_SALES_PRODUCTION = gql`
query DailySalespersonSalesProduction($ccode: String!) {
  dailySalespersonSalesProduction(ccode: $ccode) {
    ccode
    date
    quoteDocCount
    quoteLineCount
    quoteTotal
    salesOrderDocCount
    salesOrderLineCount
    salesOrderTotal
    callCount
    
  }
}

`;






// Define GraphQL Queries
export const GET_QUOTES_FOR_DAY_FOR_CUSTOMER = gql`
    query CustomerQuotesForDay($acctNo: String!, $date: DateTime!) {
        customerQuotesForDay(acctNo: $acctNo, date: $date) {
          docNo
          userLine
          acctno
          partnumber
          condition
          serialNumber
          qtyReq
          qtyBid
          lineTotal
          ata
          traceTo
          tagInfo
          customerContact
          description
        }
    }
`;


// Define GraphQL Queries
export const GET_QUOTES_FOR_DAY_FOR_SALESPERSON = gql`
    query GetQuotesForDayForSalesperson($ccode: String!, $date: DateTime!) {
        quotesForDayForSalesperson(ccode: $ccode, date: $date) {
          docNo
          userLine
          acctno
          partnumber
          condition
          serialNumber
          qtyReq
          qtyBid
          lineTotal
          ata
          traceTo
          tagInfo
          customerContact
          description
        }
    }
`;

// Define GraphQL Queries
export const GET_PURCHASE_RFQS_FOR_DAY_FOR_SALESPERSON = gql`
    query GetPurchaseRFQsForDayForSalesperson($ccode: String!, $date: DateTime!) {
        purchaseRFQsForDayForSalesperson(ccode: $ccode, date: $date) {
        docNo
        acctno
        partnumber
        condition
        description
        unitPrice
        traceTo
        ata
        tagInfo
        note
        addedOnDate
        }
    }
`;

// Define GraphQL Queries
export const GET_REPAIR_RFQS_FOR_DAY_FOR_SALESPERSON = gql`
    query GetRepairRFQsForDayForSalesperson($ccode: String!, $date: DateTime!) {
        repairRFQsForDayForSalesperson(ccode: $ccode, date: $date) {
        docNo
        acctno
        partnumber
        condition
        description
        unitPrice
        traceTo
        ata
        tagInfo
        note
        addedOnDate
        }
    }
`;


// Define GraphQL Queries
export const GET_PE_FOR_DAY_FOR_SALESPERSON = gql`
    query GetPeForDayForSalesperson($ccode: String!, $date: DateTime!) {
        peForDayForSalesperson(ccode: $ccode, date: $date) {
          partnumber
          eventDate
          arPrice
          svPrice
          ohPrice
          nsNePrice
          arPriceDate
          arPriceDateKey
          svPriceDate
          ohPriceDate
          nsNePriceDate
          changeSummary
        }
    }
`;

export const GET_SALES_FOR_DAY_FOR_CUSTOMER = gql`
    query CustomerSalesForDay($acctNo: String!, $date: DateTime!) {
        customerSalesOrdersForDay(acctNo: $acctNo, date: $date) {
          docNo
          userLine
          description
          acctno
          partnumber
          condition
          serialNumber
          qtyOrdered
          shippedDate
          estShipDate
          lineTotal
          ata
          traceTo
          tagInfo
        }
    }
`;

export const GET_SALES_FOR_DAY_FOR_SALESPERSON = gql`
    query GetSalesOrdersForDayForSalesperson($ccode: String!, $date: DateTime!) {
        salesOrdersForDayForSalesperson(ccode: $ccode, date: $date) {
          docNo
          userLine
          description
          acctno
          partnumber
          condition
          serialNumber
          qtyOrdered
          shippedDate
          estShipDate
          lineTotal
          ata
          traceTo
          tagInfo
          
        }
    }
`;

export const GET_CALL_TRACKERS_FOR_DAY_FOR_CUSTOMER = gql`
    query CustomerCallTrackersForDay($acctNo: String!, $date: DateTime!) {
        customerCallTrackersForDay(acctNo: $acctNo, date: $date) {
          docNo
          callTypeDesc
          callCategoryDesc
          acctNo
          enteredDate
          enteredByCcode
          callMessage
        }
    }
`;

export const GET_CALL_TRACKERS_FOR_DAY_FOR_SALESPERSON = gql`
    query GetCallTrackersForDayForSalesperson($ccode: String!, $date: DateTime!) {
        callTrackersForDayForSalesperson(ccode: $ccode, date: $date) {
        docNo
        callTypeDesc
        callCategoryDesc
        acctNo
        enteredDate
        enteredByCcode
        callMessage
        }
    }
`;


// GraphQL Queries
export const GET_SALESPERSON_INVOICES_FOR_MONTH = gql`
  query SalespersonInvoicesForMonth($ccode: String!, $year: Int!, $month: Int!) {
    salespersonInvoicesForMonth(ccode: $ccode, year: $year, month: $month) {
      id
      docNo
      unitPrice
      qtyShipped
      lineTotal
      finalPrice
      partnumber
      condition
      description
      serialNumber
    }
  }
`;

export const GET_SALESPERSON_OPEN_SALES_ORDERS_FOR_MONTH = gql`
  query SalespersonOpenSalesOrdersForMonth($ccode: String!, $year: Int!, $month: Int!) {
    salespersonOpenSalesOrdersForMonth(ccode: $ccode, year: $year, month: $month) {
      id
      docNo
      unitPrice
      qtyShipped
      estShipDate
      qtyOrdered
      lineTotal            
      partnumber
      condition
      description
      serialNumber
    }
  }
`;