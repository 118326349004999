// SalespersonSelect.js
import React, { useState, useEffect } from 'react';
import { TextField, CircularProgress, Autocomplete, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';

import { GET_SALESPEOPLE_QUERY, GET_SALESPERSON_BY_CCODE_QUERY } from '../../../queries';

const SalespersonSelect = ({ onSalespersonSelect, defaultSalespersonCode }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedSalesperson, setSelectedSalesperson] = useState(null);
  const [options, setOptions] = useState([]);

  // Fetch all salespeople
  const { data: allData, loading: allLoading, error: allError } = useQuery(GET_SALESPEOPLE_QUERY);

  // Fetch default salesperson by code if provided
  const { data: defaultData, loading: defaultLoading, error: defaultError } = useQuery(
    GET_SALESPERSON_BY_CCODE_QUERY,
    {
      variables: { code: defaultSalespersonCode },
      skip: !defaultSalespersonCode,
    }
  );

  // Handle errors
  useEffect(() => {
    if (allError) console.error('Error fetching all salespeople:', allError);
    if (defaultError) console.error('Error fetching default salesperson:', defaultError);
  }, [allError, defaultError]);

  // Set options once all salespeople are fetched
  useEffect(() => {
    if (allData?.salespeople?.items) {
      setOptions(allData.salespeople.items);
    }
  }, [allData]);

  // Handle default salesperson selection
  useEffect(() => {
    if (
      defaultSalespersonCode &&
      defaultData?.salespeople?.items?.length > 0 &&
      !selectedSalesperson
    ) {
      const foundSalesperson = defaultData.salespeople.items[0];
      setSelectedSalesperson(foundSalesperson);
      setInputValue(foundSalesperson.fullName);
      onSalespersonSelect(foundSalesperson);
      setOptions((prevOptions) => {
        if (!prevOptions.some((s) => s.cCode === foundSalesperson.cCode)) {
          return [foundSalesperson, ...prevOptions];
        }
        return prevOptions;
      });
    }
  }, [defaultSalespersonCode, defaultData, onSalespersonSelect, selectedSalesperson]);

  const handleInputChange = (event, value) => {
    setInputValue(value);
    // No need for debounce since all data is pre-fetched
  };

  const handleSalespersonSelect = (event, value) => {
    setSelectedSalesperson(value);
    if (value) {
      setInputValue(value.fullName);
      onSalespersonSelect(value);
    } else {
      setInputValue('');
      onSalespersonSelect(null);
    }
  };

  return (
    <>
      {(allError || defaultError) && (
        <Typography variant="body2" color="error">
          {allError?.message || defaultError?.message || 'An error occurred while fetching salespeople.'}
        </Typography>
      )}
      <Autocomplete
        sx={{ minWidth: 400, mt: 0 }}
        getOptionLabel={(option) => option.fullName}
        options={options}
        loading={allLoading || defaultLoading}
        value={selectedSalesperson}
        onChange={handleSalespersonSelect}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        isOptionEqualToValue={(option, value) => option.cCode === value.cCode}
        noOptionsText="No salespeople found"
        renderOption={(props, option) => (
          <li {...props} key={option.cCode}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {option.fullName}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ marginLeft: 1 }}>
              {option.cCode}
            </Typography>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            sx={{ mt: 0 }}
            {...params}
            label="Select Salesperson"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {(allLoading || defaultLoading) ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default SalespersonSelect;
