import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper
} from '@mui/material';
import useAuthenticatedData from '../../hooks/useAuthenticatedData';
import KpisGrid from './KpisGrid';
import MetricsGrid from './MetricsGridxx';
import GoalsGrid from '../overview/GoalsGrid';
import { useMsal } from "@azure/msal-react";


const GridWrapper = ({ title, children }) => (
  <Paper sx={{ p: 2, mb: 2 }}>
    <Typography variant="h6" gutterBottom>{title}</Typography>
    {children}
  </Paper>
);

const IOSDashboard = () => {
  const { data: favoritedKpis, isLoading: isLoadingFavKpis, error: errorFavKpis } = useAuthenticatedData('https://localhost:5001/kpis/favorited');
  const { data: favoritedMetrics, isLoading: isLoadingFavMetrics, error: errorFavMetrics } = useAuthenticatedData('https://localhost:5001/metrics/favorited');
  const { data: favoritedGoals, isLoading: isLoadingFavGoals, error: errorFavGoals } = useAuthenticatedData('https://localhost:5001/goals/favorited');

  const { data: assignedKpis, isLoading: isLoadingAssKpis, error: errorAssKpis } = useAuthenticatedData('https://localhost:5001/kpis/assigned');
  const { data: assignedMetrics, isLoading: isLoadingAssMetrics, error: errorAssMetrics } = useAuthenticatedData('https://localhost:5001/metrics/assigned');
  const { data: assignedGoals, isLoading: isLoadingAssGoals, error: errorAssGoals } = useAuthenticatedData('https://localhost:5001/goals/assigned');

  const { instance, accounts } = useMsal();
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (accounts.length > 0) {
      const name = accounts[0].name || accounts[0].username || "User";
      setUsername(name);
    }
  }, [accounts]);

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
    <Typography variant="h1" component="h1" gutterBottom align="left">
      DASHBOARD
    </Typography>
    <Box sx={{ mt: 2 }}>
      <Typography variant="body1" paragraph align="left">
        Welcome to your dashboard, {username}!
      </Typography>
      <Typography variant="body1" align="left">
        This application knows who you are and can show whatever data makes sense here.
      </Typography>

      
    </Box>
  </Paper>
  );
};

export default IOSDashboard;