import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Paper, Grid } from '@mui/material';
import useAuthenticatedData from '../hooks/useAuthenticatedData';

const RoleDetail = () => {
    const { roleId } = useParams();
    const { data: role, isLoading, error } = useAuthenticatedData(`https://localhost:5001/admin/roles/${roleId}`);

    if (!role) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Box sx={{ flexGrow: 1, padding: 3 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Role Details
            </Typography>
            <Paper elevation={3} sx={{ padding: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">Name:</Typography>
                        <Typography variant="body1">{role.name}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

export default RoleDetail;