// components/CalendarMasterDetail/CalendarMasterDetail.jsx

import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import HorizontalCalendarScroller from './HorizontalCalendarScroller';
import CalendarDetail from './CalendarDetail';
import PropTypes from 'prop-types';
import { addMonths, endOfMonth, startOfMonth } from 'date-fns';
import styles from './CalendarMasterDetail.module.css';

/**
 * CalendarMasterDetail Component
 *
 * A generic, reusable calendar component that combines a horizontal calendar scroller
 * with a detailed calendar view. It accepts pre-fetched data and supports dynamic
 * activity types.
 *
 * Props:
 * - data: Array of data objects containing date and activity information.
 * - renderDayDetail: Function or component to render the day detail view.
 * - initialDate: Initially selected date (default: null).
 * - onDateSelect: Optional callback when a date is selected.
 * - activities: Array of activity configurations for rendering in DayCell.
 * - currentYear: Current year for the detail view.
 * - currentMonth: Current month for the detail view.
 * - onMonthChange: Callback when the month changes.
 */



const CalendarMasterDetail = ({
  data,
  renderDayDetail,
  initialDate = null,
  onDateSelect,
  activities,

  currentYear: initialYear,
  currentMonth: initialMonth,
  onMonthChange,
  businessKey,
}) => {
  // State for selected date, current year, and current month
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [currentYear, setCurrentYear] = useState(
    initialDate ? initialDate.getFullYear() : initialYear || new Date().getFullYear()
  );
  const [currentMonth, setCurrentMonth] = useState(
    initialDate ? initialDate.getMonth() : initialMonth || new Date().getMonth()
  );

  const [displayDayDetail, setDisplayDayDetail] = useState(false);
  const [detailComponent, setDetailComponent] = useState(null);
  const [detailMode, setDetailMode] = useState(false);

  console.log('data:', data);

  const activityClicked = (activityType, date, businessKey) => {

   
      console.log('Activity Clicked:', activityType, date, businessKey);

      const activity = activities.find(a => a.type === activityType);
      console.log('Activity dv:', activity.detailView);

      if (activity && activity.detailView) {
        setSelectedDate(date); // Ensure you're setting the correct date

        // Set DetailComponent to the component type, not an element
        setDetailComponent(() => activity.detailView);

        setDetailMode(true);
      } else {
        console.warn('No detail view available for this activity');
      }
    };


  


  /**
   * Handle date selection from the scroller or calendar detail.
   * Updates the selected date and current month/year.
   */
  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setCurrentYear(date.getFullYear());
    setCurrentMonth(date.getMonth());
    if (onDateSelect) onDateSelect(date);
  };

  /**
   * Handle month navigation (previous/next month).
   * Updates the current month and year.
   */
  const handleMonthChange = (year, month) => {
    setCurrentYear(year);
    setCurrentMonth(month);
    if (onMonthChange) onMonthChange(year, month);
  };

  /**
   * Transform data to match CalendarDetail's expected format
   * Adjust this mapping based on your actual data structure
   */
  const formattedDetailData = useMemo(() => {
    if (!data) return [];
    const formatted =  data.map((item) => ({
      date: item.date,
      ...item, // Spread other activity fields dynamically
    }));

    console.log('formatted:', formatted);

    return formatted;
  }, [data]);

  // Define the date range for the scroller (e.g., past 12 months to current month)
  const currentDate = new Date();
  const endDate = endOfMonth(currentDate);
  const startDate = startOfMonth(addMonths(currentDate, -12));

  return (
    <Box className={styles["calendar-master-detail-container"]}>


      <Box className={styles["scroller-and-detail"]}>
        {/* Horizontal Calendar Scroller */}
        <Box className={styles["scroller-container"]}>
          <HorizontalCalendarScroller
            data={formattedDetailData}
            onDateSelect={handleDateSelect}
            selectedDate={selectedDate}
            currentYear={currentYear}
            currentMonth={currentMonth}
            startDate={startDate}
            endDate={endDate}
            activities={activities} // Pass activities config if needed
        

          />
        </Box>

        {/* Calendar Detail View */}
         
          <CalendarDetail
            data={formattedDetailData}
            currentYear={currentYear}
            currentMonth={currentMonth}
            onMonthChange={handleMonthChange}
            renderDayDetail={renderDayDetail}
            selectedDate={selectedDate}
            activities={activities} // Pass activities config for dynamic rendering
            businessKey={businessKey}
            detailMode={detailMode}
            activityClicked={activityClicked}
            DetailComponent={detailComponent}
            setDetailMode={setDetailMode}

          />
        

      </Box>


    </Box>
  );
};

CalendarMasterDetail.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired, // Adjust based on your date format
      // Add other dynamic fields based on activities
    })
  ).isRequired,
  renderDayDetail: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.elementType,
  ]), // Component or render function for day detail
  initialDate: PropTypes.instanceOf(Date), // Initially selected date
  onDateSelect: PropTypes.func, // Callback when a date is selected
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      dataFields: PropTypes.object, // Make it generic
      minimal: PropTypes.bool,
      detailView: PropTypes.elementType, // Ensure detailView is a component type

    })
  ).isRequired, // Activity configurations
  currentYear: PropTypes.number,
  currentMonth: PropTypes.number,
  onMonthChange: PropTypes.func,
  businessKey: PropTypes.string.isRequired, // Assuming it's required

};

export default CalendarMasterDetail;
