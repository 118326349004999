// RepairDetail.js
import React from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';

const RepairDetail = ({ repair }) => {
  if (!repair) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography>Select a repair to view details.</Typography>
      </Box>
    );
  }

  return (
    <Paper sx={{ p: 2, height: '100%', overflow: 'auto' }}>
      <Typography variant="h6" gutterBottom>Repair Details</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="subtitle2">ID:</Typography>
          <Typography>{repair.id}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">Added On:</Typography>
          <Typography>{repair.addedOnDate ? new Date(repair.addedOnDate).toLocaleDateString() : 'N/A'}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">Vendor:</Typography>
          <Typography>{repair.vendorName || 'N/A'}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">Part Number:</Typography>
          <Typography>{repair.partnumber || 'N/A'}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Description:</Typography>
          <Typography>{repair.description || 'N/A'}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">Line Total:</Typography>
          <Typography>{repair.lineTotal ? `$${repair.lineTotal.toFixed(2)}` : 'N/A'}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">Days Open:</Typography>
          <Typography>{repair.daysOpen || 'N/A'}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">Status:</Typography>
          <Typography>{repair.docStatusDesc || 'N/A'}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RepairDetail;