// Performance.jsx
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { Box, CircularProgress, Alert, Typography } from '@mui/material';
import { GET_SALESPERSON_PERFORMANCE } from '../../../queries';
import PerformanceChart from '../../PerformanceChart/PerformanceChart';
import SalespersonMonthPerformanceDetails from './SalespersonMonthPerformanceDetails'; 

const SalespersonPerformance = ({ selectedSalesperson }) => {
  // State management for time frame, mode, and detail type
  const [timeFrame, setTimeFrame] = useState('1year');
  const [mode, setMode] = useState('annual');
  const [detailType, setDetailType] = useState('Monthly Contribution'); // New state

 

  // Memoize the date range to avoid unnecessary recalculations
 
  // GraphQL query to fetch salesperson performance data
  const {
    data: performanceData,
    loading: performanceLoading,
    error: performanceError,
    refetch: refetchPerformance,
  } = useQuery(GET_SALESPERSON_PERFORMANCE, {
    variables: {
      ccode: selectedSalesperson?.cCode || '',
      timeFrame,
      mode,
    },
    skip: !selectedSalesperson, // Skip the query if no salesperson is selected
    fetchPolicy: 'network-only', // Always fetch fresh data
  });

  // Refetch data when timeFrame or mode changes
  useEffect(() => {
    if (selectedSalesperson) {
      refetchPerformance({
        ccode: selectedSalesperson.cCode,
        timeFrame,
        mode,
      });
    }
  }, [selectedSalesperson, timeFrame, mode, timeFrame, refetchPerformance]);

  // Log errors to the console for debugging
  useEffect(() => {
    if (performanceError) {
      console.error('Error fetching salesperson performance:', performanceError);
    }
  }, [performanceError]);

  // Remove the old chartData processing as it's no longer needed
  /*
  const chartData = useMemo(() => {
    // Existing chartData processing logic...
  }, [performanceData]);
  */

  // Handle loading and error states for better user experience
  if (!selectedSalesperson) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#121212"
        padding={0}
      >
        <Typography variant="h6" color="white">
          Please select a salesperson to view performance data.
        </Typography>
      </Box>
    );
  }

  if (performanceLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#121212"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (performanceError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#121212"
        padding={0}
      >
        <Alert severity="error">{performanceError.message}</Alert>
      </Box>
    );
  }

  // Check if the performanceData has the expected structure
  if (!performanceData?.salespersonPerformance?.years?.length) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#121212"
        padding={0}
      >
        <Typography variant="h6" color="white">
          No performance data available for the selected customer.
        </Typography>
      </Box>
    );
  }

console.log(performanceData.salespersonPerformance);

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        overflow: 'auto', // Allow overall container to scroll

        bgcolor: '#121212',
      }}
    >
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', padding:0 }}>
        <PerformanceChart
          data={performanceData?.salespersonPerformance} 
          timeFrame={timeFrame}
          setTimeFrame={setTimeFrame}
          mode={mode}
          setMode={setMode}
          detailType={detailType} // Pass detailType state
          setDetailType={setDetailType} // Pass setDetailType function
          accountNumber={selectedSalesperson.cCode || 'N/A'}
          businessKey={selectedSalesperson.cCode}
          DetailComponent={SalespersonMonthPerformanceDetails}
        />
      </Box>
    </Box>
  );
};

export default SalespersonPerformance;
