import React, { useState, useEffect } from 'react';
import { TextField, Slider, Button, Typography, Box } from '@mui/material';
import './FilterControls.css';

const FilterControls = ({ onFilterChange, onReset, initialFilters, advanced, setAdvanced }) => {
    const [filters, setFilters] = useState({
        name: initialFilters.name || '',
        creditLimit: initialFilters.creditLimit || 1000000,
        lastQuoted: initialFilters.lastQuoted || '',
        lastCallTracker: initialFilters.lastCallTracker || '',
        lastSODate: initialFilters.lastSODate || '',
    });

    useEffect(() => {
        const activeFilters = {
            name: filters.name,
            creditLimit: filters.creditLimit,
            lastQuoted: filters.lastQuoted,
            lastCallTracker: filters.lastCallTracker,
            lastSODate: filters.lastSODate,
        };
        onFilterChange(activeFilters);
    }, [filters, onFilterChange]);

    const handleInputChange = (field) => (event) => {
        setFilters(prev => ({
            ...prev,
            [field]: event.target.value,
        }));
    };

    const handleSliderChange = (event, newValue) => {
        setFilters(prev => ({
            ...prev,
            creditLimit: newValue,
        }));
    };

    const toggleAdvanced = () => {
        setAdvanced(prev => !prev);
    };

    const handleReset = () => {
        setFilters({
            name: '',
            creditLimit: 1000000,
            lastQuoted: '',
            lastCallTracker: '',
            lastSODate: '',
        });
        onReset();
    };

    return (
        <Box className="filterControls">
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={0}>
                <Typography variant="h5" gutterBottom style={{ color: '#ffffff' }}>Filters</Typography>
                <Button 
                    variant="outlined" 
                    size="small" 
                    onClick={handleReset} 
                    style={{ color: '#ffffff', borderColor: '#90caf9' }}
                >
                    Reset Filters
                </Button>
            </Box>

            <Box>
                <TextField
                    label="Customer Name"
                    type="text"
                    value={filters.name}
                    onChange={handleInputChange('name')}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                        style: { color: '#ffffff' },
                    }}
                    InputProps={{
                        style: { color: '#ffffff' },
                    }}
                />

                <Typography gutterBottom style={{ color: '#ffffff' }}>Credit Limit (≤)</Typography>
                <Slider
                    value={filters.creditLimit}
                    onChange={handleSliderChange}
                    valueLabelDisplay="auto"
                    min={0}
                    max={1000000}
                    step={1000}
                    sx={{
                        color: '#90caf9',
                    }}
                />
            </Box>

            <Button 
                variant="text" 
                size="small" 
                onClick={toggleAdvanced} 
                style={{ color: '#90caf9' }}
            >
                {advanced ? 'Collapse' : 'Advanced'}
            </Button>

            {advanced && (
                <Box mt={2}>
                    <TextField
                        label="Last Quoted"
                        type="date"
                        value={filters.lastQuoted}
                        onChange={handleInputChange('lastQuoted')}
                        InputLabelProps={{
                            shrink: true,
                            style: { color: '#ffffff' },
                        }}
                        InputProps={{
                            style: { color: '#ffffff' },
                        }}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        size="small"
                    />

                    <TextField
                        label="Last Call Tracker"
                        type="date"
                        value={filters.lastCallTracker}
                        onChange={handleInputChange('lastCallTracker')}
                        InputLabelProps={{
                            shrink: true,
                            style: { color: '#ffffff' },
                        }}
                        InputProps={{
                            style: { color: '#ffffff' },
                        }}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        size="small"
                    />

                    <TextField
                        label="Last SO Date"
                        type="date"
                        value={filters.lastSODate}
                        onChange={handleInputChange('lastSODate')}
                        InputLabelProps={{
                            shrink: true,
                            style: { color: '#ffffff' },
                        }}
                        InputProps={{
                            style: { color: '#ffffff' },
                        }}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        size="small"
                    />
                </Box>
            )}
        </Box>
    );
};

export default FilterControls;
