import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Paper, Grid, IconButton, Tooltip, 
  LinearProgress, Chip, CircularProgress, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow
} from '@mui/material';
import { 
  TrendingUp, TrendingDown, CheckCircle, Error, 
  Flag, Timeline, BarChart, PieChart
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import useAuthenticatedData from '../../hooks/useAuthenticatedData';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  borderRadius: '15px',
  boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
}));

const HealthIndicator = ({ value }) => {
  const color = value >= 70 ? 'success' : value >= 40 ? 'warning' : 'error';
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <LinearProgress 
        variant="determinate" 
        value={value} 
        color={color} 
        sx={{ width: 100, mr: 1 }}
      />
      <Typography variant="body2" color="text.secondary">
        {value}%
      </Typography>
    </Box>
  );
};

const TrendIcon = ({ trend }) => (
  <Tooltip title={trend === 'up' ? 'Trending Up' : 'Trending Down'}>
    <IconButton size="small">
      {trend === 'up' ? <TrendingUp color="success" /> : <TrendingDown color="error" />}
    </IconButton>
  </Tooltip>
);

const StatusChip = ({ status }) => (
  <Chip 
    icon={status === 'on_track' ? <CheckCircle /> : status === 'at_risk' ? <Error /> : <Error />}
    label={status.replace('_', ' ')}
    color={status === 'on_track' ? 'success' : status === 'at_risk' ? 'warning' : 'error'}
  />
);

const LevelDetail = ({ levelId }) => {
    const { data: levelData, isLoading, error } = useAuthenticatedData(
        levelId ? `https://localhost:5001/mos/levels/${levelId}` : null
      );

  if (!levelId) return <Typography>Please select a level to view details.</Typography>;
  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">Error loading level details: {error.message}</Typography>;
  if (!levelData) return <Typography>No data available for this level.</Typography>;


  const { name, description, metrics, kpis, goals } = levelData;

  return (
    <Box>
      <Typography variant="h4" gutterBottom>{name}</Typography>
      <Typography variant="subtitle1" gutterBottom>{description}</Typography>

      <StyledPaper>
        <Typography variant="h6" gutterBottom>Metrics</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Health</TableCell>
                <TableCell>Trend</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {metrics.map((metric) => (
                <TableRow key={metric.id}>
                  <TableCell>{metric.name}</TableCell>
                  <TableCell>{metric.value}</TableCell>
                  <TableCell><HealthIndicator value={metric.health} /></TableCell>
                  <TableCell><TrendIcon trend={metric.trend} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPaper>

      <StyledPaper>
        <Typography variant="h6" gutterBottom>KPIs</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Target</TableCell>
                <TableCell>Actual</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {kpis.map((kpi) => (
                <TableRow key={kpi.id}>
                  <TableCell>{kpi.name}</TableCell>
                  <TableCell>{kpi.target}</TableCell>
                  <TableCell>{kpi.actual}</TableCell>
                  <TableCell><StatusChip status={kpi.status} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPaper>

      <StyledPaper>
        <Typography variant="h6" gutterBottom>Goals</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Progress</TableCell>
                <TableCell>Due Date</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {goals.map((goal) => (
                <TableRow key={goal.id}>
                  <TableCell>{goal.name}</TableCell>
                  <TableCell>
                    <LinearProgress 
                      variant="determinate" 
                      value={goal.progress} 
                      color={goal.progress >= 70 ? 'success' : goal.progress >= 40 ? 'warning' : 'error'}
                    />
                  </TableCell>
                  <TableCell>{new Date(goal.dueDate).toLocaleDateString()}</TableCell>
                  <TableCell><StatusChip status={goal.status} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPaper>

      <StyledPaper>
        <Typography variant="h6" gutterBottom>Quick Analytics</Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Tooltip title="View Trend Analysis">
              <IconButton size="large">
                <Timeline fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <Tooltip title="View Performance Breakdown">
              <IconButton size="large">
                <BarChart fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <Tooltip title="View Resource Allocation">
              <IconButton size="large">
                <PieChart fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>
  );
};

export default LevelDetail;