// SalespersonCustomerSelect.js
import React, { useState, useEffect } from 'react';
import { TextField, CircularProgress, Autocomplete, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { GET_SALESPEOPLE_QUERY, GET_SALESPERSON_BY_CCODE_QUERY } from '../../../queries';

// Static options that appear at the top
const STATIC_OPTIONS = [
  { id: 'all', label: 'All Customers', type: 'static' },
  { id: 'assigned', label: 'Assigned Accounts', type: 'static' },
  { id: 'key', label: 'Key Customers', type: 'static' },
  { id: 'nonkey', label: 'Non-Key Customers', type: 'static' }
];


const SalespersonCustomerSelect = ({ onSalespersonSelect, defaultSalespersonCode, userAssignedCustomers = [] }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedSalesperson, setSelectedSalesperson] = useState(null);
  const [options, setOptions] = useState([]);

  // Fetch all salespeople
  const { data: allData, loading: allLoading, error: allError } = useQuery(GET_SALESPEOPLE_QUERY);

  // Fetch default salesperson by code if provided
  const { data: defaultData, loading: defaultLoading, error: defaultError } = useQuery(
    GET_SALESPERSON_BY_CCODE_QUERY,
    {
      variables: { code: defaultSalespersonCode },
      skip: !defaultSalespersonCode,
    }
  );

  // Handle errors
  useEffect(() => {
    if (allError) console.error('Error fetching all salespeople:', allError);
    if (defaultError) console.error('Error fetching default salesperson:', defaultError);
  }, [allError, defaultError]);

  // Set options once all salespeople are fetched
  useEffect(() => {
    if (allData?.salespeople?.items) {
      setOptions(allData.salespeople.items);
    }
  }, [allData]);

  // Handle default salesperson selection
  useEffect(() => {
    if (
      defaultSalespersonCode &&
      defaultData?.salespeople?.items?.length > 0 &&
      !selectedSalesperson
    ) {
      const foundSalesperson = defaultData.salespeople.items[0];
      setSelectedSalesperson(foundSalesperson);
      setInputValue(foundSalesperson.fullName);
      onSalespersonSelect(foundSalesperson);
      setOptions((prevOptions) => {
        if (!prevOptions.some((s) => s.cCode === foundSalesperson.cCode)) {
          return [foundSalesperson, ...prevOptions];
        }
        return prevOptions;
      });
    }
  }, [defaultSalespersonCode, defaultData, onSalespersonSelect, selectedSalesperson]);

  const handleInputChange = (event, value) => {
    setInputValue(value);
    // No need for debounce since all data is pre-fetched
  };

  const handleSalespersonSelect = (event, value) => {
    setSelectedSalesperson(value);
    if (value) {
      setInputValue(value.fullName);
      onSalespersonSelect(value);
    } else {
      setInputValue('');
      onSalespersonSelect(null);
    }
  };

  return (
    <>
      {(allError || defaultError) && (
        <Typography sx={{}} color="error">
          {allError?.message || defaultError?.message || 'An error occurred while fetching salespeople.'}
        </Typography>
      )}
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <InputLabel htmlFor="grouped-native-select">Filter by Customers</InputLabel>
        <Select native defaultValue="" id="grouped-native-select" label="Filter by Customers">
          <option aria-label="None" value="" />
          
          <optgroup label="Groups">
            <option value={1}>All Customers</option>
            <option value={1}>Assigned Accounts</option>
            <option value={2}>Key Customers</option>
            <option value={2}>Non-Key Customers</option>
            <option value=""> </option>
          </optgroup>

          <optgroup label="Individual Accounts">
            {userAssignedCustomers.map(account => (
              <option key={account} value={account}>
                {account}
              </option>
            ))}
          </optgroup>

        </Select>
      </FormControl>

    </>
  );
};

export default SalespersonCustomerSelect;
