// components/CalendarMasterDetail/MiniCalendar.jsx

import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import DayCell from './DayCell';
import './MiniCalendar.module.css';

// Utility function to darken colors
const getDarkerShade = (color, percent = 20) => {
  let hex = color.replace(/^#/, '');

  if (hex.length === 3) {
    hex = hex.split('').map(char => char + char).join('');
  }

  const num = parseInt(hex, 16);
  let r = (num >> 16) & 0xFF;
  let g = (num >> 8) & 0xFF;
  let b = num & 0xFF;

  r = Math.max(0, Math.floor(r * (1 - percent / 100)));
  g = Math.max(0, Math.floor(g * (1 - percent / 100)));
  b = Math.max(0, Math.floor(b * (1 - percent / 100)));

  const newHex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');

  return `#${newHex.toUpperCase()}`;
};

const MiniCalendar = ({
  monthDate,
  dataMap,
  selectedDate,
  isSelectedMonth,
  onDaySelect,
  calendarWidth,
  cellSize,
  fontSize,
  activities,

}) => {
  const monthStart = new Date(monthDate.getFullYear(), monthDate.getMonth(), 1);
  const monthEnd = new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 0);
  const daysInMonth = monthEnd.getDate();

  const days = [];
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(new Date(monthDate.getFullYear(), monthDate.getMonth(), i));
  }

  const today = new Date();

  const isSameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  const hasActivityData = (activity, dayData) => {
    if (!activity) return false;
    const { dataFields } = activity;
    const { docCount, lineCount, total } = dataFields;
    const currentDocCount = dayData[docCount] || 0;
    const currentLineCount = dayData[lineCount] || 0;
    const currentTotal = dayData[total] || 0;

    return (docCount && currentDocCount > 0) ||
      (lineCount && currentLineCount > 0) ||
      (total && currentTotal > 0);
  };

  const renderDay = (day) => {
    const dayString = day.toISOString().split('T')[0];
    const dayData = dataMap.get(dayString) || {};
    const isSelected = selectedDate && isSameDay(day, selectedDate);
    const isToday = isSameDay(day, today);

    return (
      <Box
        key={day.toISOString()}
        onClick={() => onDaySelect(day)}
        sx={{
          //   width: cellSize,
          /*
             height: cellSize,
             display: 'flex',
             flexDirection: 'column',
             position: 'relative',
             border: isSelected ? '1px solid #FFFFFF' : '1px solid #424242',
             cursor: 'pointer',
             transition: 'border 0.3s',
             outline: isToday ? '1px solid rgba(255, 215, 0, 0.6)' : 'none',
             borderRadius: isToday ? '2px' : '0px',
             */

          height: cellSize,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          border: isSelected ? '1px solid DimGray' : '1px solid DimGray',
          cursor: 'pointer',
          transition: 'border 0.3s',
          outline: isToday ? '1px solid rgba(255, 215, 0, 0.6)' : 'none',
          borderRadius: isToday ? '2px' : '0px',
        }}
      >



        <Box
          key={day.toISOString()}
          onClick={() => onDaySelect(day)}
          sx={{
            height: cellSize,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            border: isSelected ? '1px solid #FFFFFF' : '1px solid #424242',
            cursor: 'pointer',
            transition: 'border 0.3s',
            outline: isToday ? '1px solid rgba(255, 215, 0, 0.6)' : 'none',
            borderRadius: isToday ? '2px' : '0px',
          }}
        >
          {activities.length > 0 && (

            <Box
              sx={{
                flex: 1,
                backgroundColor: activities[0]?.color && hasActivityData(activities[0], dayData) ? activities[0].color : 'transparent',
                border: activities[0]?.color && hasActivityData(activities[0], dayData) ? `1px solid ${getDarkerShade(activities[0].color)}` : 'transparent',
                transition: 'background-color 0.3s, border 0.3s',
              }}
            />
          )}

          {activities.length > 1 && (
            <Box
              sx={{
                flex: 1,
                backgroundColor: activities[1]?.color && hasActivityData(activities[1], dayData) ? activities[1].color : 'transparent',
                border: activities[1]?.color && hasActivityData(activities[1], dayData) ? `1px solid ${getDarkerShade(activities[1].color)}` : 'transparent',
                transition: 'background-color 0.3s, border 0.3s',
              }}
            />
          )}
          {activities.length > 2 && (
            <Box
              sx={{
                flex: 1,
                backgroundColor: activities[2]?.color && hasActivityData(activities[2], dayData) ? activities[2].color : 'transparent',
                border: activities[2]?.color && hasActivityData(activities[2], dayData) ? `1px solid ${getDarkerShade(activities[2].color)}` : 'transparent',
                transition: 'background-color 0.3s, border 0.3s',
              }}
            />
          )}
          {activities.length > 3 && (
            <Box
              sx={{
                flex: 1,
                backgroundColor: activities[3]?.color && hasActivityData(activities[3], dayData) ? activities[3].color : 'transparent',
                border: activities[3]?.color && hasActivityData(activities[3], dayData) ? `1px solid ${getDarkerShade(activities[3].color)}` : 'transparent',
                transition: 'background-color 0.3s, border 0.3s',
              }}
            />
          )}

        </Box>



        {/* Date Number */}
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '.6rem',
            color: '#a1a0a0',
            fontWeight: 'bold',
            textShadow: '1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000',
          }}
        >
          {day.getDate()}
        </Box>
      </Box>
    );
  };

  const firstDayWeekday = monthStart.getDay();

  return (
    <Box
      className="mini-calendar-container-x"
      sx={{
        width: calendarWidth,
        border: isSelectedMonth ? '2px solid #1976d2' : 'none',
        borderRadius: isSelectedMonth ? '8px' : '0',
          padding: '2px',
        boxSizing: 'border-box',
      }}
    >
      <Typography align="center" gutterBottom sx={{ color: '#FFFFFF', fontSize: '.8rem' }}>
        {monthDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
      </Typography>

      <Grid container columns={7}>
        {/* Weekday headers */}
        {/* Weekday headers */}
        {['S', 'M', 'T', 'W', 'Th', 'F', 'S'].map((day) => (
          <Grid item xs={1} key={day} className="dayHeader" style={{fontSize: '.6rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="caption" sx={{ textAlign: 'center', fontSize: '.6rem' }}>
              {day}
            </Typography>
          </Grid>
        ))}
        {/* Empty cells before the first day */}
        {Array.from({ length: firstDayWeekday }).map((_, index) => (
          <Grid item xs={1} key={`empty-${index}`}>
            <Box sx={{ width: cellSize, height: cellSize, backgroundColor: 'transparent' }} />
          </Grid>
        ))}
        {/* Days of the month */}
        {days.map((day) => (
          <Grid item xs={1} key={day.toISOString()}>
            {renderDay(day)}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

MiniCalendar.propTypes = {
  monthDate: PropTypes.instanceOf(Date).isRequired,
  dataMap: PropTypes.instanceOf(Map).isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  isSelectedMonth: PropTypes.bool,
  onDaySelect: PropTypes.func.isRequired,
  calendarWidth: PropTypes.number.isRequired,
  cellSize: PropTypes.number.isRequired,
  fontSize: PropTypes.string.isRequired,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      dataFields: PropTypes.shape({
        docCount: PropTypes.string,
        lineCount: PropTypes.string,
        total: PropTypes.string,
      }),
      minimal: PropTypes.bool,
    })
  ).isRequired,
};

MiniCalendar.defaultProps = {
  selectedDate: null,
  isSelectedMonth: false,
};

export default MiniCalendar;
