import React from 'react';

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarQuickFilter,
    GridToolbarExport,
} from '@mui/x-data-grid'; import { Box, Typography, CircularProgress } from '@mui/material';

import useAuthenticatedData from '../hooks/useAuthenticatedData';

function CustomToolbar() {
    return (
        <GridToolbarContainer sx={{ backgroundColor: 'black', p: 1 }}>
            <GridToolbarColumnsButton sx={{ color: 'white' }} />
            <GridToolbarFilterButton sx={{ color: 'white' }} />
            <GridToolbarDensitySelector sx={{ color: 'white' }} />
            <GridToolbarQuickFilter
                sx={{
                    color: 'white',
                    '& .MuiInputBase-root': {
                        color: 'white',
                        '& .MuiInputBase-input::placeholder': {
                            color: 'rgba(255, 255, 255, 0.7)',
                        },
                    },
                    '& .MuiIconButton-root': {
                        color: 'white',
                    },
                }}
            />
            <GridToolbarExport sx={{ color: 'white' }} />
        </GridToolbarContainer>
    );
}

const MetricValuesGrid = ({ metricId }) => {
    const { data: metricValues, isLoading, error } = useAuthenticatedData(`https://localhost:5001/mos/metrics/${metricId}/values`);

    const columns = [
        {
            field: 'metricForDate',
            headerName: 'Date',
            width: 200,
            valueGetter: (params) => {
                const date = new Date(params);
                return date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                });
            },
        },
        {
            field: 'value',
            headerName: 'Value',
            width: 150,
            type: 'number'
        },
        {
            field: 'average',
            headerName: 'Average',
            width: 150,
            type: 'number'
        }
    ];

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 2, height: '400px' }}>
                <Typography color="error">Error loading metric values: {error.message}</Typography>
            </Box>
        );
    }

    if (!metricValues || metricValues.length === 0) {
        return (
            <Box sx={{ p: 2, height: '400px' }}>
                <Typography>No historical data available for this metric.</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ height: '400px', width: '100%' }}>
            <DataGrid
                rows={metricValues}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                disableSelectionOnClick
                slots={{
                    toolbar: CustomToolbar,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: 'black',
                        color: 'white',
                        fontSize: '0.875rem',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        letterSpacing: '0.1em',
                        fontFamily: "'Roboto Condensed', sans-serif",
                    },
                    '& .MuiDataGrid-row:nth-of-type(even)': {
                        backgroundColor: '#262626',
                    },
                    '& .MuiDataGrid-row:nth-of-type(odd)': {
                        backgroundColor: '#333333',
                    },
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                        '& button': {
                            color: 'white',
                        },
                        '& .MuiInputBase-root': {
                            color: 'white',
                        },
                    },
                }}
            />
        </Box>
    );
};

export default MetricValuesGrid;