// src/components/ios/repairs/TopVendors.js
import React from 'react';
import { Box, Typography, Link, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import useAuthenticatedData from '../../../hooks/useAuthenticatedData';

const columns = [
    { field: 'vendorName', headerName: 'Vendor Name', width: 200 },
    { field: 'totalRepairSpend', headerName: 'Total Repair Spend', width: 200 },
    ];

const TopVendors = () => {
  const { data, isLoading, error } = useAuthenticatedData('https://localhost:5001/ios/repairs/top-vendors');

  if (!data || isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Top Vendors</Typography>
        <Link href="#" underline="hover">vendors</Link>
      </Box>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default TopVendors;