import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import useAuthenticatedData from '../../hooks/useAuthenticatedData';
import UsersGrid from './UsersGrid';

const Users = () => {
    const navigate = useNavigate();

    const { data: users, isLoading, error } = useAuthenticatedData('https://localhost:5001/admin/users');

    const columns = [
        { field: 'firstName', headerName: 'First Name', width: 130 },
        { field: 'lastName', headerName: 'Last Name', width: 130 },
        { field: 'email', headerName: 'Email', width: 250 },
        { field: 'windowsLogin', headerName: 'Windows Login', width: 200 },
        { field: 'pentagonCCode', headerName: 'Pentagon User Code', width: 150 },
    ];

    const rowClick = (params) => {
        navigate(`/users/${params.id}`);
    }

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography color="error">Error: {error.message}</Typography>
            </Box>
        );
    }

    if (!users || users.length === 0) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography>No users data available.</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Users
            </Typography>
           <UsersGrid users={users} onRowClick={rowClick} />
        </Box>
    );
};

export default Users;