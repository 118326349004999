// MonthPerformanceDetails.jsx
import React, { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import { AgGridReact } from 'ag-grid-react';
import { Button, Box, Typography, CircularProgress, Alert } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

// GraphQL Queries
const GET_CUSTOMER_SALES_ORDER_INVOICES_FOR_MONTH = gql`
  query CustomerSalesOrderInvoicesForMonth($acctNo: String!, $year: Int!, $month: Int!) {
    customerSalesOrderInvoicesForMonth(acctNo: $acctNo, year: $year, month: $month) {
      id
      docNo
      unitPrice
      qtyShipped
      lineTotal
      finalPrice
      partnumber
      condition
      description
      serialNumber
    }
  }
`;

const GET_CUSTOMER_OPEN_SALES_ORDERS_FOR_MONTH = gql`
  query CustomerOpenSalesOrdersForMonth($acctNo: String!, $year: Int!, $month: Int!) {
    customerOpenSalesOrdersForMonth(acctNo: $acctNo, year: $year, month: $month) {
      id
      docNo
      unitPrice
      qtyShipped
      estShipDate
      qtyOrdered
      lineTotal            
      partnumber
      condition
      description
      serialNumber
    }
  }
`;

const MonthPerformanceDetails = ({ month, year, onBack, accountNumber }) => {
  // Convert month name to month number
  const monthNumber = useMemo(() => {
    const date = new Date(`${month} 1, ${year}`);
    return date.getMonth() + 1;
  }, [month, year]);

  // Fetch Invoices Data
  const {
    data: invoicesData,
    loading: loadingInvoices,
    error: errorInvoices,
  } = useQuery(GET_CUSTOMER_SALES_ORDER_INVOICES_FOR_MONTH, {
    variables: {
      acctNo: accountNumber,
      year: year,
      month: monthNumber,
    },
    fetchPolicy: 'network-only',
  });

  // Fetch Open Sales Data
  const {
    data: openSalesData,
    loading: loadingOpenSales,
    error: errorOpenSales,
  } = useQuery(GET_CUSTOMER_OPEN_SALES_ORDERS_FOR_MONTH, {
    variables: {
      acctNo: accountNumber,
      year: year,
      month: monthNumber,
    },
    fetchPolicy: 'network-only',
  });

  // Define column definitions based on your data structure
  const invoiceColumnDefs = useMemo(() => [
    { 
      headerName: 'Pentagon Doc', 
      field: 'docNo', 
      sortable: true, 
      filter: true,
      ellDataType: 'text'
    },
    { 
      headerName: 'Unit Price', 
      field: 'unitPrice', 
      sortable: true, 
      filter: true, 
      valueFormatter: params => params.value != null ? `$${params.value.toLocaleString()}` : '',
      aggFunc: null, // Prevent aggregation
      enableValue: false, // Prevent value aggregation
      enableRowGroup: false, // Prevent row grouping
    },
    { 
      headerName: 'Shipped', 
      field: 'qtyShipped', 
      sortable: true, 
      filter: true,
      aggFunc: 'sum', // Enable summation
      valueParser: params => Number(params.value),
      cellClassRules: {
        'ag-number-cell': params => typeof params.value === 'number',
      },
    },
    { 
      headerName: 'Total', 
      field: 'lineTotal', 
      sortable: true, 
      filter: true, 
      valueFormatter: params => params.value != null ? `$${params.value.toLocaleString()}` : '',
      aggFunc: 'sum', // Enable summation
      valueParser: params => Number(params.value),
      cellClassRules: {
        'ag-number-cell': params => typeof params.value === 'number',
      },
    },
    { headerName: 'Part Number', field: 'partnumber', sortable: true, filter: true },
    { headerName: 'Condition', field: 'condition', sortable: true, filter: true },
    { headerName: 'Serial Number', field: 'serialNumber', sortable: true, filter: true },
    { headerName: 'Description', field: 'description', sortable: true, filter: true },
  ], []);

  const openSalesColumnDefs = useMemo(() => [
    { 
      headerName: 'Ship Date', 
      field: 'estShipDate', 
      sortable: true, 
      filter: true,
      cellDataType: 'date',
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(params.value);
          return date.toLocaleDateString('en-US', { 
            month: '2-digit', 
            day: '2-digit', 
            year: 'numeric' 
          });
        }
        return '';
      }
    },
    { 
      headerName: 'Pentagon Doc', 
      field: 'docNo', 
      sortable: true, 
      filter: true,
      ellDataType: 'text'
    },
    { 
      headerName: 'Unit Price', 
      field: 'unitPrice', 
      sortable: true, 
      filter: true, 
      valueFormatter: params => params.value != null ? `$${params.value.toLocaleString()}` : '',
      aggFunc: null, // Prevent aggregation
      enableValue: false, // Prevent value aggregation
      enableRowGroup: false, // Prevent row grouping
    },
    { 
      headerName: 'Ordered', 
      field: 'qtyOrdered', 
      sortable: true, 
      filter: true,
      aggFunc: 'sum', // Enable summation
      valueParser: params => Number(params.value),
      cellClassRules: {
        'ag-number-cell': params => typeof params.value === 'number',
      },
    },
    { 
      headerName: 'Shipped', 
      field: 'qtyShipped', 
      sortable: true, 
      filter: true,
      aggFunc: 'sum', // Enable summation
      valueParser: params => Number(params.value),
      cellClassRules: {
        'ag-number-cell': params => typeof params.value === 'number',
      },
    },
    { 
      headerName: 'Total', 
      field: 'lineTotal', 
      sortable: true, 
      filter: true, 
      valueFormatter: params => params.value != null ? `$${params.value.toLocaleString()}` : '',
      aggFunc: 'sum', // Enable summation
      valueParser: params => Number(params.value),
      cellClassRules: {
        'ag-number-cell': params => typeof params.value === 'number',
      },
    },
    { headerName: 'Part Number', field: 'partnumber', sortable: true, filter: true },
    { headerName: 'Condition', field: 'condition', sortable: true, filter: true },
    { headerName: 'Serial Number', field: 'serialNumber', sortable: true, filter: true },
    { headerName: 'Description', field: 'description', sortable: true, filter: true },
  ], []);

  // Calculate totals for 'qtyShipped' and 'lineTotal'
  const totals = useMemo(() => {
    if (!invoicesData?.customerSalesOrderInvoicesForMonth) {
      return { qtyShipped: 0, lineTotal: 0 };
    }

    const totalQtyShipped = invoicesData.customerSalesOrderInvoicesForMonth.reduce(
      (acc, curr) => acc + (curr?.qtyShipped || 0),
      0
    );
    const totalLineTotal = invoicesData.customerSalesOrderInvoicesForMonth.reduce(
      (acc, curr) => acc + (curr?.lineTotal || 0),
      0
    );

    return {
      qtyShipped: totalQtyShipped,
      lineTotal: totalLineTotal,
    };
  }, [invoicesData]);

  // Define pinned bottom row with totals
  const pinnedBottomRowData = useMemo(() => [{
    docNo: null,
    unitPrice: null, // Set to null to prevent aggregation
    qtyShipped: totals.qtyShipped,
    lineTotal: totals.lineTotal,
    partnumber: null,
    condition: null,
    serialNumber: null,
    description: null,
  }], [totals]);

  return (
    <Box sx={{ padding: 2 }}>
      <Button variant="contained" onClick={onBack} sx={{ marginBottom: 2 }}>
        Back to Chart
      </Button>
      <Typography variant="h6" gutterBottom>
        Details for {new Date(`${month} 1, ${year}`).toLocaleString('default', { month: 'long' })}, {year}
      </Typography>

      {/* Invoices Table */}
      <Typography variant="subtitle1" gutterBottom>
        Invoices
      </Typography>
      {loadingInvoices ? (
        <CircularProgress />
      ) : errorInvoices ? (
        <Alert severity="error">Error loading invoices: {errorInvoices.message}</Alert>
      ) : (
        <div
          className="ag-theme-alpine"
          style={{ height: 400, width: '100%', marginBottom: '2rem' }}
        >
          <AgGridReact
            rowData={invoicesData.customerSalesOrderInvoicesForMonth}
            columnDefs={invoiceColumnDefs}
            pagination={true}
            paginationPageSize={10}
            defaultColDef={{
              flex: 1,
              minWidth: 100,
              filter: true,
              sortable: true,
              resizable: true,
            }}
            pinnedBottomRowData={pinnedBottomRowData}
            // Customize the pinned row style
            getRowStyle={(params) => {
              if (params.node.rowPinned) {
                return { fontWeight: 'bold', backgroundColor: '#f5f5f5' };
              }
              return null;
            }}
          />
        </div>
      )}

      {/* Open Sales Table */}
      <Typography variant="subtitle1" gutterBottom>
        Open Sales
      </Typography>
      {loadingOpenSales ? (
        <CircularProgress />
      ) : errorOpenSales ? (
        <Alert severity="error">Error loading open sales: {errorOpenSales.message}</Alert>
      ) : (
        <div
          className="ag-theme-alpine"
          style={{ height: 400, width: '100%' }}
        >
          <AgGridReact
            rowData={openSalesData.customerOpenSalesOrdersForMonth} 
            columnDefs={openSalesColumnDefs}
            pagination={true}
            paginationPageSize={10}
            defaultColDef={{
              flex: 1,
              minWidth: 100,
              filter: true,
              sortable: true,
              resizable: true,
            }}
          />
        </div>
      )}
    </Box>
  );
};

export default MonthPerformanceDetails;
