import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Box, TextField, Typography, Grid, Snackbar, Card, CardContent, Divider } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import useAuthenticatedData from '../../hooks/useAuthenticatedData';
import useAuthenticatedPut from '../../hooks/useAuthenticatedPut';
import MetricShuttle from '../metric/MetricShuttle';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UserEdit = () => {
  const { userId } = useParams();
  const { data: user, isLoading, error } = useAuthenticatedData(`https://localhost:5001/admin/users/${userId}`);
  const [userDto, setUserDto] = useState({
    firstName: '',
    lastName: '',
    email: '',
    windowsLogin: '',
    pentagonLogin: ''
  });
  const { put, isLoading: isSubmitting, error: submitError } = useAuthenticatedPut();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [selectedCompanyLevel, setSelectedCompanyLevel] = useState(null);
  
  const { data: assignedMetrics, refetch: refetchAssigned } = useAuthenticatedData(
    `https://localhost:5001/admin/users/${userId}/metrics${selectedCompanyLevel ? `?companyLevelId=${selectedCompanyLevel.id}` : ''}`
  );
  
  const { data: unassignedMetrics, refetch: refetchUnassigned } = useAuthenticatedData(
    `https://localhost:5001/admin/users/${userId}/available-metrics${selectedCompanyLevel ? `?companyLevelId=${selectedCompanyLevel.id}` : ''}`
  );

  useEffect(() => {
    if (user) {
      setUserDto({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        windowsLogin: user.windowsLogin,
        pentagonLogin: user.pentagonLogin
      });
    }
  }, [user]);

  const handleCompanyLevelSelect = useCallback((selectedLevel) => {
    setSelectedCompanyLevel(selectedLevel);
    refetchAssigned();
    refetchUnassigned();
  }, [refetchAssigned, refetchUnassigned]);

  const updateUser = useCallback((updatedUserDto) => {
    put(`https://localhost:5001/admin/users/${userId}`, updatedUserDto)
      .then(response => {
        console.log('User updated successfully');
        setUserDto(response);
        setOpenSnackbar(true);
      })
      .catch(error => {
        console.error('Error updating user:', error);
      });
  }, [userId, put]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDto(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleMetricsChange = useCallback(async (newAssigned, newUnassigned) => {
    try {
      await put(`https://localhost:5001/admin/users/${userId}/metrics`, {
        assignedMetricIds: newAssigned.map(metric => metric.id)
      });
      setOpenSnackbar(true);
      refetchAssigned();
      refetchUnassigned();
    } catch (error) {
      console.error('Error updating metrics:', error);
    }
  }, [userId, put, refetchAssigned, refetchUnassigned]);

  const handleInputBlur = () => {
    updateUser(userDto);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  if (isLoading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">Error loading data</Typography>;

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Editing User
      </Typography>
      <Card elevation={3} sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            User Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={userDto.firstName}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={userDto.lastName}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={userDto.email}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Windows Login"
                name="windowsLogin"
                value={userDto.windowsLogin}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Pentagon Login"
                name="pentagonLogin"
                value={userDto.pentagonLogin}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                margin="normal"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Typography variant="h5" gutterBottom sx={{ mt: 4, mb: 2 }}>
        Assignments
      </Typography>
      <Divider sx={{ mb: 3 }} />

      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Metrics
              </Typography>
              <MetricShuttle
                assignedMetrics={assignedMetrics || []}
                unassignedMetrics={unassignedMetrics || []}
                onMetricsChange={handleMetricsChange}
                onCompanyLevelSelect={handleCompanyLevelSelect}
                selectedCompanyLevel={selectedCompanyLevel}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          User updated successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserEdit;