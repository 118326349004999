import React, { useEffect } from 'react';
import { Box, Grid, Typography, Paper } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_SALESPERSON_PROFILE } from '../../../queries';
import './SalespersonProfile.module.css';
import AssignedAccountsGrid from './AssignedAccountsGrid';

const InfoItem = ({ label, value }) => (
    <Box className="info-item">
        <Typography className="info-label">{label}:</Typography>
        <Typography className="info-value">{value}</Typography>
    </Box>
);

const Section = ({ title, children }) => (
    <Paper className="section">
        <Typography className="section-title">{title}</Typography>
        <Box className="section-content">
            {children}
        </Box>
    </Paper>
);

const formatCurrency = (amount) => {
    if (amount === null || amount === undefined) return 'N/A';
    if (amount === 0) return '$0';
    const absAmount = Math.abs(amount).toLocaleString();
    return amount < 0 ? `-$${absAmount}` : `$${absAmount}`;
  };



const SalespersonProficiency = ({ selectedSalesperson }) => {
    const { data, loading, error } = useQuery(GET_SALESPERSON_PROFILE, {
        variables: { ccode: selectedSalesperson?.cCode || '' },
        skip: !selectedSalesperson,
    });

    useEffect(() => {
        if (error) {
            console.error('Error fetching customer profile:', error);
        }
    }, [error]);

    if (loading) return <Typography sx={{ p: 3 }}>Loading...</Typography>;
    if (error) return <Typography sx={{ p: 3 }}>Error: {error.message}</Typography>;
    if (!data) return <Typography sx={{ p: 3 }}>No data available</Typography>;

    const profile = data.salespersonProfileByCcode;

    return (
        <Box className="customer-profile">
            <Grid container spacing={2} className="grid-container">
                {/* Top row */}
                <Grid item xs={12} md={6} className="grid-item">
                    {/* Status Section */}
                    <Section title="Quote to Close">
                        <Grid container spacing={2} className="nested-grid-container">
                            <Grid  sx={{height:'200px'}} item xs={12} sm={12} className="nested-grid-item">
                   

                            </Grid>
                          
                        </Grid>
                    </Section>
                </Grid>

                <Grid item xs={12} md={6} className="grid-item">
                    {/* Financials Section */}
                    <Section title="Sales vs. Sell Price">
                        <Grid container spacing={2} className="nested-grid-container">
                            <Grid item xs={12} className="nested-grid-item">
                               
                            </Grid>
                        </Grid>
                    </Section>
                </Grid>

                {/* Bottom row */}
                <Grid item xs={12} md={6} className="grid-item">
                    {/* Fleet Section */}
                    <Section title="Sales by Age">
                        <Grid  sx={{height:'200px'}} container spacing={2} className="nested-grid-container">
                            <Grid item xs={12} className="nested-grid-item">
      

                            </Grid>
                        </Grid>
                    </Section>
                </Grid>

                <Grid item xs={12} md={6} className="grid-item">
                    {/* Memo/Alert Notes Section */}
                    <Section title="Sales by ATA">
                       


                        <Grid container spacing={2} className="nested-grid-container">
                            <Grid item xs={12} className="nested-grid-item">
                    

                            </Grid>
                        </Grid>


                    </Section>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SalespersonProficiency;