import React, { useMemo } from 'react';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { styled, alpha } from '@mui/material/styles';
import { Box, Typography, Collapse } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { animated, useSpring } from '@react-spring/web';
import { treeItemClasses } from '@mui/x-tree-view/TreeItem';
import clsx from 'clsx';
import { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view/useTreeItem2';
import {
    TreeItem2Content,
    TreeItem2IconContainer,
    TreeItem2Label,
    TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import useAuthenticatedData from '../../hooks/useAuthenticatedData'; // Adjust the import path as needed


function DotIcon() {
    return (
        <Box
            sx={{
                width: 6,
                height: 6,
                borderRadius: '70%',
                bgcolor: 'warning.main',
                display: 'inline-block',
                verticalAlign: 'middle',
                zIndex: 1,
                mx: 1,
            }}
        />
    );
}

const StyledTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
    color:
        theme.palette.mode === 'light'
            ? theme.palette.grey[800]
            : theme.palette.grey[400],
    position: 'relative',
    [`& .${treeItemClasses.groupTransition}`]: {
        marginLeft: theme.spacing(3.5),
    },
}));

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
    flexDirection: 'row',
    borderRadius: theme.spacing(0.7),
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    fontWeight: 500,
    [`&.Mui-expanded `]: {
        '&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.primary.main
                    : theme.palette.primary.dark,
        },
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: '16px',
            top: '44px',
            height: 'calc(100% - 48px)',
            width: '1.5px',
            backgroundColor:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[300]
                    : theme.palette.grey[700],
        },
    },
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        color: theme.palette.mode === 'light' ? theme.palette.primary.main : 'white',
    },
    [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
        backgroundColor:
            theme.palette.mode === 'light'
                ? theme.palette.primary.main
                : theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
    },
}));

const AnimatedCollapse = animated(Collapse);

function TransitionComponent(props) {
    const style = useSpring({
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
        },
    });

    return <AnimatedCollapse style={style} {...props} />;
}

const StyledTreeItemLabelText = styled(Typography)({
    color: 'inherit',
    fontFamily: 'General Sans',
    fontWeight: 500,
});

function CustomLabel({ children, ...other }) {
    return (
        <TreeItem2Label
            {...other}
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
            }}
        >
            {children}
        </TreeItem2Label>
    );
}

const isExpandable = (reactChildren) => {
    if (Array.isArray(reactChildren)) {
        return reactChildren.length > 0 && reactChildren.some(isExpandable);
    }
    return Boolean(reactChildren);
};




const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const { id, itemId, label, disabled, children, onItemSelect, ...other } = props;

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
        publicAPI,
    } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

    const item = publicAPI.getItem(itemId);
    const expandable = isExpandable(children);

    const handleItemClick  = (event) => {
        event.stopPropagation();
        // Handle the detail click here, e.g., navigate to a detail page
        onItemSelect(item);
    };

    return (
        <TreeItem2Provider itemId={itemId}>
            <StyledTreeItemRoot {...getRootProps(other)} onClick={handleItemClick}>
                <CustomTreeItemContent
                    {...getContentProps({
                        className: clsx('content', {
                            'Mui-expanded': status.expanded,
                            'Mui-selected': status.selected,
                            'Mui-focused': status.focused,
                            'Mui-disabled': status.disabled,
                        }),
                    })}
                >
                    <TreeItem2IconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </TreeItem2IconContainer>
                    <CustomLabel {...getLabelProps()}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <Typography variant="body2">{item.label}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {item.healthyKPIs !== undefined && (
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            bgcolor: item.healthyKPIs / item.totalKPIs > 0.6 ? 'success.main' : 'warning.main',
                                            color: 'white',
                                            borderRadius: '4px',
                                            px: 1,
                                            mr: 1,
                                        }}
                                    >
                                        {`${item.healthyKPIs}/${item.totalKPIs}`}
                                    </Typography>
                                )}
                                <ChevronRightIcon
                                     
                                    sx={{ cursor: 'pointer', fontSize: '1.2rem' }}
                                />
                            </Box>
                        </Box>
                    </CustomLabel>
                </CustomTreeItemContent>
                {children && <TransitionComponent {...getGroupTransitionProps()} />}
            </StyledTreeItemRoot>
        </TreeItem2Provider>
    );
});

export default function CompanyStack({ onItemSelect }) {
    const { data, isLoading, error } = useAuthenticatedData('https://localhost:5001/mos/company-levels/');

    const treeItems = useMemo(() => {
        if (!data || !Array.isArray(data) || data.length === 0) return [];

        const convertToTreeItems = (levels) => {
            return levels.map(level => ({
                id: level.id.toString(),
                label: level.name,
                description: level.description,
                levelType: level.levelType,
                depth: level.depth,
                // You might need to adjust these if you don't have KPI data in your current response
                healthyKPIs: 0,
                totalKPIs: 0,
                children: level.childLevels ? convertToTreeItems(level.childLevels) : null
            }));
        };

        return convertToTreeItems(data);
    }, [data]);

    const handleSelect = (event, itemIds) => {
        if (!itemIds || itemIds.length === 0) return;

        const findItem = (items, id) => {
            for (let item of items) {
                if (item.id === id) return item;
                if (item.children) {
                    const found = findItem(item.children, id);
                    if (found) return found;
                }
            }
            return null;
        };

        const selectedItem = findItem(treeItems, itemIds[0]);
        if (selectedItem) {
            onItemSelect(selectedItem);
        }
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <RichTreeView
            items={treeItems}
            defaultExpandedItems={[treeItems[0]?.id]}
            defaultSelectedItems={[treeItems[0]?.id]}
            sx={{ height: 'fit-content', flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
            slots={{ item: CustomTreeItem }}
            onSelectedItemsChange={handleSelect}
            slotProps={{
                item: {
                    onItemSelect,
                },
            }}
        />
    );
}