import React, { useState, useEffect, useMemo } from 'react';
import { TextField, CircularProgress, Autocomplete, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import debounce from 'lodash.debounce';
import { useLocation } from 'react-router-dom';

import { GET_CUSTOMERS_QUERY, GET_CUSTOMERS_BY_ACCTNO_QUERY } from '../../queries';

const CustomerSelect = ({ onCustomerSelect }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [options, setOptions] = useState([]);
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultAccountNumber = queryParams.get('acctno');

  // Debounce the search input
  const debouncedChangeHandler = useMemo(
    () => debounce((value) => {
      setDebouncedSearch(value);
    }, 300),
    []
  );

  const handleInputChange = (event, value) => {
    setInputValue(value);
    debouncedChangeHandler(value);
  };

  // Query for customers by name using Apollo Client's useQuery
  const { data: nameData, loading: nameLoading, error: nameError } = useQuery(
    GET_CUSTOMERS_QUERY,
    {
      variables: { search: debouncedSearch },
      skip: debouncedSearch.length < 2, // Apollo uses 'skip' instead of 'pause'
    }
  );

  // Query for customer by account number (if provided) using Apollo Client's useQuery
  const { data: acctNoData, loading: acctNoLoading, error: acctNoError } = useQuery(
    GET_CUSTOMERS_BY_ACCTNO_QUERY,
    {
      variables: { acctNo: defaultAccountNumber },
      skip: !defaultAccountNumber, // Apollo uses 'skip' instead of 'pause'
    }
  );

  // Handle errors
  useEffect(() => {
    if (nameError) console.error('Error fetching customers by name:', nameError);
    if (acctNoError) console.error('Error fetching customer by account number:', acctNoError);
  }, [nameError, acctNoError]);

  // Update options based on search results
  useEffect(() => {
    if (nameData?.customer?.nodes) {
      setOptions(nameData.customer.nodes);
    }
  }, [nameData]);

  // Handle default customer from account number query
  useEffect(() => {
    if (defaultAccountNumber && acctNoData?.customer?.nodes?.length > 0 && !selectedCustomer) {
      const foundCustomer = acctNoData.customer.nodes[0];
      setSelectedCustomer(foundCustomer);
      setInputValue(foundCustomer.name);
      onCustomerSelect(foundCustomer);
      setOptions(prevOptions => {
        if (!prevOptions.some(c => c.acctNo === foundCustomer.acctNo)) {
          return [foundCustomer, ...prevOptions];
        }
        return prevOptions;
      });
    }
  }, [defaultAccountNumber, acctNoData, onCustomerSelect, selectedCustomer]);

  const handleCustomerSelect = (event, value) => {
    setSelectedCustomer(value);
    if (value) {
      setInputValue(value.name);
      onCustomerSelect(value);
    } else {
      setInputValue('');
      onCustomerSelect(null);
    }
  };

  return (
    <Autocomplete
      sx={{ minWidth: 400, mt: 2 }}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={nameLoading || acctNoLoading} // Use 'loading' from Apollo
      value={selectedCustomer}
      onChange={handleCustomerSelect}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      isOptionEqualToValue={(option, value) => option.acctNo === value.acctNo}
      noOptionsText="No customers found"
      renderOption={(props, option) => (
        <li {...props} key={option.acctNo}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {option.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ marginLeft: 1 }}>
            {option.acctNo} - {option.assignedSalesperson}
          </Typography>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          sx={{ mt: 1 }}
          {...params}
          label="Select Customer"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {(nameLoading || acctNoLoading) ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default CustomerSelect;
