// src/components/EmailList.js
import React, { useContext } from 'react';
import { List, ListItem, ListItemText, Typography, Chip } from '@mui/material';
import { FrontDeskContext } from './FrontDeskContext';
import './EmailList.css';

const EmailList = () => {
  const { emails, setSelectedEmail, selectedEmail } = useContext(FrontDeskContext);

  return (
    <List className="email-list">
      {emails.map((email, index) => (
        <ListItem 
          button 
          key={index}
          onClick={() => setSelectedEmail(email)}
          selected={selectedEmail && selectedEmail.subject === email.subject}
          className={`email-list-item ${
            email.aiLegitimate === 'Yes' ? 'legitimate' : 'not-legitimate'
          } ${selectedEmail && selectedEmail.subject === email.subject ? 'selected' : ''}`}
        >
          <ListItemText 
            primary={<Typography noWrap className="email-subject">{email.subject}</Typography>}
            secondary={
              <>
                <Typography noWrap component="span" variant="body2" className="email-sender">
                  {email.fromName}
                </Typography>
                <Typography noWrap component="span" variant="body2" className="email-time">
                  {new Date(email.receivedDateTime).toLocaleString()}
                </Typography>
              </>
            }
          />
          <Chip 
            label={email.status} 
            size="small" 
            className={`status-chip ${email.status.toLowerCase()}`} 
          />
        </ListItem>
      ))}
    </List>
  );
};

export default EmailList;