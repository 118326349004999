import React, { useState, useEffect } from 'react';
import {
    Box, TextField, Typography, Grid, Card, CardContent, Snackbar,
    Tab, CircularProgress, Button
} from '@mui/material';

import MuiAlert from '@mui/material/Alert';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import SelectableCompanyStack from '../SelectableCompanyStack';
import UserShuttle from '../user/UserShuttle';
import useAuthenticatedData from '../../hooks/useAuthenticatedData';
import useAuthenticatedPut from '../../hooks/useAuthenticatedPut';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SectionTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.grey[800],
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    '&:last-child': {
        paddingBottom: theme.spacing(2),
    },
}));

const ContrastBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[900],
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
}));

const MetricEdit = () => {
    const { metricId } = useParams();
    const { data: metricData, loading, error } = useAuthenticatedData(`https://localhost:5001/mos/metrics/${metricId}`);
    const { put, isLoading: isSubmitting } = useAuthenticatedPut();
    const [selectedCompanyLevel, setSelectedCompanyLevel] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [tabValue, setTabValue] = useState('1');
    const [metricDto, setMetricDto] = useState({
        id: null,
        metricName: '',
        metricDescription: '',
        companyLevelId: null,
        // Add other fields as needed
    });

    const { data: assignedUsers, refetch: refetchAssigned } = useAuthenticatedData(
        `https://localhost:5001/mos/metrics/${metricId}/assigned-users${selectedCompanyLevel ? `?companyLevelId=${selectedCompanyLevel}` : ''}`
    );

    const { data: unassignedUsers, refetch: refetchUnassigned } = useAuthenticatedData(
        `https://localhost:5001/mos/metrics/${metricId}/available-users${selectedCompanyLevel ? `?companyLevelId=${selectedCompanyLevel}` : ''}`
    );

    useEffect(() => {
        if (metricData && typeof metricData === 'object') {
            setMetricDto({
                id: metricData.id,
                metricName: metricData.metricName || '',
                metricDescription: metricData.metricDescription || '',
                companyLevelId: metricData.companyLevelId || null,
                // Add other fields as needed
            });
            
        }
    }, [metricData]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setMetricDto(prevState => ({ ...prevState, [name]: value }));
    };

    const handleCompanyLevelSelect = (selectedLevelId) => {
        // Parse the ID to an integer
     
        setMetricDto(prevState => ({ ...prevState, companyLevelId: selectedLevelId }));
        setSelectedCompanyLevel(selectedLevelId);
    };

    const handleSaveMetric = async () => {
        try {
            await put(`https://localhost:5001/mos/metrics/${metricId}`, metricDto);
            setSnackbarMessage('Metric updated successfully!');
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error updating metric:', error);
            setSnackbarMessage('Error updating metric. Please try again.');
            setOpenSnackbar(true);
        }
    };

    const handleUsersChange = async (newAssigned, newUnassigned) => {
        try {
            await put(`https://localhost:5001/mos/metrics/${metricId}/users`, {
                assignedUserIds: newAssigned.map(user => user.id)
            });
            setSnackbarMessage('Users updated successfully!');
            setOpenSnackbar(true);
            refetchAssigned();
            refetchUnassigned();
        } catch (error) {
            console.error('Error updating users:', error);
            setSnackbarMessage('Error updating users. Please try again.');
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleTabChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    if (loading || metricDto.id == null) return <CircularProgress />;
    if (error) return <Typography color="error">Error: {error.message}</Typography>;

    return (
        <Box sx={{ p: 3, maxWidth: 1200, margin: 'auto' }}>
            <Typography style={{ color: 'white' }} variant="h4" gutterBottom color="primary">Edit Metric</Typography>

            <StyledCard>
                <StyledCardContent>
                    <SectionTitle variant="h6">Metric Details</SectionTitle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <ContrastBox>
                                <TextField
                                    fullWidth
                                    label="Metric Name"
                                    name="metricName"
                                    value={metricDto.metricName}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    variant="outlined"
                                />
                                <TextField
                                    fullWidth
                                    label="Metric Description"
                                    name="metricDescription"
                                    multiline
                                    rows={4}
                                    value={metricDto.metricDescription}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </ContrastBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ContrastBox>
                                <SectionTitle style={{ color: 'white' }} variant="subtitle1">Company Level</SectionTitle>
                                <SelectableCompanyStack
                                    initialSelectedItemId={Number(metricDto.companyLevelId)}
                                    onItemSelect={handleCompanyLevelSelect}
                                />
                            </ContrastBox>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveMetric}
                            disabled={isSubmitting}
                        >
                            Save Changes
                        </Button>
                    </Box>
                </StyledCardContent>
            </StyledCard>

            <StyledCard>
                <StyledCardContent>
                    <SectionTitle variant="h6">Assignments</SectionTitle>
                    <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="metric assignments">
                                <Tab label="Users" value="1" />
                                <Tab label="Groups" value="2" />
                                <Tab label="Roles" value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <UserShuttle
                                assignedUsers={assignedUsers}
                                unassignedUsers={unassignedUsers}
                                onUsersChange={handleUsersChange}
                                onCompanyLevelSelect={handleCompanyLevelSelect}
                                selectedCompanyLevel={selectedCompanyLevel}
                            />
                        </TabPanel>
                        <TabPanel value="2">Groups</TabPanel>
                        <TabPanel value="3">Roles</TabPanel>
                    </TabContext>
                </StyledCardContent>
            </StyledCard>

            {isSubmitting && <CircularProgress />}

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default MetricEdit;