// src/components/ios/purchasing/PurchasingPerformance.js
import React from 'react';
import { Box, Typography, Grid, Paper, CircularProgress, Link } from '@mui/material';
import useAuthenticatedData from '../../../hooks/useAuthenticatedData';

const PurchasingPerformance = () => {
  const { data, isLoading, error } = useAuthenticatedData('https://localhost:5001/ios/purchasing/performance');

  if (!data || isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Performance</Typography>
        <Link href="#" underline="hover">purchasing performance PDI</Link>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
            <Typography variant="body2">Month plan performance</Typography>
            <Typography variant="body2">Gross Margin: {data.grossMargin}%</Typography>
            <Typography variant="body2">Revenue: ${data.revenue}</Typography>
            {/* Add more performance metrics here */}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PurchasingPerformance;