// Repairs.js
import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import RepairsGrid from './RepairsGrid';
import RepairDetail from './RepairDetail';
import useAuthenticatedData from '../../../hooks/useAuthenticatedData';


const Repairs = () => {

    const { data: repairs, isLoading, error } = useAuthenticatedData('https://localhost:5001/ios/repairs');

    const [selectedRepair, setSelectedRepair] = useState(null);

    const handleRowClick = (params) => {
        setSelectedRepair(params.row);
    };

    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                    <RepairsGrid
                        data={repairs}
                        isLoading={isLoading}
                        error={error}
                        handleRowClick={handleRowClick}
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    <RepairDetail repair={selectedRepair} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Repairs;