import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, Box, Typography, Grid, Divider } from '@mui/material';
import useAuthenticatedData from '../../hooks/useAuthenticatedData'; // Adjust the import path as needed
import VersionHistory from './VersionHistory'; // Adjust the import path as needed

const About = () => {
  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
      <Typography variant="h1" component="h1" gutterBottom align="left">
        About Nexus
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Current Application Info
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1" component="p" gutterBottom>
              Version: {process.env.REACT_APP_VERSION}
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              Environment: {process.env.NODE_ENV}
            </Typography>
          </Grid>
          
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              Version History
            </Typography>
            <Divider sx={{ my: 2 }} />
            <VersionHistory />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default About;