// DayCell.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import ActivityBand from './ActivityBand';

const DayCell = ({ date, data, selectedDate, onDateSelect, isToday  }) => {
  const dateString = date.toISOString().split('T')[0];
  const dayData = data.find((d) => d.date === dateString);

  const isBusinessDay = date.getDay() !== 0 && date.getDay() !== 6;
  const isSelected = selectedDate && selectedDate.toDateString() === date.toDateString();

  return (
    <Box
      onClick={() => onDateSelect(date)}
      sx={{
        border: isSelected ? '2px solid #FFFFFF' : '1px solid #424242',
        padding: 1,
        backgroundColor: isBusinessDay ? '#1E1E1E' : '#2C2C2C',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        transition: 'background-color 0.3s, border 0.3s',
        outline: isToday ? '2px solid rgba(255, 215, 0, 0.6)' : 'none', // Subtle yellow outline
        borderRadius: isToday ? '2px' : '0px', // Rounded corners for outline
        '&:hover': {
          backgroundColor: '#333333',
        },
      }}
    >
      {/* Horizontal Layout: Date Number and Activities Side by Side */}
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        {/* Date Number */}
        <Typography variant="subtitle2" sx={{ color: '#FFFFFF', marginRight: 0.5 }}>
          {date.getDate()}
        </Typography>

        {/* Activities */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.2,
            flexGrow: 1,
            overflow: 'hidden',
          }}
        >
          {/* Quotes Activity */}
          {dayData && (dayData.quoteLineCount > 0 || dayData.quoteDocCount > 0 || dayData.quoteTotal > 0) && (
            <ActivityBand
              color="#1976d2"
              type="QUOTES"
              docCount={dayData.quoteDocCount}
              lineCount={dayData.quoteLineCount}
              total={dayData.quoteTotal}
            />
          )}

          {/* Sales Activity */}
          {dayData && (dayData.salesOrderLineCount > 0 || dayData.salesOrderDocCount > 0 || dayData.salesOrderTotal > 0) && (
            <ActivityBand
              color="#388e3c"
              type="SALES"
              docCount={dayData.salesOrderDocCount}
              lineCount={dayData.salesOrderLineCount}
              total={dayData.salesOrderTotal}
            />
          )}

          {/* Calls Activity */}
          {dayData && dayData.callCount > 0 && (
            <ActivityBand
              color="#9C4EE5"
              type="CALLS"
              docCount={0}
              lineCount={dayData.callCount}
              total={0}
              minimal={true}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

DayCell.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  data: PropTypes.array.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  onDateSelect: PropTypes.func.isRequired,
  isToday: PropTypes.bool, // Added isToday prop
};

DayCell.defaultProps = {
  selectedDate: null,
  isToday: false, // Default value for isToday
};


export default DayCell;
